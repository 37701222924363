<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('comiteCientifico.cabecalhoTitulo') }`" :classProp="'header-single header-comite'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">

          <p>{{ $t('comiteCientifico.paragrafo1') }}</p>

          <p>{{ $t('comiteCientifico.paragrafo2') }}</p>

          <ul class="mt-2">
            <li>{{ $t('comiteCientifico.marcador1') }}</li>
            <li>{{ $t('comiteCientifico.marcador2') }}</li>
            <li>{{ $t('comiteCientifico.marcador3') }}</li>
            <li>{{ $t('comiteCientifico.marcador4') }}</li>
            <li>{{ $t('comiteCientifico.marcador4b') }}</li>
            <li>{{ $t('comiteCientifico.marcador4c') }}</li>
            <li>{{ $t('comiteCientifico.marcador4d') }}</li>
            <li>{{ $t('comiteCientifico.marcador4e') }}</li>
            <li>{{ $t('comiteCientifico.marcador4f') }}</li>
            <li>{{ $t('comiteCientifico.marcador4g') }}</li>
          </ul>
          <p>{{ $t('comiteCientifico.paragrafo3') }}</p>
          <ul class="mt-2">
            <li>{{ $t('comiteCientifico.marcador5') }}</li>
            <li>{{ $t('comiteCientifico.marcador6') }}</li>
            <li>{{ $t('comiteCientifico.marcador7') }}</li>
            <li>{{ $t('comiteCientifico.marcador8') }}</li>
            <li>{{ $t('comiteCientifico.marcador9') }}</li>
            <li>{{ $t('comiteCientifico.marcador10') }}</li>
            <li>{{ $t('comiteCientifico.marcador11') }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'ComiteCientifico',
  components: { InstitucionalHeaderSingle }
}
</script>

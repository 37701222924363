<template>
  <nav class="app-admin navbar navbar-expand navbar-light navbar-bg">
    <a class="sidebar-toggle d-flex" @click="onMenuCollapse">
      <i class="hamburger align-self-center"></i>
    </a>

    <div class="navbar-collapse collapse">
      <ul class="navbar-nav navbar-align">
        <li>
          <ul class="list_idioma list_adm mt-2">
            <li><a :class="{ 'active': $i18n.locale === 'es' }" @click="setLocale('es')">ES</a></li>
            <li><a :class="{ 'active': $i18n.locale === 'pt' }" @click="setLocale('pt')">PT</a></li>
            <li><a :class="{ 'active': $i18n.locale === 'en' }" @click="setLocale('en')">EN</a></li>
          </ul>
        </li>

        <!--<li class="nav-item dropdown notificacao">
          <a class="nav-icon dropdown-toggle" id="alertsDropdown" data-toggle="dropdown" :aria-expanded="showDropdownLanguage"
            :class="{ 'show': showDropdownAlerts }" @click="showDropdownAlerts=!showDropdownAlerts">
            <div class="position-relative">
              <i class="align-middle" data-feather="bell"></i>
              <span class="indicator">4</span>
            </div>
          </a>

          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" :class="{ 'show': showDropdownAlerts }" aria-labelledby="alertsDropdown">
            <div class="dropdown-menu-header">4 novas notificações</div>
            <div class="list-group">
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i class="text-danger" data-feather="alert-circle"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">
                      Espécie XYZ aguardando aprovação
                    </div>
                    <div class="text-muted small mt-1">
                      Lorem ipsum dolor est.
                    </div>
                    <div class="text-muted small mt-1">30m atrás</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i class="text-warning" data-feather="bell"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Lorem ipsum</div>
                    <div class="text-muted small mt-1">
                      Aliquam ex eros, imperdiet vulputate hendrerit et.
                    </div>
                    <div class="text-muted small mt-1">2h atrás</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i class="text-primary" data-feather="home"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Login from 192.186.1.8</div>
                    <div class="text-muted small mt-1">5h atrás</div>
                  </div>
                </div>
              </a>
            </div>

            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Veja todas as notificações</a>
            </div>
          </div>
        </li> -->

        <li class="nav-item dropdown">
          <a class="nav-icon dropdown-toggle d-inline-block d-sm-none" data-toggle="dropdown" :aria-expanded="showDropdownSettings"
            :class="{ 'show': showDropdownSettings }" @click="showDropdownSettings=!showDropdownSettings">
            <i class="align-middle" data-feather="settings"></i>
          </a>

          <a class="nav-link dropdown-toggle d-none d-sm-inline-block" data-toggle="dropdown" :aria-expanded="showDropdownSettings"
            :class="{ 'show': showDropdownSettings }" @click="showDropdownSettings=!showDropdownSettings">
            <img
              src="../../assets/admin/img/avatars/avatar.jpg"
              class="avatar img-fluid rounded mr-1"
              :alt="user.sysUser.description"
            />
            <span class="azul-esc">{{user.sysUser.description}}</span>
          </a>
          <div class="dropdown-menu dropdown-menu-right" :class="{ 'show': showDropdownSettings }">
            <a class="dropdown-item" :href="`/admin/usuarios/${user.sysUser.name}`"><i class="align-middle mr-1" data-feather="user"></i> Mi cuenta</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" @click="logout()">Salir</a>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import feather from 'feather-icons'
import { mapState } from 'vuex'
export default {
  name: 'AdminNavbar',
  computed: mapState(['user']),
  data: function () {
    return {
      showDropdownLanguage: false,
      showDropdownAlerts: false,
      showDropdownSettings: false
    }
  },
  methods: {
    onMenuCollapse: function () {
      this.$store.commit('onMenuCollapse')
      feather.replace()
    },
    setLocale: function (value) {
      this.$i18n.locale = value
      this.showDropdownLanguage = false
    },
    logout () {
      this.$router.push({ name: 'Login' })
      this.$store.commit('setUser', null)
    }
  },
  mounted () {
    feather.replace()
  }
}
</script>

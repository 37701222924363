export const es = {
  message: {
    requiredField: 'Campo obligatorio',
    noRecords: '¡No se encontraron registros!',
    confirmDel: '¿Está seguro de que quiere borrar?',
    confirmApproval: '¿Está seguro de que desea aprobar esta ocurrencia?',
    noResultsFor: 'No hay resultados para'
  },
  login: {
    username: 'Usuario',
    password: 'Contraseña',
    login: 'Login',
    entrar: 'Iniciar sesión',
    descricao: 'Ingrese sus datos de acceso en el siguiente formulario:'
  },
  exportarFicha: {
    author: 'autor',
    biodiversity_impact: 'impacto sobre la biodiversidad',
    chemical_control: 'control_químico',
    class: 'Clase',
    common_name: 'nombre_común',
    control_biological: 'control_biológico',
    data_bibliography: 'datos_bibliografía',
    economic_impact: 'impacto_económico',
    economic_use: 'uso_económico',
    family: 'familia',
    health_impact: 'impacto_salud',
    intro_data: 'datos_introducción',
    intro_description: 'descripción_introducción',
    invasion_preferential_environments: 'ambientes_preferenciales_invasión',
    kingdom: 'Reino',
    location: 'localidad',
    morphophysiology_description: 'descripción_morfofisiología',
    native_distribution_area: 'áreas_nativa_de_distribución',
    natural_environment: 'ambiente_natural',
    order: 'Órden',
    origin: 'origen',
    phyllum: 'Phyllum',
    physical_control: 'control_físico',
    prevention: 'prevención',
    scientific_name: 'nombre_científico',
    social_impact: 'impacto_social',
    species_id: 'id_especie',
    voc_cause_introduction: 'voc_causa_de_introducción',
    voc_diet: 'voc_dieta',
    voc_dispersal_routes: 'voc_rutas_dispersión',
    voc_dispersion_vectors: 'voc_vectores_dispersión',
    voc_form: 'voc_forma',
    voc_introduction_type: 'voc_tipo_introducción',
    voc_reproduction: 'voc_reproducción',
    voc_spread: 'voc_dispersión',
    world_invasive_place: 'sitios_invasión_mundo'
  },
  label: {
    voltar: 'Volver',
    outro: 'Otro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acceda a la base de datos',
    conhecaNossaRede: 'Red Latinoamericana',
    baseDadosNacional: 'InBiAr - Invasiones Biológicas en Argentina',
    politicaDeDados: 'Política de datos',
    comiteCientifico: 'Comité científico',
    colabore: 'Aportar datos',
    especiesMarinhas: 'Especies marinas y costeras',
    peixesAquario: 'Peces de acuario',
    peixesAquicultura: 'Peces de acuicultura',
    pescaDesportiva: 'Pesca deportiva',
    pets: 'Animales de compañía',
    plantasForrageiras: 'Plantas forrajeras',
    plantasOrnamentais: 'Plantas ornamentales',
    usoFlorestal: 'Uso forestal',
    tutorial: 'Tutorial',
    especies: 'Especies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referencias',
    projetos: 'Proyectos',
    saibaMais: 'Más info',
    contida: 'Contenida',
    casual: 'Detectada en la naturaleza',
    estabelecida: 'Establecida',
    invasora: 'Invasor',
    exibirListagem: 'Mostrar listado',
    concluir: 'Terminar',
    fechar: 'Cerrar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clic aquí',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Mostrar 10 registros',
    exibir25Reg: 'Mostrar 25 registros',
    exibir50Reg: 'Mostrar 50 registros',
    exibir100Reg: 'Mostrar 100 registros',
    limpar: 'Limpiar',
    buscar: 'Buscar',
    addColaborador: 'Agregar colaborador/a',
    nome: 'Nombre',
    sobrenome: 'Apellido',
    aprovarColaborador: 'Aprobar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidad',
    instituicao: 'Institución',
    endereco: 'Dirección',
    estado: 'Estado',
    cidade: 'Ciudad',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Teléfono',
    telefone2: 'Teléfono 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Red social 1',
    midiaSocial2: 'Red social 2',
    midiaSocial3: 'Red social 3',
    observacoesAdicionais: 'Observaciones adicionales',
    adicionar: 'Agregar',
    editando: 'Editando',
    acoes: 'Acciones',
    usuarixs: 'Usuarios',
    adicionarUsuario: 'Agregar usuarix',
    descricao: 'Descripción',
    ativo: 'Activo',
    sim: 'Sí',
    nao: 'No',
    salvarUsuarix: 'Salvar usuarix',
    senha: 'Contraseña',
    observacoes: 'Observaciones',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrador',
    taxonomia: 'Taxonomía',
    nomeCientifico: 'Nombre científico',
    adicionarEspecie: 'Agregar especie',
    genero: 'Género',
    familia: 'Familia',
    ordem: 'Orden',
    classe: 'Clase',
    phylumDivisao: 'Phylum / División',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origen',
    descricaoInvalida: 'Descripción no válida',
    selecioneFamilia: 'Por favor, seleccione una familia.',
    selecioneOrdem: 'Por favor, seleccione un Orden.',
    selecioneClasse: 'Por favor, seleccione una Clase.',
    selecioneDivisao: 'Por favor, seleccione una División.',
    selectioneReino: 'Por favor, seleccione un Reino.',
    selectioneGenero: 'Por favor, seleccione un género.',
    addReferencia: 'Agregar referencia',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprobar referencia',
    salvarReferencia: 'Salvar referencia',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Localidad (Ex. Salvador, BA  /  Sudáfrica: Ciudad del Cabo)',
    localFiltro: 'Localidad',
    edicao: 'Edición (ex. 2 ed.)',
    editora: 'Editorial',
    revista: 'Revista',
    anoPublicacao: 'Año de publicación',
    meioDivulgacao: 'Medio de comunicación',
    volume: 'Volumen (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palabra clave',
    anoPublic: 'Año publicación',
    addProjeto: 'Agregar proyecto',
    aprovarProjeto: 'Aprobar Proyecto',
    salvarProjeto: 'Salvar proyecto',
    contato: 'Contacto',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descripción',
    dataInicio: 'Fecha de inicio',
    dataTermino: 'Fecha de finalización',
    comentarios: 'Comentarios',
    anoInicio: 'Año de inicio',
    anoTermino: 'Año de finalización',
    ocorrencias: 'Ocurrencias',
    exportarDados: 'Exportar datos',
    addOcorrencia: 'Agregar ocurrencia',
    visualizarOcorrencia: 'Ver ocurrencia',
    alterarOcorrencia: 'Modificar ocurrencia',
    ocorrenciasVinculadas: 'Ocurrencias vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Especie',
    aprovarOcorrencia: 'Aprobar ocurrencia',
    salvarOcorrencia: 'Salvar ocurrencia',
    colaborador: 'Colaborador/a',
    municipio: 'municipalidad',
    municipioSede: 'municipalidad (sede)',
    municipioCentroide: 'municipalidad (centroide)',
    referenciaLocal: 'Referencia local',
    referencia: 'Referencia',
    latitude: 'Latitud',
    longitude: 'Longitud',
    coordenadasCorrespondem: 'Las coordenadas corresponden a',
    distanciaAproximada: 'Distancia aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Cuenca hidrográfica',
    descricaoInvasao: 'Descripción de la invasión',
    abundancia: 'Abundancia',
    situacaoPopulacional: 'Situación poblacional',
    manejo: 'Manejo',
    dataEntrada: 'Fecha de ingreso',
    dataObservacao: 'Fecha de observación (ej. 2020 o 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'Es un ejemplar de museo o de herbario',
    selecioneTipo: 'Seleccione el tipo',
    herbario: 'Herbario',
    museu: 'Museo',
    numeroExemplaInstituicao: 'Número de ejemplar (institución)',
    numeroExemplarColetor: 'Número de ejemplar (colector)',
    selecioneOpcao: 'Seleccione una opción…',
    pointList1: 'A un punto de ocurrencia de uno o más individuos de la especie',
    pointList2: 'Al punto central de un grupo de organismos de la especie',
    pointList3: 'A un punto próximo de la ocurrencia, ubicado a una distancia aproximada de :',
    coordinatesSourceList1: 'A - Una unidad política o administrativa',
    coordinatesSourceList2: 'B - El sitio real de ocurrencia',
    ultimoRegistroBaseDados: 'Últimos registros en la base de datos',
    ultimosAcessos: 'Últimos accesos',
    fichaTecnicaEspecie: 'Ficha técnica de las especies',
    fichaTecnicaEspecieMMA: 'Ficha técnica de las especies MMA',
    fichaTecnicaEspecieMMA2: 'Ficha técnica de las especies MMA versión 2',
    usoRestringido: 'Categoria 1 Uso restringido',
    usoControlado: 'Categoria 2 Uso controlado',
    noAsignada: 'Categoria 3 No asignada',
    noEvaluada: 'No evaluada',
    formatosDisponiveisExportacao: 'Formatos disponibles para exportación:',
    biologiaEcologia: 'Biología y ecología',
    usoEconomico: 'Uso económico',
    usoEconomicoDescricao: 'Uso económico - descripción',
    invasaoBiologica: 'Invasión biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nombre científico + sinónimos',
    nomesPopulares: 'Nombres comunes',
    reproducao: 'Reproducción',
    dispersao: 'Dispersión',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes preferenciales de invasión',
    tipoIntroducao: 'Tipo de introducción',
    causaIntroducao: 'Causa de introducción',
    localIntroducao: 'Sitio de introducción',
    anoIntroducao: 'Añoo de introducción',
    marinho: 'Marino',
    dulcicola: 'Agua dulce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar especie',
    salvarEspecie: 'Salvar especie',
    data: 'Fecha',
    confiabilidade: 'Confiabilidad',
    resultadosAnalisesRisco: 'Resultados del análisis de riesgo',
    risco: 'Riesgo',
    areaOrigem: 'Área de orígen',
    areaDistribuicaoNatural: 'Área de distribución natural',
    ambientesNaturais: 'Ambientes naturales',
    descricaoEspecie: 'Descripción de la especie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes preferenciales de invasión - descripción',
    outrosLocaisOndeEspecieInvasora: 'Otros sitios donde la especie se comporta como invasora',
    impactosEcologicos: 'Impactos ambientales',
    impactosEconomicos: 'Impactos económicos',
    impactosSociais: 'Impactos sociales',
    impactosSaude: 'Impactos sobre la salud',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nivel de confianza de la evaluación EICAT',
    referenciaEICAT: 'Referencia EICAT',
    dataEICAT: 'Fecha EICAT (ej. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nivel de confianza de la evaluación SEICAT',
    referenciaSEICAT: 'Referencia SEICAT',
    dataSEICAT: 'Fecha SEICAT (ej. 2021)',
    digitarSomenteAno: 'Ingresar solamente el año - 2021',
    viasCDBCategorias: 'Vías CDB - categorías',
    viasIntroducao: 'Vías de introducción y dispersión',
    viasVetoresCDBSubcategorias: 'Vías y vectores CDB - subcategorías',
    vetoresIntroducao: 'Vectores de introducción y dispersión',
    introducao: 'Introducción',
    adicionarIntroducao: 'Agregar introducción',
    ano: 'Año',
    descricaoIntroducao: 'Descripción de la introducción',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Eliminar',
    medidasPreventivas: 'Medidas de prevención',
    medidasPreventivasMMA: 'Medidas de prevención MMA',
    medidasDeteccaoPrecoce: 'Medidas de detección precoz',
    controleMecanico: 'Control mecánico',
    controleQuimico: 'Control químico',
    controleBiologico: 'Control biológico',
    distribuicaoPais: 'Distribución en el país',
    vejaMapaOcorrencias: 'Vea el mapa de ocurrencias',
    ecologiaUso: 'Ecología y uso',
    introducaoDispersao: 'Introducción y dispersión',
    analiseRisco: 'Análisis de riesgo',
    nomeComum: 'Nombre común',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinónimos',
    autor: 'Autor',
    subespecie: 'Subespecie',
    autorSubespecie: 'Autor - subespecie',
    pendenteAnalise: 'Pendiente de análisis',
    cancelarVoltar: 'Cancelar / Volver',
    versaoAnterior: 'Versión anterior',
    versaoAtual: 'Versión actual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'TAXONOMIA - LISTA COMPLETA DE ESPECIES',
    consultaEspeciesOrigemAmbientes: 'ESPECIES, ORIGEN, AMBIENTES DE INVASIÓN E IMPACTOS',
    consultaEspeciesManejo: 'ESPECIES Y MANEJO',
    consultaParaGerarFichaTecnica: 'FICHA TÉCNICA DE LA ESPECIE SIN OCURRENCIAS',
    consultaEspeciesOcorrencias: 'ESPECIES Y OCURRENCIAS',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocurrencias APP',
    dataCadastro: 'Fecha de Registro',
    criadoPor: 'Creado por',
    nivelCerteza: 'Nivel de certeza',
    byApp: 'Por aplicación móvil',
    verDados: 'Ver datos',
    appuser: 'APPUser',
    profissao: 'Profesión',
    colobadorJaCadastrado: 'Colaborador ya registrado',
    especialidadeApp: 'Especialidad APP',
    cep: 'Código postal',
    categoriaCDB: 'Categoría CBD',
    subCategoriaCDB: 'Subcategoría CDB',
    especiesDeAguaDoce: 'Especies de agua dulce',
    insetosOutrosInvertebradosTerrestres: 'Insectos y otros invertebrados terrestres',
    animaisOrnamentaisCompanhiaAquario: 'Animales ornamentales, de compañía y de acuario',
    plantasOrnamentales: 'Plantas ornamentales',
    especiesFlorestais: 'Especies forestales',
    especiesChaveEngenheirosEcossistema: 'Ingenieros de ecosistemas y especies clave',
    mensagemEnviada: 'Mensaje enviado correctamente.'
  },
  home: {
    destaque1: 'Bienvenidxs a esta iniciativa destinada a proteger la biodiversidad frente al desafío de las invasiones biológicas!',
    destaqueDescricao1: 'Este sistema de bases de datos recibe aportes de personas comprometidas con la conservación de la biodiversidad. Lo invitamos a recorrer nuestra página!',
    destaque2: 'Desde 2004 documentando especies exóticas invasoras y compartiendo la información online!',
    destaqueDescricao2: 'Este sistema de bases de datos fue desarrollado y se mantiene actualizado con recursos nacionales e internacionales y cuenta con un Comité Científico de Apoyo.',
    destaque3: 'Qué incluye este sistema de información?',
    destaqueDescricao3: 'Administramos cinco bases de datos interconectadas sobre especies, localidades de ocurrencia, especialistas y proyectos.',
    destaque4: 'Como funciona?',
    destaqueDescricao4: 'Puede consultar sobre especies exóticas invasoras y potencialmente invasoras, especialistas, proyectos y bibliografía en la República Argentina.',
    titulo: 'Busque información en nuestras bases de datos.',
    conteudo: 'Especies y los sitios donde se encuentran dentro del territorio nacional, especialistas en la temática, referencias y proyectos dedicados al estudio y manejo de este problema.',
    conteudoEspecies: 'Esta sección de nuestro sistema contiene información acerca de las especies exóticas invasoras y potencialmente invasoras presentes en la República Argentina, su clasificación taxonómica, características biológicas, formas de introducción y dispersión, distribución, abundancia e iniciativas de manejo.',
    conteudoColaboradores: 'Registro de proveedores de datos. Si quiere entrar en contacto con quien proveyó la información sobre alguna especie o un sitio de ocurrencia en particular busque aquí.',
    conteudoReferencias: 'Lista de las referencias relacionadas con las especies incluidas en la base de datos.',
    conteudoProjetos: 'Proyectos de investigación y de manejo de especies exóticas invasoras en la Argentina.',
    titulo1: 'Sepa más sobre algunas de las especies exóticas invasoras de la Argentina',
    conteudo1: 'Las especies exóticas invasoras pertenecen a todos los grupos de organismos y son introducidas de forma accidental o voluntaria por una variedad de razones, sus efectos también son muy diversos.'
  },
  areaParceiros: {
    titulo: 'Red de Bases de Datos sobre Especies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay',
    brasil: 'Brasil',
    jamaica: 'Jamaica'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Red Latinoamericana',
    paragrafo1: 'El origen de esta red de bases de datos se remonta al año 2004, como parte de la Red Temática sobre Especies Exóticas Invasoras de la Red Interamericana de Información sobre Biodiversidad (I3N-IABIN), establecida como una iniciativa de la Cumbre de las Américas.',
    paragrafo2: 'Cada país integrante de I3N designó un líder nacional cuya función era desarrollar una base de datos nacional sobre estándares comunes y compartir información sobre especies exóticas invasoras:',
    paragrafo2_1: '',
    marcador1: ': Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (since 2003);',
    marcador2: ': Dr. Silvia R. Ziller, Founder and Executive Director of the Horus Institute for Environmental Conservation and Development (since 2004);',
    marcador3: ': Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: ': PhD. Eduardo Chacón-Madrigal, Professor at the University of Costa Rica. Researcher at the Biodiversity and Tropical Ecology Research Center. Co-coordinator of Flora Invasive and Naturalized of Central America.',
    marcador5: ': Dr. Diego Inclán, Executive Director of the National Institute of Biodiversity (since 2021) and Francisco José Prieto Albuja;',
    marcador5_1: ': Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: ': Dr. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (since 2004).',
    marcador5_3: ': Hugo Fernando del Castillo, Guyra Paraguay Bird Conservation (since 2006);',
    marcador5_4: ': Dr. Ernesto Brugnoli, Universidad de la República. M.Sc. - Marcelo Iturburu, Coordinador del Comité de Especies Exóticas Invasoras, Ministerio de Medio Ambiente del Uruguay.',
    paragrafo3: 'La iniciativa IABIN se extendió hasta el año 2011 con financiamiento del Fondo para el Ambiente Mundial (GEF). Más allá del final de este proyecto, algunos de los líderes nacionales de la red mantuvieron las bases de datos nacionales en funcionamiento (Argentina, Brasil, Jamaica, Paraguay y Uruguay).',
    paragrafo4: 'En el año 2021, se recibió el apoyo de la iniciativa BioBridge, de la Secretaría de la Convención sobre Diversidad Biológica (CDB), para reforzar y actualizar las bases de datos de Argentina, Brasil, Paraguay y Uruguay. La interfase fue actualizada y se incorporaron nuevas funcionalidades.',
    paragrafo5: 'Los profesionales informáticos responsables del desarrollo de la Base de Datos son:',
    marcador6: 'João Scucato, de Curitiba - PR, Brasil, quien desarrolló la primera versión en Microsoft Access, 2004-2005;',
    marcador7: 'Alejandro Moreno, de Argentina, quien desarrolló las primeras versiones de código abierto de nuestras bases de datos y la interface web que se utilizó hasta el 2021. Desde 2006 ha apoyado bases de datos de diferentes países. Guardó copias de los archivos y recuperó datos de Paraguay y Uruguay para esta nueva versión. Alejandro también desarrolló la Plataforma Común de los países de la red.',
    marcador8: 'El diseñador Rafael Moura y el programador Thiago Lôbo, de Brasil, a través de la Plataforma Vibbra en Florianópolis - SC, desarrollaron la nueva versión (2021) de las bases de datos nacionales. Los programadores Leonardo Rotondano y Thiago Lôbo desarrollaron la aplicación para cargar datos desde teléfonos celulares .',
    paragrafo6: 'La red espera ampliar su cobertura territorial incorporando otros países de la región de manera de ampliar la disponibilidad de información sobre especies exóticas invasoras para el conocimiento público, la gestión gubernamental y el manejo.',
    paragrafo7: 'Apoyo',
    paragrafo8: 'Periodo 2021',
    argentina: 'Argentina',
    brasil: 'Brasil',
    chile: 'Chile',
    costarica: 'Costa Rica',
    ecuador: 'Ecuador',
    honduras: 'Honduras',
    jamaica: 'Jamaica',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay',
    paragrafo9: 'Periodo 2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'InBiAr',
    titulo: 'Invasiones biológicas en Argentina',
    paragrafo1: 'Esta base de datos y la interfase Web correspondiente fueron desarrolladas por la Universidad Nacional del Sur (Argentina) con la colaboración del Instituto Hórus de Desarrollo y Conservación Ambiental (Brasil) y el apoyo del Servicio Geológico (USGS) y de la Oficina Nacional de Información Biológica de los Estados Unidos (NBII) en el marco del proyecto I3N (Red de Información sobre Especies Invasoras) de IABIN (Red Interamericana de Información sobre Biodiversidad). Desde el año 2006 el sistema permanece operativo, publicado en internet y abierto a las consulta libre y gratuita, administrado y mantenido por el Grupo de Estudios en Conservación y Manejo (GEKKO), del Departamento de Biología, Bioquímica y Farmacia de la Universidad Nacional del Sur.',
    paragrafo2: 'Entre los años 2015 y 2022 se realizaron mejoras significativas dentro del proyecto “Fortalecimiento de la gobernanza para la protección de la biodiversidad mediante la Formulación e implementación de la Estrategia Nacional sobre Especies Exóticas Invasoras (ENEEI)” (GCP/ARG/023/GFF), ejecutado por el Ministerio de Ambiente y Desarrollo Sostenible de la República Argentina, con financiamiento parcial del Fondo para el Medio Ambiente Mundial (GEF) y con la Organización de las Naciones Unidas para la Alimentación y la Agricultura (FAO-ONU) como agencia de implementación.',
    paragrafo3: 'En 2020 se retomaron los esfuerzos para coordinar el manejo de la información sobre especies exóticas invasoras con otros países de la región. Con el apoyo de la iniciativa Biobridge del Convenio sobre la Diversidad Biológica (CDB-PNUMA), este sistema de información unió esfuerzos a iniciativas similares en Brasil, Paraguay y Uruguay, desarrollando una ',
    paragrafo3_1: 'plataforma de búsqueda de información unificada',
    paragrafo3_2: ', como un paso más para consolidar esfuerzos conjuntos para enfrentar el desafío de la invasiones biológicas a través de conocimientos sólidos y actualizados sobre especies exóticas invasoras en la región y contribuir así al logro de la Meta 8 de la Agenda 2030 / ODS y la Meta 9 de Aichi.'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de datos',
    titulo: 'Conceptos y referencias',
    criterio: 'Criterios para la inclusión de especies',
    construccion: 'Construcción colaborativa',
    validacao: 'Validación de datos',
    paragrafo1: 'A los efectos de este sistema de datos se adoptan las siguientes definiciones, de acuerdo con el Convenio de Diversidad Biológica:',
    marcador1: 'especie nativa: aquella que se encuentra dentro del área de distribución geográfica donde evolucionó y forma parte de una comunidad biológica que se considera natural o seminatural,',
    marcador2: 'especie exótica: aquella que se encuentra fuera de su área de distribución natural pasada o presente,',
    marcador3: 'especie exótica invasora: toda especie exótica que afecta o amenaza hábitats, ecosistemas o especies causando alteraciones en los ambientes naturales,',
    marcador3_1: 'especie criptogénica: aquella especie cuyo origen no puede establecerse con certeza por lo que no puede clasificarse como nativa o exótica.',
    paragrafo2: 'Los vocabularios controlados relacionados con ambientes preferenciales de invasión, rutas y vectores de dispersión, formas biológicas, impactos y métodos de control fueron definidos de acuerdo con los términos utilizados a por la Base de Datos Global sobre Especies Invasoras del Grupo Especialista en Especies Invasoras de la UICN',
    paragrafo3: 'Las referencias taxonómicas principales se toman de los sistemas propuestos por el Catálogo de Plantas Vasculares del Instituto de Botánica Darwinion',
    paragrafo3_1: 'por el Jardín Botánico de Missouri',
    paragrafo3_2: 'y por el Sistema Integrado de Información Taxonómica',
    paragrafo3_3: 'Referencias complementarias incluyen el Registro Mundial de Especies Marinas',
    paragrafo3_4: 'la Base de Datos Global sobre Especies Exóticas Invasoras',
    paragrafo3_5: 'y el Compendio de Especies Invasoras de CABI',
    paragrafo6: 'Para cada punto de ocurrencia las especies se califican como:',
    marcador4: '– cuando su presencia se limita o está restringida a sistemas de cultivo o cría o vive dependiendo de manera directa de las personas, por ejemplo como animal de laboratorio, de compañía o de acuario, planta de jardín, sin evidencias de escape de individuos en ese sitio.',
    marcador5: '– cuando la especie fue vista en ambientes naturales o seminaturales, fuera de sistemas de cultivo o cría, todavía sin evidencias de haber establecido un núcleo poblacional.',
    marcador6: '– cuando la especie se reproduce de manera efectiva, conformando una población autosostenible pero se mantiene en un área limitada, próxima al sitio de introducción local.',
    marcador7: '– cuando la especie avanza, ya sea de manera autónoma o auxiliada por vector antrópicos, hacia otros sitios más allá del punto de introducción local.',
    paragrafo7: 'Es importante notar que el estado de invasión es un atributo de la localidad de ocurrencia, no de las especies. De este modo, la misma especie puede comportarse como invasora en una localidad, estar contenida en otro sitio y haber sido detectada en la naturaleza en un tercero.',
    paragrafo11: 'La base de datos de especies exóticas invasoras de la República Argentina incluye especies exóticas y criptogénicas detectadas en ambientes naturales o seminaturales del país, así como aquellas que, manteniéndose contenidas en sistemas de cultivo o cría cuentan con antecedentes como invasoras en otros países. Se incluyen, asimismo, especies que siendo nativas de alguna región del país hayan sido traslocadas a otras regiones, cuencas o ecosistemas. Todas las especies se encuentran asociadas a, por lo menos, una localidad de ocurrencia en el país. En algunos casos esa localidad es el aeropuerto o el puerto de ingreso, cuando no se cuenta con datos posteriores a su importación. El foco de este sistema es la conservación de la diversidad biológica y, por ello, no se incluyen en la base de datos especies exóticas que se comporten como malezas, plagas o patógenos de las personas o de los animales domésticos si no han sido detectadas todavía en la naturaleza, no afectan a especies de la flora y fauna nativa o no tienen antecedentes internacionales en ese sentido. Más allá de eso, es muy frecuente que las especies que afectan la estructura, composición y funcionamiento de los ecosistemas naturales y seminaturales tengan también impactos sobre la economía, la salud y los valores culturales.',
    paragrafo13: 'Las bases de datos de este sistema se mantienen actualizadas gracias a los aportes de información de colaboradores voluntarios distribuidos en todo el país. Nuestros colaboradores pueden contribuir con información sobre nuevas especies, nuevas localidades de ocurrencia de especies ya detectadas en el territorio nacional, sobre proyectos, especialistas o publicaciones en la materia. Cada información permanece vinculada a los datos de quien la proveyó, como un reconocimiento acerca de sus aportes al conocimiento del estado del problema de las invasiones biológicas en el país y como una forma de promover la comunicación directa entre personas con conocimiento e interés en la materia. Para contribuir con información acceda al',
    paragrafo13_1: 'link de carga de datos',
    paragrafo13_2: 'o contáctese a',
    paragrafo13_3: 'La información también se carga a partir de publicaciones y de ejemplares de museo y de herbario. Cada nueva localidad de ocurrencia es evaluada siguiendo estándares de validación compartidos con la',
    paragrafo13_4: 'red de bases de datos',
    paragrafo13_5: 'Los datos se asocian con coordenadas geográficas que permiten mapear la distribución registrada para cada especie.',
    paragrafo14: 'Los administradores de la base de datos se ocupan de completar la información respecto de las características biológicas de cada especie, los ambientes preferenciales de invasión, vectores y vías de introducción y dispersión, impactos y técnicas de control, entro otros.',
    paragrafo14_1: 'Los datos son revisados continuamente, con cada nueva unidad de información recibida. Las nuevas especies o las localidades de ocurrencia que implican cambios significativos en la distribución de una especie o la colonización de nuevos ambientes son confirmados mediante consultas a especialistas del',
    paragrafo14_2: ' comité científico',
    paragrafo14_3: 'o a otros convocados especialmente a partir de su experiencia particular.',
    paragrafo15: 'La confiabilidad de los datos es una de las principales preocupaciones de los administradores de este sistema. La validación de la información se evalúa en dos niveles principales: respecto de la correcta identificación de cada taxón y en referencia con la exactitud del sitio al que se refiere cada dato. Para eso se utilizan estándares y criterios consensuados con el resto de los sistemas que conforman la',
    paragrafo15_1: 'Red de información sobre EEI',
    paragrafo16: 'Criterios para validar la identidad taxonómica de cada taxón',
    marcador8: '¿El proveedor del dato (autor de la publicación o colector del espécimen) puede considerarse un especialista del grupo en cuestión o tiene una experiencia equivalente?',
    marcador9: '¿La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del organismo en cuestión?',
    marcador10: '¿La ocurrencia está documentada en una publicación indexada y con evaluación de pares o en un documento técnico realizado o revisado por especialistas?',
    marcador11: '¿La información se corresponde con un ejemplar de herbario o con un espécimen depositado en una colección de museo?',
    marcador12: '¿La ocurrencia corresponde a una especie que ya estaba registrada para el país?',
    marcador13: '¿El grupo al que pertenece la especie tiene una taxonomía estable y bien conocida?',
    marcador14: '¿Las características de la especie permiten una determinación relativamente simple?',
    paragrafo17: 'Una respuesta positiva a al menos una de las preguntas 1, 2, 3 o 4 lleva directamente a la recomendación de considerar al dato como validado. En caso de responder negativamente a estas cuatro preguntas, la validación se obtendrá solamente con respuestas positivas a las tres últimas (5, 6 y 7).',
    paragrafo18: 'Criterios para validar la ubicación asociada a del sitio donde decimos que se detectó una especie',
    marcador15: '¿El dato incluye coordenadas geográficas? Se consideran tanto coordenadas tomadas directamente en el sitio de la observación como otras que correspondan a alguna referencia geográfica cercana (en el caso que esto se indique).',
    marcador16: '¿Se incluye una descripción del sitio que permite su identificación confiable?',
    marcador17: '¿La ocurrencia está documentada mediante una fotografía que permite la identificación confiable del sitio?',
    marcador17_1: '¿Las coordenadas geográficas coinciden con la ubicación del área descripta y/o fotografiada?',
    marcador18: '¿Las coordenadas geográficas provistas coinciden con la ubicación del sitio descripto y/o fotografiado?',
    marcador19: '¿La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión?',
    marcador20: '¿Las condiciones ecológicas del sitio, inferidas en función de la localización indicada, resultan compatibles con los requerimientos generales de nicho de la especie?',
    paragrafo19: 'El dato se considera validado, desde el punto de vista de su ubicación geográfica, si:',
    marcador21: 'se responde de manera afirmativa a las preguntas 1, 2 y/o 3, y 4 (el dato incluye coordenadas geográficas, se brinda una referencia inequívoca del sitio y/o se provee una fotografía que permite la identificación confiable de lugar y las coordenadas provistas coinciden con la ubicación del sitio descripto o fotografiado, o,',
    marcador22: 'se asigna una respuesta positiva o negativa a la pregunta 1 (se brindan o no coordenadas geográficas), una respuesta negativa a las preguntas 2 y 3 (no se describe en detalle el sitio ni se adjunta una fotografía que permita ubicarlo), pero se responde de manera positiva a las preguntas 5 y 6 (la especie ya fue citada para el país y la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, y las condiciones ecológicas del sitio resultan compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo20: 'La localización se considerará no validada si: ',
    marcador23: 'se responde de manera positiva a la pregunta 1 y a las preguntas 2 y/o 3, pero negativa a la 4 (las coordenadas no coinciden con el sitio descripto o fotografiado), o,',
    marcador24: 'se responde de manera negativa a las preguntas 2 y 3, y negativa a al menos una de las preguntas 5 y 6 (el dato no cumple con una o con ninguna de estas dos condiciones: 1- La especie ya está citada para el país y, en ese caso, la localidad está incluida dentro de su rango de distribución conocido o en un sitio que resulta compatible con los sitios de presencia conocida y con su capacidad de dispersión, 2- el sitio reúne condiciones ecológicas compatibles con los requerimientos generales de nicho de la especie).',
    paragrafo21: 'Finalmente, el dato se considera validado en caso de pasar el análisis de validación de los dos criterios (taxonomía y ubicación). ',
    paragrafo22: 'La validación no se propone como una regla de inclusión o no de un dato, sino como un descriptor que acompaña a cada localidad de ocurrencia. Cada localidad de ocurrencia de cada especie se asocia al descriptor “dato validado” o “dato no validado”. Es importante entender que esta clasificación es temporaria, ya que un dato puede confirmarse y pasar a estar validado si cumple los criterios planteados en algún momento luego de haber sido cargado en el sistema. Para facilitar la visualización del estado de los datos, el sistema de mapeo de distribución de ocurrencias muestra las localidades validadas en color verde y no validadas en color rojo.',
    paragrafo22_1: 'Usted puede aportar datos que ayuden a validar la presencia de las especies exóticas invasoras en las distintas localidades de la República Argentina. Para ello, por favor envíe un mail a',
    paragrafo22_2: 'inbiar@uns.edu.ar',
    paragrafo22_3: 'con el asunto “Validación de dato”.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comité científico',
    paragrafo1: 'Este sistema de bases de datos cuenta con un grupo científico de apoyo que coopera para mantener la calidad, la validez y la actualización de la información. El grupo incluye especialistas en distintos grupos de animales, plantas y microorganismos, así como investigadores con una extensa experiencia en el estudio de los distintos biomas de nuestro país. Su papel incluye la colaboración para confirmar la identidad de los organismos detectados, así como para apoyar la información referida a la presencia de nuevas especies en el territorio nacional y de nuevas localidades de ocurrencia en regiones donde aún no estaban citadas.',
    paragrafo2: 'Este Comité Científico está integrado por:',
    marcador1: 'Sergio M. Zalba, Biólogo, MSc. en Manejo de Vida Silvestre, Dr. en Biología. Profesor Universidad Nacional del Sur, Investigador CONICET. Administrador de la base de datos;',
    marcador2: 'Sílvia R. Ziller, Ingeniera Forestal, Dra. en Conservación de Biodiversidad. Instituto Horus de Desarrollo y Conservación Ambiental. Administradora de la base de datos de especies exóticas invasoras de Brasil;',
    marcador3: 'Alberto L. Scorolli, Biólogo, MSc. y Dr. en Biología. Profesor Universidad Nacional del Sur. Especialista en ungulados invasores;',
    marcador4: 'Carlos Villamil, Ingeniero Agrónomo, PhD. Profesor Emérito Universidad Nacional del Sur. Especialista en taxonomía de plantas vasculares y en floras regionales de la República Argentina;',
    marcador4b: 'Evangelina Schwindt, Bióloga, Dra. en Ciencias Biológicas. Investigadora CONICET. Grupo de Ecología en Ambientes Costeros (GEAC), Centro Nacional Patagónico (CENPAT-CONICET). Especialista en especies exóticas marinas y costeras;',
    marcador4c: 'Jorge Liotta, Biólogo, Museo de Ciencias Naturales P. Scasso. Especialista en peces continentales;',
    marcador4d: 'Sebastián Di Martino, Zoólogo, Director de Conservación. Fundación Rewilding Argentina;',
    marcador4e: 'Dra. M. Laura Guichón, Grupo de Ecología Terrestre de Neuquén, Instituto de Investigaciones en Biodiversidad y Medioambiente (INIBIOMA, CONICET-UNCo), Subsede Junín de los Andes. Centro de Ecología Aplicada del Neuquén (CEAN). Especialista en mamíferos invasores;',
    marcador4f: 'Lic. Francisco Firpo Lacoste, Dirección Nacional Dirección Nacional de Gestión Ambiental del Agua y los Ecosistemas Acuáticos, Ministerio de Ambiente y Desarrollo Sostenible de Nación. Especialista em peixes;',
    marcador4g: 'Dras. Cristina Sanhueza y Yannina Cuevas, Universidad Nacional del Sur. Encargadas de gestión de datos.',
    paragrafo3: 'Funciones del Comité Científico:',
    marcador5: '1- decidir la inclusión de nuevas especies en la base de datos;',
    marcador6: '2- decidir la eliminación de especies por falta o inconsistencia de los datos;',
    marcador7: '3- apoyo para la validación de la información en la base de datos;',
    marcador8: '4- apoyo para la revisión y búsqueda de datos;',
    marcador9: '5- apoyo para la búsqueda y consulta a colaboradores y expertos para la contribución y validación de datos;',
    marcador10: '6- recomendaciones para mejorar la base de datos;',
    marcador11: '7- mejora continua del nivel de información.'
  },
  fornecerDados: {
    cabecalhoTitulo: 'Colabore con información',
    paragrafo1: 'Nuestras bases de datos se mantienen actualizadas gracias a los aportes de especialistas y observadores de la naturaleza distribuidos en todo el territorio nacional. Antes de publicarse, la información provista es validada siguiendo criterios acordados a nivel regional. Usted puede aportar datos sobre especies aún no incluidas en este sistema o sobre nuevas localidades de ocurrencia descargando nuestra app (Invasoras AR) o enviando un e-mail a',
    paragrafo1_1: 'inbiar@uns.edu.ar',
    paragrafo2: 'En ese mail podrá, además, informarnos acerca de publicaciones o proyectos sobre invasiones biológicas en Argentina. Los proveedores de datos que realizan aportes de manera regular reciben un usuario y una contraseña para la carga de información. Ayúdenos a enfrentar el desafío de las especies exóticas invasoras sobre la base de información completa, actualizada y confiable!',
    paragrafo3: 'Muchas gracias!'
  },
  especiesInstitucional: {
    titulo: 'Búsqueda por especie',
    paragrafo1: 'Busque aquí información detallada acerca de las especies exóticas invasoras y potencialmente invasoras presentes en la República Argentina, sus características y efectos, y las opciones de control.',
    paragrafo2_1: 'Usted puede contribuir con información sobre nuevas localidades de ocurrencia o sobre especies aún no incluidas en esta base de datos, haciendo clik ',
    paragrafo2_2: 'aquí',
    verTutorial: 'Abrir tutorial',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Consultado DD/MM/AAA.',
    paragrafo4_2: '',
    tutorialTitulo1: 'Cómo citar la base de datos',
    tutorialDescricao1: 'Lo invitamos a utilizar la información disponible en este sistema de bases de datos citándolo para que más personas puedan conocerlo y aprovechar la información que contiene:',
    tutorialTitulo2: 'Use los filtros',
    tutorialDescricao2: 'Aquí tenemos algunas opciones para filtrar contenido.',
    tutorialTitulo3: 'Use los filtros: seleccione datos',
    tutorialDescricao3: 'Elija filtros y haga combinaciones para seleccionar datos por lugar, forma biológica, uso, grupo taxonómico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este es el número de especies exóticas invasoras en la base de datos resultante de las búsquedas con filtros.',
    tutorialDescricao5: 'Aquí puede elegir cuántos registros ver por página.',
    tutorialTitulo6: 'Lista de especies exóticas invasoras',
    tutorialDescricao6: 'Lista de todas las especies o resultados del uso de filtros. Haga clic sobre el nombre de la especie para ver los datos completos.',
    tutorialTitulo6_1: 'Exportar datos',
    tutorialDescricao6_1: 'Aquí puede filtrar datos y elegir el formato para exportar.',
    tutorialTitulo7: 'Taxonomía',
    tutorialDescricao7: 'Clasificación taxonómica de la especie seleccionada, nombres comunes, sinónimos, y una foto cuando fuera posible!',
    tutorialTitulo8: 'Ecología y uso',
    tutorialDescricao8: 'Descripción, biología y ecología de la especie seleccionada, de dónde viene, que ambientes invade y usos económicos.',
    tutorialTitulo9: 'Introducción y dispersión',
    tutorialDescricao9: 'Cómo llegó la especie al sitio donde es invasora, por qué fue introducida y cómo se dispersa a otros sitios.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientales, económicos, a la salud y culturales. Clasificación de impactos ambientales por el protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detección temprana, control mecánico, químico y biológico para la especie seleccionada.',
    tutorialTitulo12: 'Análisis de Riesgo',
    tutorialDescricao12: 'Resultados de análisis de riesgo para invasión biológica realizadas para la especie seleccionada.',
    tutorialTitulo13: 'Ocurrencias',
    tutorialDescricao13: 'Sitios, ambientes, áreas protegidas, estados donde la especie ocurre en el país y estadio de invasión – vea el mapa!',
    tutorialDescricao14: 'Lista de referencias usadas para compilar datos sobre la especie seleccionada y otras publicaciones sobre la especie.',
    tutorialTitulo15: 'Proyectos',
    tutorialDescricao15: 'Lista de proyectos de investigación y manejo que incluyen la especie seleccionada.'

  },
  colaboradoresInstitucional: {
    titulo: 'Búsqueda por colaborador',
    paragrafo1: 'Los contactos incluyen a quienes aportan datos para este sistema de información, así como a quienes colectaron especímenes de museo o ejemplares de herbario, o son autores de publicaciones que se utilizaron como fuentes de datos.',
    paragrafo2: '',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Consultado DD/MM/AAA.',
    paragrafo4_2: ''
  },
  especiesMarinhasInstitucional: {
    titulo: 'Especies marinas y costeras',
    paragrafo1: 'En la mayoría de los casos son transportadas de manera accidental, muchas veces con el agua de lastre o adheridas al casco de los navíos. Su presencia suele pasar inadvertida durante las primeras fases de invasión y cuando son detectadas las chances de controlarlas son muy limitadas por las restricciones que impone el ambiente en que se establecen. Es por esto que los mayores esfuerzos se destinan a la prevención de nuevas introducciones. El problema es una prioridad a nivel global y ha motivado el desarrollo del',
    paragrafo1_1: 'Convenio Global de Manejo de Agua de Lastre y Sedimentos',
    paragrafo1_2: ', del que la República Argentina es signataria.',
    paragrafo2: 'Otras especies, como la ostra del Pacífico, son introducidas voluntariamente para la producción. Más información acerca del problema de las especies invasoras de la costa argentina puede obtenerse en la página del',
    paragrafo2_1: 'Grupo de Ecología en Ambientes Costeros',
    paragrafo2_2: ', CENPAT-CONICET.',
    paragrafo4: 'Amphibalanus amphitrite - diente de perro',
    paragrafo4b: 'Foto Nicolas Battini',
    paragrafo5: 'Botryllus schlosseri - tunicado de estrella',
    paragrafo5b: 'Foto Nicolas Battini',
    paragrafo6: 'Carcinus maenas - cangrejo verde europeo',
    paragrafo6b: 'Foto Alejandro Bortolus',
    paragrafo7: 'Crassostrea gigas - ostra del Pacífico',
    paragrafo7b: 'Foto Silvana Burela',
    paragrafo8: 'Ficopomatus enigmaticus – poliqueto formador de arrecifes',
    paragrafo8b: 'Foto Alejandro Bortolus'
  },
  especiesAguaDulceInstitucional: {
    titulo: 'Especies de agua dulce',
    paragrafo1: 'Al igual que en el caso de las especies marinas y costeras, es común que las especies de agua dulce se introduzcan con el agua de lastre, en asociación con buques que provienen de puertos ubicados en la desembocadura de grandes ríos. De esta manera algas, moluscos, crustáceos y microorganismos consiguen atravesar la barrera que históricamente les impusieron los mares y océanos. Una vez introducidas es común que se dispersen asociadas al transporte fluvial, a la navegación deportiva o a la pesca.',
    paragrafo3: 'Los peces de aguas continentales, en cambio, suelen ser introducidos de manera voluntaria, sobre todo para la pesca deportiva, como ocurre con las truchas y otras especies de salmónidos que fueron, y aún hoy, son “sembrados” en ríos, arroyos y lagos de casi todo el país, donde provocan serios impactos como predadores de especies nativas.',
    paragrafo5: 'Acipenser gueldenstaedtii - esturión ruso',
    paragrafo5b: 'Foto Danilo Demonte',
    paragrafo6: 'Corbicula fluminea - almeja asiática',
    paragrafo6b: 'Foto Silvana Burela',
    paragrafo7: 'Limnoperna fortunei - mejillón dorado',
    paragrafo7b: 'Foto Silvana Burela',
    paragrafo8: 'Litobathes catesbeianus - rana Toro',
    paragrafo8b: 'Foto Walter Prado',
    paragrafo9: 'Oncorhynchus tshawytscha - salmón chinook',
    paragrafo9b: 'Foto Jorge Liotta',
    paragrafo10: 'Sinotaia quadrata - caracol asiático grande',
    paragrafo10b: 'Foto Silvana Burela'
  },
  insectosInstitucional: {
    titulo: 'Insectos y otros invertebrados terrestres',
    paragrafo1: 'Típicamente transportados de manera accidental, como carnada viva, contaminantes de semillas o acompañando distintos tipos de cargas. Algunas especies son introducidas como polinizadores de cultivos, por ejemplo para plantas cultivadas en invernáculos o para pasturas, otras se importan como agentes de control biológico y, cada vez más frecuentemente, como animales de compañía. Uno de los insectos más ampliamente distribuido en el mundo en asociación con las actividades humanas es la abeja melífera, una especie que es, a la vez, soporte de actividades económicas pero también causante de impactos sobre la biodiversidad.',
    paragrafo2: 'Algunos invertebrados están asociados con impactos sobre la producción agrícola y otros con la transmisión de enfermedades, como ocurre con el mosquito de la fiebre amarilla y el caracol gigante africano.',
    paragrafo4: 'Aedes aegypti - mosquito tigre',
    paragrafo4b: 'Foto: ENEEI Argentina',
    paragrafo5: 'Harmonia axyridis - vaquita asiática multicolor',
    paragrafo5b: 'Foto: Cristina Sanhueza',
    paragrafo6: 'Rumina decollata - caracol degollado',
    paragrafo6b: 'Foto: Silvana Burela',
    paragrafo7: 'Bombus terrestris - abejorro común',
    paragrafo7b: 'Foto: Carolina Morales'
  },
  animalesonamentalescompaniaacuarioInstitucional: {
    titulo: 'Animales onamentales, de compañía y de acuario',
    paragrafo1: 'El comercio internacional de animales de compañía y de acuario es una de las vías más frecuentes de introducción voluntaria de vertebrados e invertebrados a nivel mundial. La variedad de especies que se comercializan a nivel internacional crece con los procesos de globalización y el comercio vía internet intensifica el intercambio informal, y con frecuencia ilegal, de especies. Muchas de estas especies acaban siendo liberadas en ambientes naturales o seminaturales donde, en algunos casos, pueden establecer poblaciones silvestres, como ocurre con la tortuga de orejas rojas, o aún si no consiguen reproducirse, afectar a la fauna nativa como predadores o transmisores de enfermedades.',
    paragrafo2: 'La actividad de acuarismo implica, además, la introducción de otros organismos potencialmente invasores como algas y "rocas vivas".',
    paragrafo4: 'Callosciurus erythraeus - ardilla de vientre rojo',
    paragrafo4b: 'Foto: ENEEI Argentina',
    paragrafo5: 'Callosciurus erythraeus - ardilla de vientre rojo',
    paragrafo5b: 'Foto: Fernando Milesi',
    paragrafo6: 'Felis catus - gato doméstico',
    paragrafo6b: 'Foto: Instituto Hórus',
    paragrafo7: 'Sturnus vulgaris - estornino',
    paragrafo7b: 'Foto: ENEEI Argentina',
    paragrafo8: 'Trachemys scripta - tortuga de orejas rojas',
    paragrafo8b: 'Foto: Marcos Tortato'
  },
  plantasOrnamentalesInstitucional: {
    titulo: 'Plantas ornamentales',
    paragrafo1: 'La jardinería y el paisajismo se cuentan entre las principales actividades responsables de la introducción de especies invasoras en todo el mundo. Más de un tercio de las plantas invasoras de la República Argentina registran usos como plantas de parques y jardines. La fiscalización del comercio es compleja ya que el tamaño de las semillas permite transportar muchos individuos de muchas especies en contenedores reducidos.',
    paragrafo2: 'A esto se suma la percepción generalizada de que nada malo puede traer el cultivo de plantas de valor ornamental y que, en el peor de los casos, su escape hacia la naturaleza no hace más que “embellecer” los paisajes. Lejos de eso, algunas de las invasoras de mayo impacto en nuestro país, como el ligustro, las retamas, la madreselva, o el lirio amarillo, se escaparon alguna vez de los jardines.',
    paragrafo3: 'Ligustrum lucidum - ligustro',
    paragrafo3b: 'Foto: Sergio Zalba',
    paragrafo4: 'Prunus mahaleb - cerezo silvestre',
    paragrafo4b: 'Foto: Martín Amodeo',
    paragrafo5: 'Prunus mahleb - cerezo silvestre',
    paragrafo5b: 'Foto: Gabriela Brancatelli',
    paragrafo6: 'Rosa sp. - rosa mosqueta',
    paragrafo6b: 'Foto: Sergio Zalba'
  },
  especiesForestalesInstitucional: {
    titulo: 'Especies forestales',
    paragrafo1: 'Los árboles son introducidos con una variedad de propósitos, desde la actividad forestal hasta la creación de cortinas corta viento, la producción de leña y de forraje, la estabilización de dunas, el uso ornamental, el interés botánico y la fijación de dióxido de Carbono. El éxito de los árboles invasores suele ser particularmente notable en regiones de sabana y estepa, como es el caso de la Región Pampeana y de la Estepa Patagónica en la República Argentina, allí ocupan un nicho ecológico que estaba desocupado hasta su llegada y alteran no solo la composición de las comunidades vegetales sino la propia estructura del ecosistema.',
    paragrafo2: 'Más allá de eso, especies forestales invasoras, incluyendo el ligustro, el paraíso, la acacia negra, y distintas especies de pinos amenazan la conservación de la biodiversidad en distintos biomas de nuestro país.',
    paragrafo4: 'Acacia mearnsii - acacia negra',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Pinus halepensis - pino tosquero',
    paragrafo5b: 'Foto: Gabriela Brancatelli',
    paragrafo6: 'Tamarix ramosíssima - tamarisco',
    paragrafo6b: 'Foto: Evangelina Natale'
  },
  ingenierosInstitucional: {
    titulo: 'Ingenieros de ecosistemas y especies clave',
    paragrafo1: 'Si bien los efectos más conocidos de las especies invasoras sobre la biodiversidad se refieren al impacto de una especie introducida sobre una o unas pocas especies nativas, hay invasores que producen cambios que afectan el funcionamiento de sistemas ecológicos completos. Estas especies juegan roles ecológicos clave e incluyen, por ejemplo, predadores oportunistas que pueden diezmar comunidades completas de presas, herbívoros ungulados que afectan la estructura y la composición de la vegetación de pastizales, arbustales y bosques, gramíneas que alteran la frecuencia e intensidad de los incendios, plantas fijadoras de nitrógeno que modifican la dinámica de este nutriente en los suelos y árboles capaces de alcanzar depósitos subterráneos de agua y de provocar la desecación de pequeños espejos de agua.',
    paragrafo4: 'Equus caballus - caballo',
    paragrafo4b: 'Foto: Alberto Scorolli',
    paragrafo5: 'Impacto de castor (Castor canadensis)',
    paragrafo5b: 'Foto: Erio Curto',
    paragrafo6: 'Bubalus bubalis - búfalo de agua',
    paragrafo6b: 'Foto: Pablo Petracci',
    paragrafo7: 'Capra hircus - cabra',
    paragrafo7b: 'Foto: Pablo Petracci',
    paragrafo8: 'Sus scrofa - jabalí',
    paragrafo8b: 'Foto: Pablo Petracci'
  },
  projetosInstitucional: {
    titulo: 'Búsqueda por proyecto',
    paragrafo1: 'En esta sección se incluye información acerca de los proyectos actuales o anteriores enfocados en el estudio y el manejo de las especies exóticas invasoras y potencialmente invasoras presentes en la República Argentina. Si participa o participó de algún proyecto referido a estas especies, por favor ayude a incorporarlo enviando la información correspondiente a inbiar@uns.edu.ar.',
    paragrafo2_1: '',
    paragrafo2_2: '',
    paragrafo2_3: '',
    paragrafo3: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo4_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Consultado DD/MM/AAA.',
    paragrafo4_2: ''
  },
  referenciasInstitucional: {
    titulo: 'Búsqueda por referencia',
    paragrafo1: 'Las publicaciones e informes técnicos citados aquí fueron utilizados para sumar información acerca de las especies presentes en el territorio nacional, su comportamiento y efectos. Algunas incluyen datos acerca de las opciones de manejo de las distintas especies. Si es autor o conoce publicaciones en la materia que aún no están incluidas en esta sección, por favor ayude a incorporarlas enviando las citas correspondientes a inbiar@uns.edu.ar.',
    paragrafo2: '',
    paragrafo3: '',
    paragrafo4: 'Por favor utilice el siguiente formato para citar información consultada en este sistema:',
    paragrafo5_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina.',
    paragrafo5_2: 'Consultado DD/MM/AAA.'
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso forestal',
    paragrafo1: 'Varias especies de árboles exóticos se introdujeron en Brasil a partir de la década de 1950 por iniciativa del gobierno para desarrollar la industria forestal con el fin de producir papel, pulpa, madera y otros subproductos. Algunas de estas especies se han convertido en invasoras, y los pinos están especialmente distribuidos en numerosos ambientes. Estas especies requieren una gestión que incluya el control continuo y la contención en las masas forestales para evitar el impacto ambiental y paisajístico. La certificación forestal exige el control de las especies exóticas invasoras y es necesario eliminar estos árboles de las áreas naturales, los bordes de las carreteras, las vías férreas, los bosques de ribera y otros ambientes donde no estén cultivados.',
    paragrafo2: 'El género Pinus se considera el género de plantas con mayor área de invasión en el hemisferio sur. El pino desplaza a las especies nativas en los ecosistemas abiertos y es un gran consumidor de agua, más que las especies nativas.',
    paragrafo3: 'Más información en este artículo.',
    paragrafo4: 'Pinus sp. - pino',
    paragrafo4b: 'Foto: Instituto Horus',
    paragrafo5: 'Acacia mearnsii – acacia negra',
    paragrafo5b: 'Foto: Instituto Horus',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Foto: Instituto Horus'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contacto',
    contato: 'Contacto',
    p1: 'Complete todos los campos del formulario siguiente:',
    nome: 'Nombre',
    email: 'Correo electrónico',
    instituicao: 'Institución',
    mensagem: 'Mensaje',
    enviar: 'Enviar'
  }
}

<template>
  <div class="temas">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides">
        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/especies-marinhas">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/especies-marinhas"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-tematica-esp-marinas.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/especies-marinhas">Marine and coastal species</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/especies-agua-dulce">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/especies-agua-dulce"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-tematica-agua-dolce.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/especies-agua-dulce">Freshwater species</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/insectos">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/insectos"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-tematica-insecto.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/insectos">Insects and other terrestrial invertebrates</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/animales-onamentales-compania-acuario">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/animales-onamentales-compania-acuario"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-tematica-animales.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/animales-onamentales-compania-acuario">Ornamental, companion and aquarium animals</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/plantas-ornamentales">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/plantas-ornamentales"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-avancada-ornamentales.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/plantas-ornamentales">Ornamental plants</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/especies-forestales">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/especies-forestales"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-avancada-forestales.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/especies-forestales">Forest species</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>

        <li class="glide__slide">
          <div class="gallery-block">
        <router-link to="/ingenieros">
          <div class="inner-box">
            <div class="image">
              <div class="hover-color-layer"></div>
              <router-link class="arrow ion-android-arrow-forward" to="/ingenieros"><img src="../../assets/institucional/img/icon/seta-direita.svg"></router-link>
              <img src="../../assets/institucional/img/galeria/busca-avancada-ingenieros.jpg" alt="" />
              <div class="overlay-box">
                <div class="content">
                  <h3><router-link to="/ingenieros">Key Species and Ecosystem Engineers</router-link></h3>
                </div>
              </div>
            </div>
          </div>
        </router-link>
          </div>
        </li>
      </ul>
    </div>

    <div class="slider__bullets glide__bullets" data-glide-el="controls[nav]">
      <button class="slider__bullet glide__bullet active" data-glide-dir="=0"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=1"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=2"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=3"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=4"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=5"></button>
      <button class="slider__bullet glide__bullet" data-glide-dir="=6"></button>
    </div>
  </div>
</template>

<script>
import '@glidejs/glide/dist/css/glide.core.min.css'
import '@glidejs/glide/dist/css/glide.theme.min.css'
import Glide from '@glidejs/glide'
export default {
  name: 'InstitucionalSliderTemasGlideEn',
  mounted () {
    const glideTemas = new Glide('.temas', {
      type: 'carousel',
      autoplay: 3500,
      perView: 6,
      gap: 10,
      breakpoints: {
        1440: {
          perView: 5
        },
        1200: {
          perView: 4
        },
        992: {
          perView: 3
        },
        768: {
          perView: 3
        },
        480: {
          perView: 2
        },
        0: {
          perView: 1
        }
      }
    })
    glideTemas.mount()
  }
}
</script>

<template>
  <div>
    <InstitucionalHeaderSingle :classProp="'header-single header-rede'" :title="`${$t('redeBaseDeDados.cabecalhoTitulo') }`"/>
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">

          <p>{{ $t('redeBaseDeDados.paragrafo1') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo2') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo2_1') }}</p>
          <ul>
            <li>
              <a href="http://www.inbiar.uns.edu.ar/" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.argentina') }}</font></a>
               {{ $t('redeBaseDeDados.marcador1') }}</li>
            <li>
              <a href="https://bd.institutohorus.org.br" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.brasil') }}</font></a>
               {{ $t('redeBaseDeDados.marcador2') }}</li>
            <li>
              <a href="" target="_blank"><font style="vertical-align: inherit;">Chile</font></a>
               {{ $t('redeBaseDeDados.marcador3') }}</li>
            <li>
              <a href="https://cr-invasoras.ucr.ac.cr/" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.costarica') }}</font></a>
               {{ $t('redeBaseDeDados.marcador4') }}</li>
            <li>
              <a href="https://invasoras.biodiversidad.gob.ec" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.ecuador') }}</font></a>
               {{ $t('redeBaseDeDados.marcador5') }}</li>
            <li>
              <a href="https://hn-invasoras.ucr.ac.cr/" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.honduras') }}</font></a>
               {{ $t('redeBaseDeDados.marcador5_1') }}</li>
            <li>
              <a href="https://jamaicainvasives.licj.org.jm/" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.jamaica') }}</font></a>
               {{ $t('redeBaseDeDados.marcador5_2') }}</li>
            <li>
              <a href="https://py-invasoras.ucr.ac.cr/" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.paraguai') }}</font></a>
               {{ $t('redeBaseDeDados.marcador5_3') }}</li>
            <li>
              <a href="https://sieei.udelar.edu.uy" target="_blank"><font style="vertical-align: inherit;">{{ $t('redeBaseDeDados.uruguai') }}</font></a>
               {{ $t('redeBaseDeDados.marcador5_4') }}</li>
          </ul>
          <p>{{ $t('redeBaseDeDados.paragrafo3') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo4') }}</p>
          <p>{{ $t('redeBaseDeDados.paragrafo5') }}</p>
          <ul>
            <li>{{ $t('redeBaseDeDados.marcador6') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador7') }}</li>
            <li>{{ $t('redeBaseDeDados.marcador8') }}</li>
          </ul>
          <p>{{ $t('redeBaseDeDados.paragrafo6') }}</p>

          <div class="container logo-parceiros pb-5" style="width: 110%;">
          <div class="row">
            <div class="col-sm">
              <h2 class="h5 laranja">{{ $t('redeBaseDeDados.paragrafo7') }}</h2>

                <div class="row mt-5">
                  <div class="col-sm col-md-12 col-lg-12 col-xxl-12 pb-2">
                    <h3 class="h5">Argentina</h3>

                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/apoio-dbbyf.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-gekko.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-uns.png" ></li>
                    </ul>

                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-sm col-md-12 col-lg-3 col-xxl-3 pb-2">
                    <h3 class="h5">Brasil</h3>
                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/apoio-horus.png" ></li>
                    </ul>
                  </div>
                  <div class="col-sm col-md-12 col-lg-3 col-xxl-3 pb-2">
                    <h3 class="h5">Chile</h3>

                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/apoio-lib.png" ></li>
                    </ul>

                  </div>
                  <div class="col-sm col-md-12 col-lg-6 col-xxl-6 pb-2">
                    <h3 class="h5">Costa Rica</h3>
                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/apoio-cibet.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-ucr.png" ></li>
                    </ul>
                  </div>
                  <div class="col-sm col-md-12 col-lg-6 col-xxl-2 pb-2">

                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-sm col-md-12 col-lg-12 col-xxl-12 pb-2">
                    <h3 class="h5">Ecuador</h3>
                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/logo_inabio4.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/logo_invasoras_ec.png"></li>
                      <li><img src="../../../assets/institucional/img/logo/logo_republica_del_ecuador.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/logo_gobierno_del_encontro.png"></li>
                    </ul>
                  </div>

                </div>

                <div class="row mt-5">
                  <div class="col-sm col-md-12 col-lg-8 col-xxl-8 pb-2 ">
                    <h3 class="h5">Honduras</h3>
                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/apoio-herbario-tefh.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-unah1.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-unah2.png" ></li>
                    </ul>
                  </div>
                  <div class="col-sm col-md-12 col-lg-4 col-xxl-4 pb-2">
                    <h3 class="h5">Jamaica</h3>
                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/logo_jamaica.png" ></li>
                    </ul>
                  </div>
                </div>

                <div class="row mt-5">
                  <div class="col-sm col-md-12 col-lg-3 col-xxl-3 pb-2">
                    <h3 class="h5">Paraguay</h3>
                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/apoio-guyra.png" ></li>
                    </ul>
                  </div>
                  <div class="col-sm col-md-12 col-lg-9 col-xxl-9 pb-2">
                    <h3 class="h5">Uruguay</h3>
                    <ul>
                      <li><img src="../../../assets/institucional/img/logo/apoio-comite.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-faculdad-del-ciencia.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-ministerio.png" ></li>
                      <li><img src="../../../assets/institucional/img/logo/apoio-universidad-de-la-republica.png" ></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'RedeBaseDeDados',
  components: { InstitucionalHeaderSingle }
}
</script>

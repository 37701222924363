export const en = {
  message: {
    requiredField: 'Required field',
    noRecords: 'No record found!',
    confirmDel: 'Are you sure you wish to delete?',
    confirmApproval: 'Are you sure you wish to approve this occurrence?',
    noResultsFor: 'No results for'
  },
  login: {
    username: 'User',
    password: 'Password',
    login: 'Login',
    entrar: 'Log in',
    descricao: 'Enter your access data in the form below:'
  },
  exportarFicha: {
    author: 'author',
    biodiversity_impact: 'biodiversity_impact',
    chemical_control: 'chemical_control',
    class: 'class',
    common_name: 'common_name',
    control_biological: 'control_biological',
    data_bibliography: 'data_bibliography',
    economic_impact: 'economic_impact',
    economic_use: 'economic_use',
    family: 'family',
    health_impact: 'health_impact',
    intro_data: 'intro_data',
    intro_description: 'intro_description',
    invasion_preferential_environments: 'invasion_preferential_environments',
    kingdom: 'kingdom',
    location: 'location',
    morphophysiology_description: 'morphophysiology_description',
    native_distribution_area: 'native_distribution_area',
    natural_environment: 'natural_environment',
    oorder: 'oorder',
    origin: 'origin',
    phyllum: 'phyllum',
    physical_control: 'physical_control',
    prevention: 'prevention',
    scientific_name: 'scientific_name',
    social_impact: 'social_impact',
    species_id: 'species_id',
    voc_cause_introduction: 'voc_cause_introduction',
    voc_diet: 'voc_diet',
    voc_dispersal_routes: 'voc_dispersal_routes',
    voc_dispersion_vectors: 'voc_dispersion_vectors',
    voc_form: 'voc_form',
    voc_introduction_type: 'voc_introduction_type',
    voc_reproduction: 'voc_reproduction',
    voc_spread: 'voc_spread',
    world_invasive_place: 'world_invasive_places'
  },
  label: {
    voltar: 'Back',
    outro: 'Other',
    filtros: 'Filters',
    acesseBaseDados: 'Access the database',
    conhecaNossaRede: 'Latin American Network',
    baseDadosNacional: 'InBiAr - Biological Invasions in Argentina',
    politicaDeDados: 'Data policy',
    comiteCientifico: 'Scientific Committee',
    colabore: 'Provide data',
    especiesMarinhas: 'Marine and coastal species',
    peixesAquario: 'Aquarium fishes',
    peixesAquicultura: 'Aquaculture fishes',
    pescaDesportiva: 'Sport fishing',
    pets: 'Pets',
    plantasForrageiras: 'Forage plants',
    plantasOrnamentais: 'Ornamental plants',
    usoFlorestal: 'Forestry',
    tutorial: 'Tutorial',
    especies: 'Species',
    colaboradores: 'Collaborators',
    referencias: 'References',
    projetos: 'Projects',
    saibaMais: 'Find out more',
    contida: 'Contained',
    casual: 'Casual',
    estabelecida: 'Established',
    invasora: 'Invasive',
    exibirListagem: 'View List',
    concluir: 'Complete',
    fechar: 'Close',
    próximo: 'Next',
    anterior: 'Former',
    clickAqui: 'Click here',
    numRegistrosEncontrados: 'Number of records found:',
    exibir10Reg: 'Display 10 records',
    exibir25Reg: 'Display 25 records',
    exibir50Reg: 'Display 50 records',
    exibir100Reg: 'Display 100 records',
    limpar: 'Clear',
    buscar: 'Search',
    addColaborador: 'Add collaborator',
    nome: 'Name',
    sobrenome: 'Last name',
    aprovarColaborador: 'Approve collaborator',
    salvarColaborador: 'Save collaborator',
    titulo: 'Title',
    cargo: 'Position',
    especialidade: 'Expertise',
    instituicao: 'Institution',
    endereco: 'Address',
    estado: 'State',
    cidade: 'City',
    pais: 'Country',
    email: 'E-mail address',
    telefone: 'Telephone 1',
    telefone2: 'Telephone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Social media 1',
    midiaSocial2: 'Social media 2',
    midiaSocial3: 'Social media 3',
    observacoesAdicionais: 'Additional observations',
    adicionar: 'Add',
    editando: 'Editing',
    acoes: 'Actions',
    usuarixs: 'Users',
    adicionarUsuario: 'Add user',
    descricao: 'Description',
    ativo: 'Active',
    sim: 'Yes',
    nao: 'No',
    salvarUsuarix: 'Save user',
    senha: 'Password',
    observacoes: 'Observations',
    grupos: 'Groups',
    basico: 'Basic',
    administrator: 'Administrator',
    taxonomia: 'Taxonomy',
    nomeCientifico: 'Latin name',
    adicionarEspecie: 'Add species',
    genero: 'Genus',
    familia: 'Family',
    ordem: 'Order',
    classe: 'Class',
    phylumDivisao: 'Phylum / Division',
    reino: 'Kingdom',
    phylum: 'Phylum',
    origem: 'Origin',
    descricaoInvalida: 'Invalid description',
    selecioneFamilia: 'Please select a Family.',
    selecioneOrdem: 'Please select an Order.',
    selecioneClasse: 'Please select a Class.',
    selecioneDivisao: 'Please select a Phylum.',
    selectioneReino: 'Please select a Kingdom.',
    selectioneGenero: 'Please select a Genus.',
    addReferencia: 'Add reference',
    autoresEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Approve reference',
    salvarReferencia: 'Save reference',
    autorxsEX: 'Authors (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Authors',
    local: 'Location (Ex. Bahía Blanca, Buenos Aires  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Location',
    edicao: 'Edition (ex. 2 ed.)',
    editora: 'Editor',
    revista: 'Journal',
    anoPublicacao: 'Publication year',
    meioDivulgacao: 'Publication means',
    volume: 'Volume (ex. v. 3)',
    numero: 'Number (ex. n. 28)',
    pagina: 'Pages (ex. p. 25-34 / 245p.)',
    url: 'DOI / URL',
    palavraChave: 'Keywords',
    anoPublic: 'Publication year',
    addProjeto: 'Add project',
    aprovarProjeto: 'Approve project',
    salvarProjeto: 'Save project',
    contato: 'Contact',
    areaGeografica: 'Geographic range',
    objetivos: 'Objectives',
    breveDescricao: 'Brief description',
    dataInicio: 'Start date',
    dataTermino: 'End date',
    comentarios: 'Comments',
    anoInicio: 'Start year',
    anoTermino: 'End year',
    ocorrencias: 'Occurrences',
    exportarDados: 'Export data',
    addOcorrencia: 'Add occurrence',
    visualizarOcorrencia: 'View occurrence',
    alterarOcorrencia: 'Change occurrence',
    ocorrenciasVinculadas: 'Linked occurrences',
    areaProtegida: 'Protected area',
    especie: 'Species',
    aprovarOcorrencia: 'Approve occurrence',
    salvarOcorrencia: 'Save occurrence',
    colaborador: 'Collaborator',
    municipio: 'Municipality',
    municipioSede: 'Municipality (city)',
    municipioCentroide: 'municipality (centroid)',
    referenciaLocal: 'Place reference',
    referencia: 'Reference',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'The coordinates correspond to',
    distanciaAproximada: 'Approximate distance',
    ambiente: 'Environment',
    baciaHidro: 'Basin',
    descricaoInvasao: 'Description of the invasion',
    abundancia: 'Abundance',
    situacaoPopulacional: 'Population status',
    manejo: 'Management',
    dataEntrada: 'Entry date',
    dataObservacao: 'Observation date (ex. 2020 ou 13/05/2020)',
    validado: 'Validated',
    especieMuseuHerbario: 'It is a museum or herbarium specimen',
    selecioneTipo: 'Select the type',
    herbario: 'Herbarium',
    museu: 'Museum',
    numeroExemplaInstituicao: 'Specimen number (Institution)',
    numeroExemplarColetor: 'Specimen number (Collector)',
    selecioneOpcao: 'Select an option',
    pointList1: 'A point of occurrence of one or more individuals of the species',
    pointList2: 'The center of a group of individuals of the species',
    pointList3: 'A point of occurrence at an approximate distance of:',
    coordinatesSourceList1: 'A - A political ou administrative unit',
    coordinatesSourceList2: 'B - The actual site of occurrence',
    ultimoRegistroBaseDados: 'Latest records in database',
    ultimosAcessos: 'Latest access',
    fichaTecnicaEspecie: 'Species factsheet',
    fichaTecnicaEspecieMMA: 'Species factsheet MMA version 1',
    fichaTecnicaEspecieMMA2: 'Species factsheet MMA version 2',
    usoRestringido: 'Categoria 1 Restricted use',
    usoControlado: 'Categoria 2 Controlled use',
    noAsignada: 'Categoria 3 Not assigned',
    noEvaluada: 'Categoria 4 Not evaluated',
    formatosDisponiveisExportacao: 'Available formats for export:',
    biologiaEcologia: 'Biology and ecology',
    usoEconomico: 'Economic use',
    usoEconomicoDescricao: 'Economic use - description',
    invasaoBiologica: 'Biological invasion',
    impactos: 'Impacts',
    habitat: 'Habitat',
    nomeCientificoSinonimos: 'Scientific name + synonyms',
    nomesPopulares: 'Common names',
    reproducao: 'Reproduction',
    dispersao: 'Dispersal',
    dieta: 'Diet',
    formaBiologica: 'Biological form',
    ambientesPreferenciaisInvasao: 'Habitats more susceptible to invasion',
    tipoIntroducao: 'Introduction type',
    causaIntroducao: 'Cause of introduction',
    localIntroducao: 'Place of introduction',
    anoIntroducao: 'Year of introduction',
    marinho: 'Marine',
    dulcicola: 'Freshwater',
    terrestre: 'Terrestrial',
    validarEspecie: 'Validate species',
    salvarEspecie: 'Save species',
    data: 'Date',
    confiabilidade: 'Reliability',
    resultadosAnalisesRisco: 'Risk analysis results',
    risco: 'Risk',
    areaOrigem: 'Area of origin',
    areaDistribuicaoNatural: 'Native range',
    ambientesNaturais: 'Natural habitats',
    descricaoEspecie: 'Species description',
    ambientesPreferenciaisInvasaoDescricao: 'Preferred habitat types for invasion - description',
    outrosLocaisOndeEspecieInvasora: 'Other places where the species is invasive',
    impactosEcologicos: 'Ecological impacts',
    impactosEconomicos: 'Economic impacts',
    impactosSociais: 'Social impacts',
    impactosSaude: 'Health impacts',
    categoriaEICAT: 'EICAT category',
    mecanismosImpactoEICAT: 'EICAT impact mechanisms',
    nivelConfiancaAvaliacaoEICAT: 'EICAT assessment confidence level',
    referenciaEICAT: 'EICAT reference',
    dataEICAT: 'EICAT date (e.g. 2021)',
    categoriaSEICAT: 'SEICAT category',
    mecanismosImpactoSEICAT: 'SEICAT impact mechanisms',
    nivelConfiancaAvaliacaoSEICAT: 'SEICAT assessment confidence level',
    referenciaSEICAT: 'SEICAT reference',
    dataSEICAT: 'SEICAT date (e.g. 2021)',
    digitarSomenteAno: 'Enter year only - e.g. 2021',
    viasCDBCategorias: 'CDB pathways - categories',
    viasIntroducao: 'Pathways of introducion and spread',
    viasVetoresCDBSubcategorias: 'CDB pathways - subcategories',
    vetoresIntroducao: 'Vectors of introducion and spread',
    introducao: 'Introduction',
    adicionarIntroducao: 'Add introduction',
    ano: 'Year',
    descricaoIntroducao: 'Introduction description',
    salvar: 'Save',
    cancelar: 'Cancel',
    excluir: 'Delete',
    medidasPreventivas: 'Prevention measures',
    medidasPreventivasMMA: 'Prevention measures MMA',
    medidasDeteccaoPrecoce: 'Early detection measures',
    controleMecanico: 'Mechanical control',
    controleQuimico: 'Chemical control',
    controleBiologico: 'Biological control',
    distribuicaoPais: 'Distribution in the country',
    vejaMapaOcorrencias: 'See occurrence map',
    ecologiaUso: 'Ecology and use',
    introducaoDispersao: 'Introduction and spread',
    analiseRisco: 'Risk analysis',
    nomeComum: 'Common name',
    foto: 'Photograph',
    credito: 'Credit',
    sinonimos: 'Synonyms',
    autor: 'Author',
    subespecie: 'Subspecies',
    autorSubespecie: 'Author of subspecies',
    pendenteAnalise: 'Analysis pending',
    cancelarVoltar: 'Cancel / Back',
    versaoAnterior: 'Former version',
    versaoAtual: 'Current version',
    colaboradorxs: 'Collaborators',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomy - Complete list of species',
    consultaEspeciesOrigemAmbientes: 'Species, origin, habitats susceptible to invasion, and impacts',
    consultaEspeciesManejo: 'Species and management information',
    consultaParaGerarFichaTecnica: 'Species factsheet without places of occurrence',
    consultaEspeciesOcorrencias: 'Species and places of occurrence',
    exportarDadosConsultas: 'Export data',
    ocorrenciasApp: 'Occurrences app',
    dataCadastro: 'Registration date',
    criadoPor: 'Created by',
    nivelCerteza: 'Certainty level',
    byApp: 'From mobile app',
    verDados: 'View data',
    appuser: 'App user',
    profissao: 'Profession',
    colobadorJaCadastrado: 'Collaborator already registered',
    especialidadeApp: 'Expertise app',
    cep: 'Zip code',
    categoriaCDB: 'CBD Category',
    subCategoriaCDB: 'CDB Subcategory',
    especiesDeAguaDoce: 'Freshwater species',
    insetosOutrosInvertebradosTerrestres: 'Insects and other terrestrial invertebrates',
    animaisOrnamentaisCompanhiaAquario: 'Ornamental, companion and aquarium animals',
    plantasOrnamentales: 'Ornamental plants',
    especiesFlorestais: 'Forest species',
    especiesChaveEngenheirosEcossistema: 'Key Species and Ecosystem Engineers',
    mensagemEnviada: 'Message sent successfully.'
  },
  home: {
    destaque1: 'Welcome to this initiative aimed at protecting biodiversity against the challenge of biological invasions!',
    destaqueDescricao1: 'This database system receives contributions from people committed to the conservation of biodiversity. We invite you to visit our page!',
    destaque2: 'Since 2004 documenting invasive alien species in Argentina',
    destaqueDescricao2: 'This database has been developed and updated with funds from national and international projects. A Scientific Committee supports data management.',
    destaque3: 'What does this information system include?',
    destaqueDescricao3: 'We manage five interconnected databases on species, localities of occurrence, specialists and projects.',
    destaque4: 'How does it work?',
    destaqueDescricao4: 'You can consult about invasive and potentially invasive alien species, specialists, projects and bibliography in the Argentine Republic.',
    titulo: 'Look for information in our databases.',
    conteudo: 'Species and the sites where they are found within the national territory, specialists in the subject, references and projects dedicated to the study and management of this problem.',
    conteudoEspecies: 'This section of our system contains information about the invasive and potentially invasive alien species present in the Argentine Republic, their taxonomic classification, biological characteristics, forms of introduction and dispersal, distribution, abundance and management initiatives.',
    conteudoColaboradores: 'Registry of people who have contributed data to the database. If you need to find an expert or more information a place of occurrence, look for contacts here.',
    conteudoReferencias: 'Complete list of references used for the data fields on invasive alien species.',
    conteudoProjetos: 'Invasive alien species research and management projects in Argentina. If you are working on a project, please let us know, so we can register it.',
    titulo1: 'Learn more about some of the invasive alien species of Argentina',
    conteudo1: 'Invasive alien species belong to all groups of organisms and are introduced accidentally or voluntarily for a variety of reasons, their effects are also very diverse.'
  },
  areaParceiros: {
    titulo: 'Latin America and Caribbean Invasive Alien Species Database Network:',
    argentina: 'Argentina',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay',
    brasil: 'Brazil',
    jamaica: 'Jamaica'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Latin American Network',
    titulo: 'Biological Invasions in Argentina',
    paragrafo1: 'The origin of this database network dates back to 2004. It was part of the Thematic Network on Invasive Alien Species of the Inter-American Biodiversity Information Network (I3N-IABIN), established as an initiative of the Summit of the Americas.',
    paragrafo2: 'Each country had a Lead for the network in charge of developing a national database and sharing information on invasive species. The people in charge of the national databases are:',
    paragrafo2_1: '',
    marcador1: ': Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (since 2003);',
    marcador2: ': Dr. Silvia R. Ziller, Founder and Executive Director of the Horus Institute for Environmental Conservation and Development (since 2004);',
    marcador3: ': Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: ': PhD. Eduardo Chacón-Madrigal, Professor at the University of Costa Rica. Researcher at the Biodiversity and Tropical Ecology Research Center. Co-coordinator of Flora Invasive and Naturalized of Central America.',
    marcador5: ': Dr. Diego Inclán, Executive Director of the National Institute of Biodiversity (since 2021) and Francisco José Prieto Albuja;',
    marcador5_1: ': Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras;',
    marcador5_2: ': Dr. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (since 2004).',
    marcador5_3: ': Hugo Fernando del Castillo, Guyra Paraguay Bird Conservation (since 2006);',
    marcador5_4: ': Dr. Ernesto Brugnoli, Universidade da República. Mestrado - Marcelo Iturburu, Coordenador do Comitê de Espécies Exóticas Invasoras, Ministério do Meio Ambiente do Uruguai.',
    paragrafo3: 'IABIN received funding from a World Bank (GEF) project between 2004 and 2011, when it was terminated. Despite the closing of IABIN, some of the network Leads continued to develop and update the national databases (Brazil, Argentina, Uruguay, Paraguay and Jamaica).',
    paragrafo4: 'In 2021, the four South American countries received funding from the Bio-Bridge Initiative of the Convention on Biological Diversity to revitalize the Argentina, Brazil, Paraguay, and Uruguay databases. The interface was redesigned, new functions were added, and the programming was totally redone.',
    paragrafo5: 'The IT professionals responsible for the development of the Data Base are:',
    marcador6: 'João Scucato, from Curitiba - PR, Brazil, who developed the first version in Microsoft Access, 2004-2005;',
    marcador7: 'Alejandro Moreno, from Argentina, who developed the first open source versions of our databases and the web interface that was used until 2021. Since 2006, he provided support to the databases of the different countries. He kept copies of the files and rescued data from Paraguay and Uruguay for this new version. Alejandro also developed the Common Platform of the network countries.',
    marcador8: 'Designer Rafael Moura and Programmer Thiago Lôbo, from Brazil, through the Vibbra Platform in Florianópolis - SC, Brazil, developed the new version (2021) of the national databases. Leonardo Rotondano and Thiago Lôbo developed the cell phone application for data upload.',
    paragrafo6: 'We hope to expand the network once again to include more countries in Latin America and the Caribbean, making more data on invasive alien species available for management and policy, public knowledge and on-site field work.',
    paragrafo7: 'Support',
    paragrafo8: 'Year 2021',
    argentina: 'Argentina',
    brasil: 'Brazil',
    chile: 'Chile',
    costarica: 'Costa Rica',
    ecuador: 'Ecuador',
    honduras: 'Honduras',
    jamaica: 'Jamaica',
    paraguai: 'Paraguay',
    uruguai: 'Uruguay',
    paragrafo9: '2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'InBiAr',
    titulo: 'Biological Invasions in Argentina',
    paragrafo1: 'This database and the corresponding Web interface were developed by the National University of the South (Argentina) with the collaboration of the Hórus Institute for Environmental Development and Conservation (Brazil) and the support of the Geological Service (USGS) and the National Biological Information Infrastructure (NBII), within the framework of the I3N (Invasive Species Information Network) project of IABIN (Inter-American Biodiversity Information Network). Since 2006, the system remains operational, published on the internet and open to free and free consultation, administered and maintained by the Group of Studies in Conservation and Management (GEKKO), of the Department of Biology, Biochemistry and Pharmacy of the National University of the South.',
    paragrafo2: 'Between 2015 and 2021, significant improvements were made within the project “Strengthening governance for the protection of biodiversity through the Formulation and implementation of the National Strategy on Invasive Alien Species (ENEEI)” (GCP / ARG / 023 / GFF) , executed by the Ministry of Environment and Sustainable Development of the Argentine Republic, with partial financing from the Global Environment Fund (GEF) and with the Food and Agriculture Organization of the United Nations (FAO-UN) as the agency of implementation.',
    paragrafo3: 'Unfortunately, most of the national databases have been lost over time. The databases that have been maintained from the beginning are that of Argentina (Universidad Nacional del Sur, Administrator Dr. Sergio Zalba); that of Brazil (Horus Institute, Dra. Silvia Ziller and Dra. Michele de Sá Dechoum), that of Uruguay (University of the Republic in Montevideo, Dr. Ernesto Brugnoli and Committee of Invasive Exotic Species of the Ministry of the Environment, M.Sc Marcelo Ituruburu) and that of Jamaica (Institute of Jamaica, Dra. Suzanne Davis). The cooperation between these databases has never been interrupted by their administrators.'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Data policy',
    titulo: 'Concepts and sources of reference',
    criterio: 'Criteria for adding new species',
    construccion: 'Collaborative construction',
    validacao: 'Data validation',
    paragrafo1: 'The concepts used in this database are aligned with the concepts used in the International Convention on Biological Diversity:',
    marcador1: 'native species: one that is found within the geographic distribution area where it evolved and is part of a biological community that is considered natural or semi-natural,',
    marcador2: 'exotic species: one that is outside its past or present natural range,',
    marcador3: 'invasive alien species: any exotic species that affects or threatens habitats, ecosystems or species causing alterations in natural environments,',
    marcador3_1: 'cryptogenic species: that species whose origin cannot be established with certainty and therefore cannot be classified as native or exotic.',
    paragrafo2: 'The controlled vocabularies related to preferential invasion environments, dispersal routes and vectors, biological forms, impacts and control methods were defined in accordance with the terms used by the Global Database on Invasive Species of the Specialist Group on Invasive Species of the IUCN',
    paragrafo3: 'The main taxonomic references are taken from the systems proposed by the Catalog of Vascular Plants of the Darwinion Institute of Botany ',
    paragrafo3_1: 'by the Missouri Botanical Garden ',
    paragrafo3_2: 'and by the Integrated Taxonomic Information System (ITIS)',
    paragrafo3_3: 'Complementary references include the World Register of Marine Species',
    paragrafo3_4: 'the Global Invasive Species Database (GISD)',
    paragrafo3_5: 'and the CABI Invasive Species Compendium',
    paragrafo6: 'Species are classified in the following categories for every occurrence record:',
    marcador4: '- when its presence is limited or restricted to cultivation or breeding systems or lives depending directly on people, for example as a laboratory animal, companion or aquarium, garden plant, without evidence of escape of individuals in that site.',
    marcador5: '- when the species was seen in natural or semi-natural environments, outside of cultivation or breeding systems, still without evidence of having established a population nucleus.',
    marcador6: '- when the species reproduces effectively, forming a self-sustaining population but remains in a limited area, close to the site of local introduction.',
    marcador7: '– when the species occurs in natural areas, has started to reproduce and spread beyond the point of introduction.',
    paragrafo7: 'A species may therefore be considered invasive in one location, while it is casually present in another, or simply contained in a third place.',
    paragrafo11: 'The database of invasive alien species of the Argentine Republic includes exotic and cryptogenic species detected in natural or semi-natural environments of the country, as well as those that, while remaining contained in cultivation or breeding systems, have a history of being invasive in other countries. Also included are species that, being native to some region of the country, have been translocated to other regions, basins or ecosystems. All species are associated with at least one locality of occurrence in the country. In some cases, that location is the airport or port of entry, when there is no post-import data. The focus of this system is the conservation of biological diversity and, therefore, alien species that behave as weeds, pests or pathogens of people or domestic animals are not included in the database if they have not yet been detected. in nature, they do not affect native flora and fauna species or do not have international antecedents in this regard. Beyond that, it is very common for species that affect the structure, composition and functioning of natural and semi-natural ecosystems to also have impacts on the economy, health and cultural values.',
    paragrafo13: 'The databases of this system are kept up to date thanks to the contributions of information from volunteer collaborators distributed throughout the country. Our collaborators can contribute information on new species, new locations of occurrence of species already detected in the national territory, on projects, specialists or publications on the subject. Each information remains linked to the data of the person who provided it, as a recognition of their contributions to the knowledge of the state of the problem of biological invasions in the country and as a way to promote direct communication between people with knowledge and interest in the matter. To contribute information, please go to',
    paragrafo13_1: 'data upload link',
    paragrafo13_2: 'or contact',
    paragrafo13_3: 'Information is also uploaded from publications and museum and herbarium specimens. Each new location of occurrence is evaluated following validation standards shared with the ',
    paragrafo13_4: 'Latin American Network of Invasive Alien Species Databases',
    paragrafo13_5: 'The data are associated with geographic coordinates that allow mapping the distribution recorded for each species.',
    paragrafo14: 'The database administrators are in charge of completing the information regarding the biological characteristics of each species, the preferential invasion environments, vectors and routes of introduction and dispersal, impacts and control techniques, among others.',
    paragrafo14_1: 'The data is continually reviewed, with each new unit of information received. New species or localities of occurrence that imply significant changes in the distribution of a species or the colonization of new environments are confirmed by consulting specialists from the',
    paragrafo14_2: 'Scientific Comittee',
    paragrafo14_3: 'or to others specially summoned from their particular experience.',
    paragrafo15: 'The validation of data included in the database by data providers / collaborators is conducted by verifying the reliability of the species identification and of places of occurrence.',
    paragrafo15_1: '',
    paragrafo16: 'Taxonomic validation',
    marcador8: 'Can the data provider (author of the publication or collector of the specimen) be considered an expert on the biological group to which the species belongs, or have equivalent experience?',
    marcador9: 'Is the occurrence documented in a photograph through which it is possible to identify of the organism with high certainty?',
    marcador10: 'Is the occurrence documented in an indexed and peer-reviewed publication or in a technical document prepared or reviewed by experts?',
    marcador11: 'Does the information correspond to a specimen deposited in a herbarium or museum?',
    marcador12: 'Does the occurrence correspond to a species that was already registered for the country?',
    marcador13: 'Is the taxonomy to which the group belongs stable and well-known?',
    marcador14: 'Do the characteristics of the species allow for a relatively simple identification?',
    paragrafo17: 'A positive answer to at least one of questions 1, 2, 3 or 4 leads directly to the recommendation to consider the data as validated. In case of answering negatively to these four questions, the validation will be obtained only with positive answers to the last three (5, 6 and 7).',
    paragrafo18: 'Geographic validation',
    marcador15: 'Does the data include geographic coordinates? Coordinates taken directly at the observation site as well as coordinates that correspond to a nearby geographic reference are considered (as indicated).',
    marcador16: 'Is there a description of the site that facilitates its location in the field?',
    marcador17: 'Is the occurrence documented by a photograph that allows for a reliable identification of the site?',
    marcador17_1: 'As coordenadas geográficas coincidem com a localização da área descrita e/ou fotografada?',
    marcador18: 'Do the geographic coordinates provided coincide with the location of the described and / or photographed site?',
    marcador19: 'The species is already cited for the country and, in that case, is the locality included within its known range of distribution or in a site that is compatible with the sites of known presence and with its dispersal capacity?',
    marcador20: 'Are the ecological conditions of the site, inferred from the location indicated, compatible with the general niche requirements of the species?',
    paragrafo19: 'The geographical location data are validated if:',
    marcador21: 'the answer to questions 1, 2 and/or 3, and 4 is positive;  ',
    marcador22: 'a positive or negative answer is assigned to question 1, a negative answer is given to questions 2 and 3, but questions 5 and 6 have positive answers. ',
    paragrafo20: 'The location will be considered not validated if: ',
    marcador23: 'the answer is positive to question 1 and questions 2 and/or 3, but negative to question 4; ',
    marcador24: 'negative or positive answer to question 1 and positive to one or none of questions 5 and 6.',
    paragrafo21: 'Finally, the data are considered validated if the validation analysis of the two criteria (taxonomy and location) is passed.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Scientific Committee',
    paragrafo1: 'This database is supported by a group of experts who ensure the quality, validity and update of information. The committee is formed by experts on different biological groups of animals, plants, and microorganisms, as well as researchers with long-term experience on different biomes in Argentina. The scientific committee provides support for the identification of organisms, to check and validate data on the detection of new species in the country, and to verify and confirm occurrence records in areas or regions where they had not been recorded.',
    paragrafo2: 'The Scientific Committee is formed by:',
    marcador1: 'Sergio M. Zalba, Biologist, MSc. In Wildlife Management, Dr. in Biology. Professor at the Universidad Nacional del Sur, Researcher for CONICET. Manager of the Argentina National Invasive Alien Species Database.',
    marcador2: 'Silvia R. Ziller, Forest Engineer, Dr. in Nature Conservation. The Horus Institute for Environmental Conservation and Development. Manager of the Brazil Invasive Alien Species Database.',
    marcador3: 'Alberto L. Scorolli, Biologist, MSc. and Dr. in Biology. Professor of the Universidad Nacional del Sur. Expert on invasive ungulates.',
    marcador4: 'Carlos Villamil, Agricultural Engineer, PhD. Emeritus Professor of the Universidad Nacional del Sur. Expert in vascular plant taxonomy and the regional flora of Argentina.',
    marcador4b: 'Evangelina Schwindt, Biologist, Dr. in Biological Sciences. Researcher for CONICET. Grupo de Ecología en Ambientes Costeros (GEAC), Centro Nacional Patagónico (CENPAT-CONICET). Expert on marine and coastal alien species.',
    marcador4c: 'Jorge Liotta, Biologist, P. Scasso Museum of Natural Sciences. Expert on continental fishes.',
    marcador4d: 'Sebastián Di Martino, Zoologist, Director of Conservation. Rewilding Argentina Foundation.',
    marcador4e: 'Dra. M. Laura Guichón, Neuquén Terrestrial Ecology Group, Biodiversity and Environment Research Institute (INIBIOMA, CONICET-UNCo), Subsede Junín de los Andes. Center for Applied Ecology of Neuquén (CEAN). Invasive Mammals Specialist;',
    marcador4f: 'Lic. Francisco Firpo Lacoste, Dirección Nacional Dirección Nacional de Gestión Ambiental del Agua y los Ecosistemas Acuáticos, Ministerio de Ambiente y Desarrollo Sostenible de Nación. Exper on fihes;',
    marcador4g: 'Dr. Cristina Sanhueza and Yannina Cuevas, Universidad Nacional del Sur. Responsible for data management.',
    paragrafo3: 'The role of the Scientific Committee:',
    marcador5: '1- make decisions on the inclusion of new species in the database;',
    marcador6: '2- make decisions on the exclusion of species for lack of data consistency or evidence of invasion;',
    marcador7: '3- support data validation;',
    marcador8: '4- support on data reviews and for data searches;',
    marcador9: '5- support to locate and consult with experts for data validation and to improve database content;',
    marcador10: '6- make recommendations for improvement of the database;',
    marcador11: '7- continuously increase and improve database content.',
    paragrafo4: 'Dra. M. Laura Guichón, Neuquén Terrestrial Ecology Group, Biodiversity and Environment Research Institute (INIBIOMA, CONICET-UNCo), Subsede Junín de los Andes. Center for Applied Ecology of Neuquén (CEAN). Invasive Mammals Specialist;',
    paragrafo5: 'Dr. Cristina Sanhueza and Yannina Cuevas, Universidad Nacional del Sur. Responsible for data management.'
  },
  fornecerDados: {
    cabecalhoTitulo: 'Contribute data!',
    paragrafo1: 'Our database is kept up to date with support from experts and nature lovers throughout the national territory. Before being published, all information provided is validated according to criteria adopted at the regional level. You can provide data on species not yet listed in our database or on new places of occurrence by downloading our app (Invasoras AR) or sending an e-mail to',
    paragrafo1_1: 'inbiar@uns.edu.ar',
    paragrafo2: 'You can also send information on new publications or projects on biological invasions in Argentina. Data providers that contribute regularly may receive a username and password to upload information directly to the database. Help us face the threat of invasive species by contributing data to complete and update the database.',
    paragrafo3: 'Thank you!'
  },
  especiesInstitucional: {
    titulo: 'Species search',
    paragrafo1: 'Look here for detailed information about the invasive and potentially invasive alien species present in the Argentine Republic, their characteristics and effects, and the control options.',
    paragrafo2_1: 'You can contribute information on new locations of occurrence or on species not yet included in this database, by clicking ',
    paragrafo2_2: 'here',
    verTutorial: 'Open tutorial ',
    paragrafo3: 'Please use the following format to cite information consulted in this system:',
    paragrafo4_1: 'InBiAr. Database on Biological Invasions in Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Accessed MM/DD/AAAA.',
    paragrafo4_2: '',
    tutorialTitulo1: 'How to cite this database',
    tutorialDescricao1: 'We invite you to use the information available in this database system by citing it so that more people can learn about it and take advantage of the information it contains:',
    tutorialTitulo2: 'Use the filters',
    tutorialDescricao2: 'You’ll find some options to filter contents here.',
    tutorialTitulo3: 'Use the filters: select data',
    tutorialDescricao3: 'Choose filters and combine data by place, biological group, use, taxonomy, etc.',
    tutorialTitulo4: 'Number of records',
    tutorialDescricao4: 'This is the number of invasive alien species in the database or resulting from your search using combined filters.',
    tutorialDescricao5: 'Here you can select the number of records you wish to view per page.',
    tutorialTitulo6: 'List of invasive alien species',
    tutorialDescricao6: 'List of all species or search results using filters. Click on the name of the species to see the specific data set.',
    tutorialTitulo6_1: 'Export data',
    tutorialDescricao6_1: 'Here you can filter data and choose the format to export.',
    tutorialDescricao7: 'Taxonomic classification of the species selected, common names, synonyms, and a picture when possible!',
    tutorialTitulo8: 'Ecology and use',
    tutorialDescricao8: 'Description, biology and ecology of the species selected, native range, type of habitats it invades, and economic uses.',
    tutorialTitulo9: 'Introduction and spread',
    tutorialDescricao9: 'How the species arrived, why it was introduced, and how it spreads to other areas.',
    tutorialTitulo10: 'Impacts',
    tutorialDescricao10: 'Environmental, economic, health, and cultural impacts. Classification of environmental impacts according to the IUCN EICAT protocol.',
    tutorialTitulo11: 'Management',
    tutorialDescricao11: 'Prevention and early detection measures, mechanical, chemical, biological control.',
    tutorialTitulo12: 'Risk analysis',
    tutorialDescricao12: 'Risk analysis results for the species selected.',
    tutorialTitulo13: 'Places of occurrence',
    tutorialDescricao13: 'Location, ecosystems, protected areas, states in the country where the species occurs, and stage of invasion – see the map!',
    tutorialDescricao14: 'List of references used to compile data on the species and other publications on the species.',
    tutorialTitulo15: 'Projects',
    tutorialDescricao15: 'List of research and management projects on the species selected.'
  },
  colaboradoresInstitucional: {
    titulo: 'Collaborators',
    paragrafo1: 'Contacts include those who provide data for this information system, as well as those who collected museum specimens or herbarium specimens, or are authors of publications that were used as data sources.',
    paragrafo2: '',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'InBiAr. Database on Biological Invasions in Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Accessed MM/DD/AAAA.',
    paragrafo4_2: ''
  },
  especiesMarinhasInstitucional: {
    titulo: 'Marine species',
    paragrafo1: 'In most cases they are accidentally transported, often with ballast water or attached to the hull of ships. Their presence tends to go unnoticed during the first invasion phases and when they are detected the chances of controlling them are very limited by the restrictions imposed by the environment in which they are established. This is why the greatest efforts are made to prevent new introductions. The problem is a global priority and has motivated the development of the',
    paragrafo1_1: 'Global Ballast Water Management Programme',
    paragrafo1_2: ', of which the Argentine Republic is a signatory.',
    paragrafo2: 'Other species, such as the Pacific oyster, are voluntarily introduced for production. More information about the problem of invasive species of the Argentine coast can be obtained on the page of the',
    paragrafo2_1: 'Grupo de Ecología en Ambientes Costeros',
    paragrafo2_2: ', CENPAT-CONICET.',
    paragrafo4: 'Amphibalanus amphitrite - striped barnacle',
    paragrafo4b: 'Foto Nicolas Battini',
    paragrafo5: 'Botryllus schlosseri - star tunicate',
    paragrafo5b: 'Foto Nicolas Battini',
    paragrafo6: 'Carcinus maenas - green shore crab',
    paragrafo6b: 'Foto Alejandro Bortolus',
    paragrafo7: 'Crassostrea gigas - Pacific oyster',
    paragrafo7b: 'Foto Silvana Burela',
    paragrafo8: 'Ficopomatus enigmaticus – Australian tubeworm',
    paragrafo8b: 'Foto Alejandro Bortolus'
  },
  especiesAguaDulceInstitucional: {
    titulo: 'Freshwater species',
    paragrafo1: 'As in the case of marine and coastal species, it is common for freshwater species to be introduced with ballast water, in association with ships that come from ports located at the mouths of large rivers. In this way algae, mollusks, crustaceans and microorganisms manage to cross the barrier that the seas and oceans historically imposed on them. Once introduced, it is common for them to disperse associated with river transport, boating or fishing.',
    paragrafo3: 'Inland water fish, on the other hand, are usually introduced voluntarily, especially for sport fishing, as is the case with trout and other species of salmonids that were, and still are, “stocked” in rivers, streams and lakes. from almost the entire country, where they cause serious impacts as predators of native species.',
    paragrafo5: 'Acipenser gueldenstaedtii - esturión ruso',
    paragrafo5b: 'Foto Danilo Demonte',
    paragrafo6: 'Corbicula fluminea - Asian clam',
    paragrafo6b: 'Foto Silvana Burela',
    paragrafo7: 'Limnoperna fortunei - golden mussel',
    paragrafo7b: 'Foto Silvana Burela',
    paragrafo8: 'Litobathes catesbeianus - bullfrog',
    paragrafo8b: 'Foto Walter Prado',
    paragrafo9: 'Oncorhynchus tshawytscha - chinook salmon',
    paragrafo9b: 'Foto Jorge Liotta',
    paragrafo10: 'Sinotaia quadrata - giant African snail',
    paragrafo10b: 'Foto Silvana Burela'
  },
  insectosInstitucional: {
    titulo: 'Insects and other terrestrial invertebrates',
    paragrafo1: 'Typically transported accidentally, as live bait, seed contaminants or accompanying different types of loads. Some species are introduced as crop pollinators, for example for plants grown in greenhouses or for pastures, others are imported as biological control agents and, more and more frequently, as companion animals. One of the most widely distributed insects in the world in association with human activities is the honeybee, a species that is, at the same time, a support for economic activities but also a cause of impacts on biodiversity.',
    paragrafo2: 'Some invertebrates are associated with impacts on agricultural production and others with disease transmission, such as the yellow fever mosquito and the giant African snail.',
    paragrafo4: 'Aedes aegypti - tiger mosquito',
    paragrafo4b: 'Foto: ENEEI Argentina',
    paragrafo5: 'Harmonia axyridis - harlequin ladybeetle',
    paragrafo5b: 'Foto: Cristina Sanhueza',
    paragrafo6: 'Rumina decollata - decollate snail',
    paragrafo6b: 'Foto: Silvana Burela',
    paragrafo7: 'Bombus terrestris - buff-tailed bumblebee',
    paragrafo7b: 'Foto: Carolina Morales'
  },
  animalesonamentalescompaniaacuarioInstitucional: {
    titulo: 'Ornamental, pets and aquarium animals ',
    paragrafo1: 'The international trade in companion and aquarium animals is one of the most frequent routes of voluntary introduction of vertebrates and invertebrates worldwide. The variety of species that are traded internationally grows with the processes of globalization and trade via the Internet intensifies the informal, and often illegal, exchange of species. Many of these species end up being released into natural or semi-natural environments where, in some cases, they can establish wild populations, such as the red-eared turtle, or even if they fail to reproduce, affect native fauna as predators or disease transmitters.',
    paragrafo2: 'The aquarium activity also involves the introduction of other potentially invasive organisms such as algae and "living rocks".',
    paragrafo4: 'Callosciurus erythraeus - red-bellied tree squirrel',
    paragrafo4b: 'Foto: ENEEI Argentina',
    paragrafo5: 'Callosciurus erythraeus - red-bellied tree squirrel',
    paragrafo5b: 'Foto: Fernando Milesi',
    paragrafo6: 'Felis catus - domestic cat',
    paragrafo6b: 'Foto: Instituto Hórus',
    paragrafo7: 'Sturnus vulgaris - European starling',
    paragrafo7b: 'Foto: ENEEI Argentina',
    paragrafo8: 'Trachemys scripta - red eared slider',
    paragrafo8b: 'Foto: Marcos Tortato'
  },
  plantasOrnamentalesInstitucional: {
    titulo: 'Ornamental plants',
    paragrafo1: 'Gardening and landscaping are among the main activities responsible for the introduction of invasive species around the world. More than a third of the invasive plants in the Argentine Republic register uses as park and garden plants. Trade control is complex as the size of the seeds allows many individuals of many species to be transported in small containers.',
    paragrafo2: 'Added to this is the widespread perception that there is nothing wrong with the cultivation of plants of ornamental value and that, in the worst case, their escape to nature does nothing more than "beautify" the landscapes. Far from that, some of the invaders of May impact on our country, such as privet, broom, honeysuckle, or yellow lily, once escaped from the gardens.',
    paragrafo3: 'Ligustrum lucidum - privet',
    paragrafo3b: 'Foto: Sergio Zalba',
    paragrafo4: 'Prunus mahaleb - wild cherry',
    paragrafo4b: 'Foto: Martín Amodeo',
    paragrafo5: 'Prunus mahleb - wild cherry',
    paragrafo5b: 'Foto: Gabriela Brancatelli',
    paragrafo6: 'Rosa sp. - rosehip',
    paragrafo6b: 'Foto: Sergio Zalba'
  },
  especiesForestalesInstitucional: {
    titulo: 'Trees',
    paragrafo1: 'Trees are introduced for a variety of purposes, from forestry to creating windbreaks, fuelwood and forage production, dune stabilization, ornamental use, botanical interest, and carbon dioxide fixation. The success of invasive trees is usually particularly notable in savanna and steppe regions, as is the case of the Pampean Region and the Patagonian Steppe in the Argentine Republic, there they occupy an ecological niche that was unoccupied until their arrival and they alter not only the composition of plant communities but the structure of the ecosystem itself.',
    paragrafo2: 'Beyond that, invasive forest species, including privet, paradise, black acacia, and different species of pines threaten the conservation of biodiversity in different biomes of our country.',
    paragrafo4: 'Acacia mearnsii - black wattle',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Pinus halepensis - pine',
    paragrafo5b: 'Foto: Gabriela Brancatelli',
    paragrafo6: 'Tamarix ramosíssima - tamarisk',
    paragrafo6b: 'Foto: Evangelina Natale'
  },
  ingenierosInstitucional: {
    titulo: 'Ecosystem Engineers and key-stone species ',
    paragrafo1: 'While the best known effects of invasive species on biodiversity refer to the impact of an introduced species on one or a few native species, there are invaders that produce changes that affect the functioning of entire ecological systems. These species play key ecological roles and include, for example, opportunistic predators that can decimate entire prey communities, hoofed herbivores that affect the structure and composition of grassland, shrubland and forest vegetation, grasses that alter the frequency and intensity of attacks. fires, nitrogen fixing plants that modify the dynamics of this nutrient in soils and trees capable of reaching underground water deposits and causing the desiccation of small water bodies.',
    paragrafo4: 'Equus caballus - horse',
    paragrafo4b: 'Foto: Alberto Scorolli',
    paragrafo5: 'Impact by beavers (Castor canadensis)',
    paragrafo5b: 'Foto: Erio Curto',
    paragrafo6: 'Bubalus bubalis - water buffalo',
    paragrafo6b: 'Foto: Pablo Petracci',
    paragrafo7: 'Capra hircus - goat',
    paragrafo7b: 'Foto: Pablo Petracci',
    paragrafo8: 'Sus scrofa - wild boar',
    paragrafo8b: 'Foto: Pablo Petracci'
  },
  projetosInstitucional: {
    titulo: 'Projects',
    paragrafo1: 'This section includes information about current or previous projects focused on the study and management of invasive and potentially invasive alien species present in the Argentine Republic. If you participate or participated in a project related to these species, please help to incorporate it by sending the corresponding information to inbiar@uns.edu.ar.',
    paragrafo2_1: '',
    paragrafo2_2: '',
    paragrafo2_3: '',
    paragrafo3: 'Please use the following format to cite data from this system:',
    paragrafo4_1: 'InBiAr. Database on Biological Invasions in Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Accessed MM/DD/AAAA.',
    paragrafo4_2: ''
  },
  referenciasInstitucional: {
    titulo: 'References',
    paragrafo1: 'The publications and technical reports cited here were used to add information about the species present in the national territory, their behavior and effects. Some include data on the management options for different species. If you are an author or know of publications on the subject that are not yet included in this section, please help to incorporate them by sending the corresponding citations to inbiar@uns.edu.ar.',
    paragrafo2: '',
    paragrafo3: '',
    paragrafo4: 'Please use the following format to cite data from this system:',
    paragrafo5_1: 'InBiAr. Database on Biological Invasions in Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Accessed MM/DD/AAAA.'
  },
  usoFlorestalInstitucional: {
    titulo: 'Forestry',
    paragrafo1: 'Several non-native species were introduced in Brazil after 1950 by government initiatives to develop a forestry industy for pulp, paper, wood and other subproducts. Some of these species became invasive, especially pines which have been distributed to many ecosystems. These species require continuous control to prevent them from escaping plantations and causing environmental damage. Forest certification requires companies to control invasions, and such trees have to be eliminated from natural areas, roadsides and railways, riparian forests, and other habitat types where they are not cultivated.',
    paragrafo2: 'The genus Pinus is considered the most invasive genus of tree in the southern hemisphere! Pines displace native vegetation in open ecosystems and use up more water than native plants.',
    paragrafo3: 'Learn more',
    paragrafo4: 'Pinus sp. - pine',
    paragrafo4b: 'Photo by: The Horus Institute',
    paragrafo5: 'Acacia mearnsii - black wattle',
    paragrafo5b: 'Photo by: The Horus Institute',
    paragrafo6: 'Acacia mangium - acacia',
    paragrafo6b: 'Photo by: The Horus Institute'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contact',
    contato: 'Contact',
    p1: 'Fill all the fields in the form below:',
    nome: 'Name',
    email: 'E-mail',
    instituicao: 'Institution',
    mensagem: 'Message',
    enviar: 'Send'
  }
}

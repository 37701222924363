<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('fornecerDados.cabecalhoTitulo') }`" :classProp="'header-single header-colabore'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5 mb-5">

          <p>{{ $t('fornecerDados.paragrafo1') }} <a href="mailto:inbiar@uns.edu.ar" target="_blank">{{ $t('fornecerDados.paragrafo1_1') }}</a>.</p>

          <p>{{ $t('fornecerDados.paragrafo2') }}</p>
          <p>{{ $t('fornecerDados.paragrafo3') }}</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'FornecerDados',
  components: { InstitucionalHeaderSingle }
}
</script>

<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('politicaDeDados.cabecalhoTitulo') }`" :classProp="'header-single header-politica'"/>
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5 politica-de-dados">

          <ul class="menu-politica " id="menu-politica">
            <li class="list-group-item"><a href="#conceitos" >{{ $t('politicaDeDados.titulo') }}</a></li>
            <li class="list-group-item"><a href="#criterios" >{{ $t('politicaDeDados.criterio') }}</a></li>
            <li class="list-group-item"><a href="#construccion" >{{ $t('politicaDeDados.construccion') }}</a></li>
            <li class="list-group-item"><a href="#validacao" >{{ $t('politicaDeDados.validacao') }}</a></li>
          </ul>

          <h2 class="laranja mb-5 pt-5" id="conceitos">{{ $t('politicaDeDados.titulo') }}</h2>

          <p>{{ $t('politicaDeDados.paragrafo1') }}</p>

          <ol>
            <li>{{ $t('politicaDeDados.marcador1') }} </li>
            <li>{{ $t('politicaDeDados.marcador2') }}</li>
            <li>{{ $t('politicaDeDados.marcador3') }}</li>
            <li>{{ $t('politicaDeDados.marcador3_1') }}</li>
          </ol>

          <p>{{ $t('politicaDeDados.paragrafo2') }} (<a href="https://www.issg.org" target="_blank">www.issg.org</a>).</p>
          <p>{{ $t('politicaDeDados.paragrafo3') }} (<a href="http://www.darwin.edu.ar/proyectos/floraargentina/fa.htm" target="_blank">http://www.darwin.edu.ar/proyectos/floraargentina/fa.htm</a>), {{ $t('politicaDeDados.paragrafo3_1') }} (<a href="www.tropicos.org" target="_blank">www.tropicos.org</a>), {{ $t('politicaDeDados.paragrafo3_2') }} (ITIS, <a href="https://www.itis.gov" target="_blank">www.itis.gov</a>). {{ $t('politicaDeDados.paragrafo3_3') }} (<a href="http://www.marinespecies.org/" target="_blank">www.marinespecies.org</a>), Fishbase (<a href="http://www.fishbase.org/" target="_blank">www.fishbase.org</a>), AmphibiaWeb (<a href="https://amphibiaweb.org/" target="_blank">https://amphibiaweb.org/</a>), Algaebase (<a href="www.algaebase.org" target="_blank">www.algaebase.org</a>), {{ $t('politicaDeDados.paragrafo3_4') }} (<a href="www.issg.org/database" target="_blank">www.issg.org/database</a>) {{ $t('politicaDeDados.paragrafo3_5') }} (<a href="https://www.cabi.org/isc" target="_blank">www.cabi.org/isc</a>).</p>

          <p>{{ $t('politicaDeDados.paragrafo6') }}</p>
          <ol>
            <li><strong>{{ $t('label.contida') }}</strong> {{ $t('politicaDeDados.marcador4') }}</li>
            <li><strong>{{ $t('label.casual') }}</strong> {{ $t('politicaDeDados.marcador5') }}</li>
            <li><strong>{{ $t('label.estabelecida') }}</strong> {{ $t('politicaDeDados.marcador6') }}</li>
            <li><strong>{{ $t('label.invasora') }}</strong> {{ $t('politicaDeDados.marcador7') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo7') }}</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
          <h2 class="laranja mb-5 mt-5" id="criterios">{{ $t('politicaDeDados.criterio') }}</h2>
          <p>{{ $t('politicaDeDados.paragrafo11') }}</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
          <h2 class="laranja mb-5 mt-5" id="construccion">{{ $t('politicaDeDados.construccion') }}</h2>
          <p>{{ $t('politicaDeDados.paragrafo13') }} <a href="/login"> {{ $t('politicaDeDados.paragrafo13_1') }}</a> {{ $t('politicaDeDados.paragrafo13_2') }} <a href="mailto:inbiar@uns.edu.ar">inbiar@uns.edu.ar</a>. {{ $t('politicaDeDados.paragrafo13_3') }} <a href="http://portaleei.udelar.edu.uy/" target="_blank">{{ $t('politicaDeDados.paragrafo13_4') }}</a>. {{ $t('politicaDeDados.paragrafo13_5') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo14') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo14_1') }}<a href="/comite-cientifico" target="_blank">{{ $t('politicaDeDados.paragrafo14_2') }}</a> {{ $t('politicaDeDados.paragrafo14_3') }}</p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
          <h2 class="laranja mb-5 mt-5" id="validacao">{{ $t('politicaDeDados.validacao') }}</h2>
          <p>{{ $t('politicaDeDados.paragrafo15') }}<a href="/rede-base-de-dados" > {{ $t('politicaDeDados.paragrafo15_1') }}</a>.</p>
          <p class="mt-2"><strong>{{ $t('politicaDeDados.paragrafo16') }}</strong></p>
          <ol class="mt-2">
            <li>{{ $t('politicaDeDados.marcador8') }}</li>
            <li>{{ $t('politicaDeDados.marcador9') }}</li>
            <li>{{ $t('politicaDeDados.marcador10') }}</li>
            <li>{{ $t('politicaDeDados.marcador11') }}</li>
            <li>{{ $t('politicaDeDados.marcador12') }}</li>
            <li>{{ $t('politicaDeDados.marcador13') }}</li>
            <li>{{ $t('politicaDeDados.marcador14') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo17') }}</p>
          <p class="mt-2"><strong>{{ $t('politicaDeDados.paragrafo18') }}</strong></p>
          <ol class="mt-2 mb-3">
            <li>{{ $t('politicaDeDados.marcador15') }}</li>
            <li>{{ $t('politicaDeDados.marcador16') }}</li>
            <li>{{ $t('politicaDeDados.marcador17') }}</li>
            <li>{{ $t('politicaDeDados.marcador17_1') }}</li>
            <li>{{ $t('politicaDeDados.marcador18') }}</li>
            <li>{{ $t('politicaDeDados.marcador19') }}</li>
            <li>{{ $t('politicaDeDados.marcador20') }}</li>
          </ol>
          <p class="mt-2">{{ $t('politicaDeDados.paragrafo19') }}</p>
          <ol class="mt-2 mb-3">
            <li>{{ $t('politicaDeDados.marcador21') }}</li>
            <li>{{ $t('politicaDeDados.marcador22') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo20') }}</p>
          <ol class="mt-2 mb-3">
            <li>{{ $t('politicaDeDados.marcador23') }}</li>
            <li>{{ $t('politicaDeDados.marcador24') }}</li>
          </ol>
          <p>{{ $t('politicaDeDados.paragrafo21') }}</p>
          <p>{{ $t('politicaDeDados.paragrafo22') }} <b>{{ $t('politicaDeDados.paragrafo22_1') }}</b> <a href="mailto:inbiar@uns.edu.ar">{{ $t('politicaDeDados.paragrafo22_2') }}</a> <b>{{ $t('politicaDeDados.paragrafo22_3') }}</b></p>
          <p class="my-5 "><a href="#topo" class="back-topo-politica"><img src="../../../assets/institucional/img/icon/seta-up.png"> {{ $t('label.voltar') }}</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'PoliticaDeDados',
  components: { InstitucionalHeaderSingle }
}
</script>

<template>
  <div>
    <div class="header-single header-insecto">
      <div class="container  h-100">
        <div class="row align-items-end  h-100">
          <div class="col-sm ">
            <h1 class="align-bottom">{{ $t('insectosInstitucional.titulo') }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <div class="row mb-2">
        <div class="col-md-4 p-3 ">

          <p class="mt-5">{{ $t('insectosInstitucional.paragrafo1') }}</p>

        </div>
        <div class="col-md-4 p-3">
          <p class="mt-5">{{ $t('insectosInstitucional.paragrafo2') }}</p>
        </div>
        <div class="col-md-4 p-3">
          <!-- galeria -->
            <div id="carousel-especies" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img src="../../../assets/institucional/img/galeria/galeria-insectos-1.jpg" class="d-block w-100" alt="...">
                  <div class="carousel-caption d-none d-md-block">
                    <p class="text-start">{{ $t('insectosInstitucional.paragrafo4') }}<br>{{ $t('insectosInstitucional.paragrafo4b') }}</p>
                  </div>
                </div>
                <div class="carousel-item ">
                  <img src="../../../assets/institucional/img/galeria/galeria-insectos-2.jpg" class="d-block w-100" alt="...">
                  <div class="carousel-caption d-none d-md-block">
                    <p class="text-start">{{ $t('insectosInstitucional.paragrafo5') }}<br>{{ $t('insectosInstitucional.paragrafo5b') }}</p>
                  </div>
                </div>
                <div class="carousel-item ">
                  <img src="../../../assets/institucional/img/galeria/galeria-insectos-3.jpg" class="d-block w-100" alt="...">
                  <div class="carousel-caption d-none d-md-block">
                    <p class="text-start">{{ $t('insectosInstitucional.paragrafo6') }}<br>{{ $t('insectosInstitucional.paragrafo6b') }}</p>
                  </div>
                </div>
                <div class="carousel-item ">
                  <img src="../../../assets/institucional/img/galeria/galeria-insectos-4.jpg" class="d-block w-100" alt="...">
                  <div class="carousel-caption d-none d-md-block">
                    <p class="text-start">
                      {{ $t('insectosInstitucional.paragrafo7') }}<br>{{ $t('insectosInstitucional.paragrafo7b') }}
                    </p>
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carousel-especies" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">{{ $t('label.anterior') }}</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carousel-especies" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">{{ $t('label.próximo') }}</span>
              </button>
            </div><!--/ galeria -->
        </div>
      </div>
      <EspeciesForm v-if="viewEspecie" :speciesId="species.species_id" :viewEspecie="true" :byInstitucional="true"/>
      <Especies v-else :withFilter="false" :withPageTitle="false" :withOrderBy="true" :byInstitucional="true" @actionByInstitucional="actionByInstitucional" :dataFilter="{ biological_form_id_array: '3, 4, 47' }"/>
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto ml-auto text-right mt-n1">
          <button v-if="viewEspecie" @click="viewEspecie=false" class="btn bt-filtrar mt-1 mb-2">
            {{ $t('label.exibirListagem') }}
          </button>
        </div>
      </div>
      <InstitucionalSliderTemas />
    </div>
  </div>
</template>

<script>
import Especies from '../../admin/especies/Especies.vue'
import EspeciesForm from '../../admin/especies/EspeciesForm.vue'
import InstitucionalSliderTemas from '@/components/institucional/InstitucionalSliderTemas'
export default {
  name: 'insectosInstitucional',
  components: { Especies, EspeciesForm, InstitucionalSliderTemas },
  data () {
    return {
      driver: null,
      viewEspecie: false,
      species: {}
    }
  },
  methods: {
    actionByInstitucional: function (item) {
      this.species = item
      this.viewEspecie = true
    }
  },
  created () {
    this.viewEspecie = false
  }
}
</script>

<template>
  <div>
    <InstitucionalHeaderSingle :title="`${$t('baseDadosNacional.cabecalhoTitulo') }`" :classProp="'header-single header-rede-nacional'" />
    <div class="container">
      <div class="row">
        <div class="col-sm col-lg-10 py-5">
          <h2 class="laranja mt-2 h3">{{ $t('baseDadosNacional.titulo') }}</h2>
          <p>{{ $t('baseDadosNacional.paragrafo1') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo2') }}</p>
          <p>{{ $t('baseDadosNacional.paragrafo3') }}<a href="http://portaleei.udelar.edu.uy" target="_blank">{{ $t('baseDadosNacional.paragrafo3_1') }}</a>{{ $t('baseDadosNacional.paragrafo3_2') }}</p>
        </div>
      </div>
    </div>
    <div class="container logo-parceiros logos-apoio-argentina pb-5">
      <div class="row">
        <div class="col-sm">
          <div class="row mt-5">
            <div class="col-sm col-md-12">
              <ul>
                <li><img src="../../../assets/institucional/img/logo/apoio-gekko.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-dbbyf.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-uns.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-mayds.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-conicet.png" ></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-0 mb-5">
        <div class="col-sm">
          <div class="row mt-5">
            <div class="col-sm col-md-12">
              <ul>
                <li><img src="../../../assets/institucional/img/logo/apoio-eneei.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-iabin.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-i3n.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-fao.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-gef.png" ></li>
                <li><img src="../../../assets/institucional/img/logo/apoio-griis.png" ></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InstitucionalHeaderSingle from '@/components/institucional/InstitucionalHeaderSingle'
export default {
  name: 'BaseDadosNacional',
  components: { InstitucionalHeaderSingle }
}
</script>

<template>
  <div class="row" id="tut-box-filtros">
    <div class="col-12 d-flex">
      <div class="card flex-fill">
        <div class="card-body p-0 box-dashboard">
          <div class="align-self-center">
            <form class="filtro_especie" @submit.prevent="search" v-if="withFilter">
              <div class="row g-3 p-3 pb-0" v-if="!byInstitucional">
                <div class="col-12 col-md-9 col-lg-9">
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" :value="1" v-model="$store.state.formSearchEspecies.species_type">
                      <label class="form-check-label" for="inlineRadio1">{{ $t('label.usoRestringido') }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" :value="2" v-model="$store.state.formSearchEspecies.species_type">
                      <label class="form-check-label" for="inlineRadio2">{{ $t('label.usoControlado') }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" :value="3" v-model="$store.state.formSearchEspecies.species_type">
                      <label class="form-check-label" for="inlineRadio3">{{ $t('label.noAsignada') }}</label>
                  </div>
                  <div class="form-check form-check-inline">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio4" :value="4" v-model="$store.state.formSearchEspecies.species_type">
                      <label class="form-check-label" for="inlineRadio4">{{ $t('label.noEvaluada') }}</label>
                  </div>
                </div>
                <div class="col-12 col-md-3 col-lg-3 text-right">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" id="pendenteAnalise" v-model="$store.state.formSearchEspecies.pending_analysis">
                    <label class="form-check-label" for="pendenteAnalise">{{ $t('label.pendenteAnalise') }}</label>
                  </div>
                </div>
              </div>
              <div class="row g-3 p-3 pb-0">
                <div class="col-12" id="tut-filtro-select" :class="$store.state.formSearchEspecies.theme === 11 ? 'col-lg-4' : 'col-lg-3'" >
                  <v-select :appendToBody="true" :options="listThemes" v-model="$store.state.formSearchEspecies.theme" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- NOME CIENTIFICO -->
                <div class="col-12 col-lg-7" v-if="$store.state.formSearchEspecies.theme === 1">
                  <v-select multiple :appendToBody="true" :filterable="false" @search="getSpecies" :options="speciesList" v-model="$store.state.formSearchEspecies.specieSelected" label="scientific_name" index="species_id"
                    :reduce="r => r.species_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.scientific_name}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.scientific_name}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- NOME CIENTIFICO -->
                <div class="col-12 col-lg-6" v-if="$store.state.formSearchEspecies.theme === 11">
                  <input type="text" class="form-control bg-laranja" v-model="$store.state.formSearchEspecies.taxonomySynonymousDesc"/>
                </div>
                <div class="col-12 col-lg-7" v-if="$store.state.formSearchEspecies.theme === 10">
                  <input type="text" class="form-control bg-laranja" v-model="$store.state.formSearchEspecies.commonNameDesc"/>
                </div>

                <!-- TAXONOMIA -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 2">
                  <v-select :appendToBody="true" :options="listTaxonomy" v-model="$store.state.formSearchEspecies.fieldTaxonomy" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 2 && $store.state.formSearchEspecies.fieldTaxonomy === 1">
                  <v-select :appendToBody="true" :options="taxonomyKingdomList" v-model="$store.state.formSearchEspecies.kingdom_id" label="kingdom" index="kingdom_id"
                    :reduce="r => r.kingdom_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.kingdom}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.kingdom}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 2 && $store.state.formSearchEspecies.fieldTaxonomy === 2">
                  <v-select :appendToBody="true" :options="taxonomyPhyllumList" v-model="$store.state.formSearchEspecies.phyllum_id" label="phyllum" index="phyllum_id"
                    :reduce="r => r.phyllum_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.phyllum}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.phyllum}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 2 && $store.state.formSearchEspecies.fieldTaxonomy === 3">
                  <v-select :appendToBody="true" :options="taxonomyClassList" v-model="$store.state.formSearchEspecies.class_id" label="class" index="class_id"
                    :reduce="r => r.class_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.class}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.class}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 2 && $store.state.formSearchEspecies.fieldTaxonomy === 4">
                  <v-select :appendToBody="true" :options="taxonomyOrderList" v-model="$store.state.formSearchEspecies.order_id" label="oorder" index="order_id"
                    :reduce="r => r.order_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.oorder}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.oorder}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 2 && $store.state.formSearchEspecies.fieldTaxonomy === 5">
                  <v-select :appendToBody="true" :options="taxonomyFamilyList" v-model="$store.state.formSearchEspecies.family_id" label="family" index="family_id"
                    :reduce="r => r.family_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.family}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.family}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 2 && $store.state.formSearchEspecies.fieldTaxonomy === 6">
                  <v-select :appendToBody="true" :options="taxonomyGenusList" v-model="$store.state.formSearchEspecies.genus_id" label="genus" index="genus_id"
                    :reduce="r => r.genus_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.genus}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.genus}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- TAXONOMIA -->

                <!-- ORIGEM -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 3">
                  <v-select :appendToBody="true" :options="listOrigin" v-model="$store.state.formSearchEspecies.origin" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- ORIGEM -->

                <!-- Biologia e ecologia -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 4">
                  <v-select :appendToBody="true" :options="listBiologiaEcologia" v-model="$store.state.formSearchEspecies.fieldBiologiaEcologia" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 4 && $store.state.formSearchEspecies.fieldBiologiaEcologia === 1">
                  <v-select :appendToBody="true" :options="vocReproductionList" v-model="$store.state.formSearchEspecies.reproduction_id" label="value" index="reproduction_id"
                    :reduce="r => r.reproduction_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 4 && $store.state.formSearchEspecies.fieldBiologiaEcologia === 2">
                  <v-select :appendToBody="true" :options="vocSpreadList" v-model="$store.state.formSearchEspecies.spread_id" label="value" index="spread_id"
                    :reduce="r => r.spread_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 4 && $store.state.formSearchEspecies.fieldBiologiaEcologia === 3">
                  <v-select :appendToBody="true" :options="vocDietList" v-model="$store.state.formSearchEspecies.diet_id" label="value" index="diet_id"
                    :reduce="r => r.diet_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 4 && $store.state.formSearchEspecies.fieldBiologiaEcologia === 4">
                  <v-select :appendToBody="true" :options="vocFormList" v-model="$store.state.formSearchEspecies.form_id" label="value" index="form_id"
                    :reduce="r => r.form_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Biologia e ecologia -->

                <!-- Uso econômico -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 5">
                  <v-select :appendToBody="true" :options="vocEconomicalUseList" v-model="$store.state.formSearchEspecies.economic_use_id" label="value" index="economical_use_id"
                    :reduce="r => r.economical_use_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Uso econômico -->

                <!-- Invasão biológica -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 6">
                  <v-select :appendToBody="true" :options="listInvasaoBiologia" v-model="$store.state.formSearchEspecies.fieldInvasaoBiologia" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 1">
                  <v-select :appendToBody="true" :options="vocprefenvironmentsList" v-model="$store.state.formSearchEspecies.pref_inv_env_id" label="value" index="pref_inv_env_id"
                    :reduce="r => r.pref_inv_env_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 2">
                  <v-select :appendToBody="true" :options="vocIntroductionTypeList" v-model="$store.state.formSearchEspecies.introduction_type_id" label="value" index="introduction_type_id"
                    :reduce="r => r.introduction_type_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 3">
                  <v-select :appendToBody="true" :options="vocCauseIntroductionList" v-model="$store.state.formSearchEspecies.cause_introduction_id" label="value" index="cause_introduction_id"
                    :reduce="r => r.cause_introduction_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 4">
                  <input type="text" class="form-control bg-laranja" v-model="$store.state.formSearchEspecies.location"/>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 5">
                  <input type="text" class="form-control bg-laranja" v-mask="'####'" v-model="$store.state.formSearchEspecies.intro_data"/>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 6">
                  <v-select :appendToBody="true" :options="vocPathwaysCdbList" v-model="$store.state.formSearchEspecies.pathways_cdb_id" label="value" index="pathways_cdb_id"
                    :reduce="r => r.pathways_cdb_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 7">
                  <v-select :appendToBody="true" :options="vocDispersalRoutesList" v-model="$store.state.formSearchEspecies.route_id" label="value" index="route_id"
                    :reduce="r => r.route_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 8">
                  <v-select :appendToBody="true" :options="vocVectorsCdbList" v-model="$store.state.formSearchEspecies.vectors_cdb_id" label="value" index="vectors_cdb_id"
                    :reduce="r => r.vectors_cdb_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 6 && $store.state.formSearchEspecies.fieldInvasaoBiologia === 9">
                  <v-select :appendToBody="true" :options="vocDispersionVectorsList" v-model="$store.state.formSearchEspecies.vector_id" label="value" index="vector_id"
                    :reduce="r => r.vector_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Invasão biológica -->

                <!-- Impactos -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 7">
                  <v-select :appendToBody="true" :options="vocImpactsList" v-model="$store.state.formSearchEspecies.impact_id" label="value" index="impact_id"
                    :reduce="r => r.impact_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Impactos -->

                <!-- Ocorrencias -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 8">
                  <v-select :appendToBody="true" :options="listOccurrences" v-model="$store.state.formSearchEspecies.fieldOccurrence" label="text" index="value"
                    :reduce="r => r.value" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.text}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 8 && $store.state.formSearchEspecies.fieldOccurrence === 1">
                  <v-select :appendToBody="true" :options="statesList" v-model="$store.state.formSearchEspecies.state_id" label="state" index="state_id"
                    :reduce="r => r.state_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.state}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.state}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 8 && $store.state.formSearchEspecies.fieldOccurrence === 2">
                  <v-select :appendToBody="true" :filterable="false" @search="getVocMunicipios" :options="vocMunicipiosList" v-model="$store.state.formSearchEspecies.municipio_id" label="municipio" index="municipio_id"
                    :reduce="r => r.municipio_id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.municipio}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.municipio}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 8 && $store.state.formSearchEspecies.fieldOccurrence === 3">
                  <input type="text" class="form-control bg-laranja" v-model="$store.state.formSearchEspecies.protected_area"/>
                </div>
                <div class="col-12 col-lg-3" v-if="$store.state.formSearchEspecies.theme === 8 && $store.state.formSearchEspecies.fieldOccurrence === 4">
                  <v-select :appendToBody="true" :options="vocEnvironmentsList" v-model="$store.state.formSearchEspecies.environment_id" label="value" index="environment_id"
                        :reduce="r => r.environment_id" :clearable="false" class="bg-laranja-select vue-select">
                        <template v-slot:selected-option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:option="option">
                          {{ `${option.value}` }}
                        </template>
                        <template v-slot:no-options="{ search, searching }">
                          <template v-if="searching">
                            {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                          </template>
                        </template>
                        <template #open-indicator="{ attributes }">
                          <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                        </template>
                      </v-select>
                </div>
                <!-- Ocorrencias -->
                <!-- Hábitat -->
                <div class="col-12 col-lg-4" v-if="$store.state.formSearchEspecies.theme === 9">
                  <v-select :appendToBody="true" :options="habitats" v-model="$store.state.formSearchEspecies.habitat" label="value" index="id"
                    :reduce="r => r.id" :clearable="false" class="bg-laranja-select vue-select">
                    <template v-slot:selected-option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:option="option">
                      {{ `${option.value}` }}
                    </template>
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        {{`${ $t('message.noResultsFor') } `}}<em>{{ search }}</em>.
                      </template>
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes"><img src="/img/arrow-drop-white.1894748c.svg"/></span>
                    </template>
                  </v-select>
                </div>
                <!-- Hábitat -->
                <div class="col-12 col-lg-2 ml-auto text-right pt-1">
                  <button type="button" @click="clear" class="btn btn-outline-secondary mt-1 mb-2 mr-1">
                    {{ $t('label.limpar') }}
                  </button>
                  <button type="submit" class="btn bt-filtrar mt-1 mb-2">
                    Buscar
                  </button>
                </div>
              </div>
              <div class="row pl-2 pt-1 pb-0">
                <div class="col-12">
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.specieSelected.length > 0">
                    <b class="d-flex justify-content-start">{{$t('label.nomeCientifico')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.specieNameSelected}}</li>
                      <li><a @click="removeFilter('specieSelected')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.taxonomySynonymousDesc">
                    <b class="d-flex justify-content-start">{{$t('label.sinonimos')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.taxonomySynonymousDesc}}</li>
                      <li><a @click="removeFilter('taxonomySynonymousDesc')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.commonNameDesc">
                    <b class="d-flex justify-content-start">{{$t('label.nomesPopulares')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.commonNameDesc}}</li>
                      <li><a @click="removeFilter('commonNameDesc')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.kingdom_id">
                    <b class="d-flex justify-content-start">{{$t('label.reino')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.kingdom}}</li>
                      <li><a @click="removeFilter('kingdom_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.phyllum_id">
                    <b class="d-flex justify-content-start">{{$t('label.phylum')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.phyllum}}</li>
                      <li><a @click="removeFilter('phyllum_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.class_id">
                    <b class="d-flex justify-content-start">{{$t('label.classe')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.class}}</li>
                      <li><a @click="removeFilter('class_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.order_id">
                    <b class="d-flex justify-content-start">{{$t('label.ordem')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.oorder}}</li>
                      <li><a @click="removeFilter('order_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.family_id">
                    <b class="d-flex justify-content-start">{{$t('label.familia')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.family}}</li>
                      <li><a @click="removeFilter('family_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.genus_id">
                    <b class="d-flex justify-content-start">{{$t('label.genero')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.genus}}</li>
                      <li><a @click="removeFilter('genus_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.origin">
                    <b class="d-flex justify-content-start">{{$t('label.origem')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.originDesc}}</li>
                      <li><a @click="removeFilter('origin')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.reproduction_id">
                    <b class="d-flex justify-content-start">{{$t('label.reproducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.reproductionDesc}}</li>
                      <li><a @click="removeFilter('reproduction_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.spread_id">
                    <b class="d-flex justify-content-start">{{$t('label.dispersao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.spreadDesc}}</li>
                      <li><a @click="removeFilter('spread_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.diet_id">
                    <b class="d-flex justify-content-start">{{$t('label.dieta')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.dietDesc}}</li>
                      <li><a @click="removeFilter('diet_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.form_id">
                    <b class="d-flex justify-content-start">{{$t('label.formaBiologica')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.formDesc}}</li>
                      <li><a @click="removeFilter('form_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.economic_use_id">
                    <b class="d-flex justify-content-start">{{$t('label.usoEconomico')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.economicUseDesc}}</li>
                      <li><a @click="removeFilter('economic_use_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.pref_inv_env_id">
                    <b class="d-flex justify-content-start">{{$t('label.ambientesPreferenciaisInvasao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.prefInvEnvDesc}}</li>
                      <li><a @click="removeFilter('pref_inv_env_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.introduction_type_id">
                    <b class="d-flex justify-content-start">{{$t('label.tipoIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.introductionTypeDesc}}</li>
                      <li><a @click="removeFilter('introduction_type_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.cause_introduction_id">
                    <b class="d-flex justify-content-start">{{$t('label.causaIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.causeIntroductionDesc}}</li>
                      <li><a @click="removeFilter('cause_introduction_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.location">
                    <b class="d-flex justify-content-start">{{$t('label.localIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.location}}</li>
                      <li><a @click="removeFilter('location')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.intro_data">
                    <b class="d-flex justify-content-start">{{$t('label.anoIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.intro_data}}</li>
                      <li><a @click="removeFilter('intro_data')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.impact_id">
                    <b class="d-flex justify-content-start">{{$t('label.impactos')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.impactDesc}}</li>
                      <li><a @click="removeFilter('impact_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.state_id">
                    <b class="d-flex justify-content-start">{{$t('label.estado')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.state}}</li>
                      <li><a @click="removeFilter('state_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.municipio_id">
                    <b class="d-flex justify-content-start">{{$t('label.municipio')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.municipio}}</li>
                      <li><a @click="removeFilter('municipio_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.environment_id">
                    <b class="d-flex justify-content-start">{{$t('label.ambiente')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.environment}}</li>
                      <li><a @click="removeFilter('environment_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.protected_area">
                    <b class="d-flex justify-content-start">{{$t('label.areaProtegida')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.protected_area}}</li>
                      <li><a @click="removeFilter('protected_area')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.habitat">
                    <b class="d-flex justify-content-start">{{$t('label.habitat')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.habitatDesc}}</li>
                      <li><a @click="removeFilter('habitat')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.pathways_cdb_id">
                    <b class="d-flex justify-content-start">{{$t('label.categoriaCDB')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.pathwaysCdbDesc}}</li>
                      <li><a @click="removeFilter('pathways_cdb_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.route_id">
                    <b class="d-flex justify-content-start">{{$t('label.viasIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.routeDesc}}</li>
                      <li><a @click="removeFilter('route_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.vectors_cdb_id">
                    <b class="d-flex justify-content-start">{{$t('label.subCategoriaCDB')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.vectorsCdbDesc}}</li>
                      <li><a @click="removeFilter('vectors_cdb_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                  <span class="filtros-selecionados" v-if="$store.state.formSearchEspecies.vector_id">
                    <b class="d-flex justify-content-start">{{$t('label.vetoresIntroducao')}}:</b>
                    <ul class="acoes d-flex justify-content-end">
                      <li>{{$store.state.formSearchEspecies.vectorDesc}}</li>
                      <li><a @click="removeFilter('vector_id')"><span class="apagar"></span></a></li>
                    </ul>
                  </span>
                </div>
              </div>
            </form>

            <div class="row p-3 bg_table_header" :class="withFilter ? 'mt-1 mt-xl-2' : ''" id="tut-num-registro">
              <div class="col-auto d-none d-sm-block">
                <p class="mb-0 mt-2">
                  {{ $t('label.numRegistrosEncontrados') }}
                  <span class="laranja">{{$store.state.countList}}</span>
                </p>
              </div>

              <div class="col-auto ml-auto text-right select_registro" id="select-registro">
                <select class="form-select  form-select-lg mb-0" ref="select" v-model="$store.state.perPage" @change="search">
                  <option selected value="10">{{ $t('label.exibir10Reg') }}</option>
                  <option value="25">{{ $t('label.exibir25Reg') }}</option>
                  <option value="50">{{ $t('label.exibir50Reg') }}</option>
                  <option value="100">{{ $t('label.exibir100Reg') }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EspeciesFiltro',
  props: {
    withFilter: {
      type: Boolean,
      default: false
    },
    byInstitucional: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      listThemes: [],
      listTaxonomy: [],
      taxonomyGenusList: [],
      taxonomyKingdomList: [],
      taxonomyPhyllumList: [],
      taxonomyOrderList: [],
      listOrigin: [],
      listBiologiaEcologia: [],
      listInvasaoBiologia: [],
      listOccurrences: [],
      vocDietList: [],
      vocSpreadList: [],
      vocReproductionList: [],
      vocFormList: [],
      vocEconomicalUseList: [],
      vocprefenvironmentsList: [],
      vocIntroductionTypeList: [],
      vocCauseIntroductionList: [],
      vocImpactsList: [],
      vocMunicipiosList: [],
      statesList: [],
      taxonomyClassList: [],
      speciesList: [],
      habitats: [],
      vocPathwaysCdbList: [],
      vocDispersionVectorsList: [],
      vocDispersalRoutesList: [],
      vocVectorsCdbList: [],
      vocEnvironmentsList: [],
      padeSizeComboSpecie: 10
    }
  },
  watch: {
    '$i18n.locale' () {
      this.loadFilters()
      this.$store.state.formSearchEspecies.theme = undefined
      setTimeout(() => {
        this.$store.state.formSearchEspecies.theme = null
      }, 150)
    },
    '$store.state.formSearchEspecies.municipio_id' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getVocMunicipios()
      }
      if (newValue) {
        const list = this.vocMunicipiosList.filter(l => l.municipio_id === newValue)
        if (list.length > 0) {
          this.$store.state.formSearchEspecies.municipio = list[0].municipio
        }
      }
    },
    '$store.state.formSearchEspecies.environment_id' (newValue, oldValue) {
      if (oldValue === undefined) {
        this.getVocEnvironments()
      }
      if (newValue) {
        const list = this.vocEnvironmentsList.filter(l => l.environment_id === newValue)
        if (list.length > 0) {
          this.$store.state.formSearchEspecies.environment = list[0].value
        }
      }
    },
    '$store.state.formSearchEspecies.specieSelected' (newValue) {
      const desc = []
      newValue.forEach(item => {
        const list = this.speciesList.filter(l => l.species_id === item)
        if (list.length > 0) {
          desc.push(list[0].scientific_name)
        }
      })
      this.$store.state.formSearchEspecies.specieNameSelected = desc.join(', ')
    },
    '$store.state.formSearchEspecies.kingdom_id' (newValue) {
      const list = this.taxonomyKingdomList.filter(l => l.kingdom_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.kingdom = list[0].kingdom
      }
      this.getTaxonomyPhyllum()
    },
    '$store.state.formSearchEspecies.phyllum_id' (newValue) {
      const list = this.taxonomyPhyllumList.filter(l => l.phyllum_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.phyllum = list[0].phyllum
      }
      this.getTaxonomyClass()
    },
    '$store.state.formSearchEspecies.class_id' (newValue) {
      const list = this.taxonomyClassList.filter(l => l.class_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.class = list[0].class
      }
      this.getTaxonomyOrder()
    },
    '$store.state.formSearchEspecies.order_id' (newValue) {
      const list = this.taxonomyOrderList.filter(l => l.order_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.oorder = list[0].oorder
      }
      this.getTaxonomyFamily()
    },
    '$store.state.formSearchEspecies.family_id' (newValue) {
      const list = this.taxonomyFamilyList.filter(l => l.family_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.family = list[0].family
      }
      this.getTaxonomyGenus()
    },
    '$store.state.formSearchEspecies.genus_id' (newValue) {
      const list = this.taxonomyGenusList.filter(l => l.genus_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.genus = list[0].genus
      }
    },
    '$store.state.formSearchEspecies.origin' (newValue) {
      const list = this.listOrigin.filter(l => l.value === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.originDesc = list[0].text
      }
    },
    '$store.state.formSearchEspecies.reproduction_id' (newValue) {
      const list = this.vocReproductionList.filter(l => l.reproduction_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.reproductionDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.spread_id' (newValue) {
      const list = this.vocSpreadList.filter(l => l.spread_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.spreadDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.diet_id' (newValue) {
      const list = this.vocDietList.filter(l => l.diet_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.dietDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.form_id' (newValue) {
      const list = this.vocFormList.filter(l => l.form_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.formDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.economic_use_id' (newValue) {
      const list = this.vocEconomicalUseList.filter(l => l.economical_use_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.economicUseDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.pref_inv_env_id' (newValue) {
      const list = this.vocprefenvironmentsList.filter(l => l.pref_inv_env_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.prefInvEnvDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.introduction_type_id' (newValue) {
      const list = this.vocIntroductionTypeList.filter(l => l.introduction_type_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.introductionTypeDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.cause_introduction_id' (newValue) {
      const list = this.vocCauseIntroductionList.filter(l => l.cause_introduction_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.causeIntroductionDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.impact_id' (newValue) {
      const list = this.vocImpactsList.filter(l => l.impact_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.impactDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.state_id' (newValue) {
      const list = this.statesList.filter(l => l.state_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.state = list[0].state
      }
    },
    '$store.state.formSearchEspecies.habitat' (newValue) {
      const list = this.habitats.filter(l => l.id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.habitatDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.pathways_cdb_id' (newValue) {
      const list = this.vocPathwaysCdbList.filter(l => l.pathways_cdb_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.pathwaysCdbDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.route_id' (newValue) {
      const list = this.vocDispersalRoutesList.filter(l => l.route_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.routeDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.vectors_cdb_id' (newValue) {
      const list = this.vocVectorsCdbList.filter(l => l.vectors_cdb_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.vectorsCdbDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.vector_id' (newValue) {
      const list = this.vocDispersionVectorsList.filter(l => l.vector_id === newValue)
      if (list.length > 0) {
        this.$store.state.formSearchEspecies.vectorDesc = list[0].value
      }
    },
    '$store.state.formSearchEspecies.theme' (newValue) {
      this.loadVocs(newValue)
    }
  },
  methods: {
    getVocVectorsCdb: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/VocVectorsCdb/getall', { params })
        .then(response => {
          this.vocVectorsCdbList = response.data.items.map(i => {
            const item = {
              vectors_cdb_id: i.vectors_cdb_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocDispersalRoutes: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocdispersalroutes/getall', { params })
        .then(response => {
          this.vocDispersalRoutesList = response.data.items.map(i => {
            const item = {
              route_id: i.route_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocDispersionVectors: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocdispersionvectors/getall', { params })
        .then(response => {
          this.vocDispersionVectorsList = response.data.items.map(i => {
            const item = {
              vector_id: i.vector_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocPathwaysCdb: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/VocPathwaysCdb/getall', { params })
        .then(response => {
          this.vocPathwaysCdbList = response.data.items.map(i => {
            const item = {
              pathways_cdb_id: i.pathways_cdb_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    loadFilters: function () {
      this.listThemes = [
        { value: null, text: this.$t('label.filtros') },
        { value: 1, text: this.$t('label.nomeCientifico') },
        { value: 11, text: this.$t('label.nomeCientificoSinonimos') },
        { value: 10, text: this.$t('label.nomesPopulares') },
        { value: 2, text: this.$t('label.taxonomia') },
        { value: 3, text: this.$t('label.origem') },
        { value: 4, text: this.$t('label.biologiaEcologia') },
        { value: 5, text: this.$t('label.usoEconomico') },
        { value: 6, text: this.$t('label.invasaoBiologica') },
        { value: 7, text: this.$t('label.impactos') },
        { value: 8, text: this.$t('label.ocorrencias') },
        { value: 9, text: this.$t('label.habitat') }
      ]
      this.listTaxonomy = [
        { value: 1, text: this.$t('label.reino') },
        { value: 2, text: this.$t('label.phylum') },
        { value: 3, text: this.$t('label.classe') },
        { value: 4, text: this.$t('label.ordem') },
        { value: 5, text: this.$t('label.familia') },
        { value: 6, text: this.$t('label.genero') }
      ]
      this.listOrigin = [
        { value: 'Exotic', text: this.$i18n.locale === 'en' ? 'Not native to Argentina' : this.$i18n.locale === 'es' ? 'No es nativo de Argentina' : 'Não nativa da Argentina' },
        { value: 'Native', text: this.$i18n.locale === 'en' ? 'Native of Argentina' : this.$i18n.locale === 'es' ? 'Nativo de Argentina' : 'Nativa da Argentina' },
        { value: 'Cripto', text: this.$i18n.locale === 'en' ? 'Origin unknown' : this.$i18n.locale === 'es' ? 'Origen desconocido' : 'Origem desconhecida' }
      ]
      this.listBiologiaEcologia = [
        { value: 1, text: this.$t('label.reproducao') },
        { value: 2, text: this.$t('label.dispersao') },
        { value: 3, text: this.$t('label.dieta') },
        { value: 4, text: this.$t('label.formaBiologica') }
      ]
      this.listInvasaoBiologia = [
        { value: 1, text: this.$t('label.ambientesPreferenciaisInvasao') },
        { value: 2, text: this.$t('label.tipoIntroducao') },
        { value: 3, text: this.$t('label.causaIntroducao') },
        { value: 4, text: this.$t('label.localIntroducao') },
        { value: 5, text: this.$t('label.anoIntroducao') },
        { value: 6, text: this.$t('label.categoriaCDB') },
        { value: 7, text: this.$t('label.viasIntroducao') },
        { value: 8, text: this.$t('label.subCategoriaCDB') },
        { value: 9, text: this.$t('label.vetoresIntroducao') }
      ]
      this.listOccurrences = [
        { value: 1, text: this.$t('label.estado') },
        { value: 2, text: this.$t('label.municipio') },
        { value: 3, text: this.$t('label.areaProtegida') },
        { value: 4, text: this.$t('label.ambiente') }
      ]
      this.habitats = [
        { id: null, value: this.$t('label.selecioneOpcao') },
        { id: 1, value: this.$t('label.marinho') },
        { id: 2, value: this.$t('label.dulcicola') },
        { id: 3, value: this.$t('label.terrestre') }
      ]
    },
    removeFilter: function (prop) {
      switch (prop) {
        case 'specieSelected':
          this.$store.state.formSearchEspecies.specieSelected = []
          break
        case 'taxonomySynonymousDesc':
          this.$store.state.formSearchEspecies.taxonomySynonymousDesc = null
          break
        case 'commonNameDesc':
          this.$store.state.formSearchEspecies.commonNameDesc = null
          break
        case 'kingdom_id':
          this.$store.state.formSearchEspecies.kingdom_id = null
          break
        case 'phyllum_id':
          this.$store.state.formSearchEspecies.phyllum_id = null
          break
        case 'class_id':
          this.$store.state.formSearchEspecies.class_id = null
          break
        case 'order_id':
          this.$store.state.formSearchEspecies.order_id = null
          break
        case 'family_id':
          this.$store.state.formSearchEspecies.family_id = null
          break
        case 'genus_id':
          this.$store.state.formSearchEspecies.genus_id = null
          break
        case 'origin':
          this.$store.state.formSearchEspecies.origin = null
          break
        case 'reproduction_id':
          this.$store.state.formSearchEspecies.reproduction_id = null
          break
        case 'spread_id':
          this.$store.state.formSearchEspecies.spread_id = null
          break
        case 'diet_id':
          this.$store.state.formSearchEspecies.diet_id = null
          break
        case 'form_id':
          this.$store.state.formSearchEspecies.form_id = null
          break
        case 'economic_use_id':
          this.$store.state.formSearchEspecies.economic_use_id = null
          break
        case 'pref_inv_env_id':
          this.$store.state.formSearchEspecies.pref_inv_env_id = null
          break
        case 'introduction_type_id':
          this.$store.state.formSearchEspecies.introduction_type_id = null
          break
        case 'cause_introduction_id':
          this.$store.state.formSearchEspecies.cause_introduction_id = null
          break
        case 'location':
          this.$store.state.formSearchEspecies.location = null
          break
        case 'intro_data':
          this.$store.state.formSearchEspecies.intro_data = null
          break
        case 'impact_id':
          this.$store.state.formSearchEspecies.impact_id = null
          break
        case 'state_id':
          this.$store.state.formSearchEspecies.state_id = null
          break
        case 'municipio_id':
          this.$store.state.formSearchEspecies.municipio_id = null
          break
        case 'protected_area':
          this.$store.state.formSearchEspecies.protected_area = null
          break
        case 'habitat':
          this.$store.state.formSearchEspecies.habitat = null
          break
        case 'pathways_cdb_id':
          this.$store.state.formSearchEspecies.pathways_cdb_id = null
          break
        case 'route_id':
          this.$store.state.formSearchEspecies.route_id = null
          break
        case 'vectors_cdb_id':
          this.$store.state.formSearchEspecies.vectors_cdb_id = null
          break
        case 'vector_id':
          this.$store.state.formSearchEspecies.vector_id = null
          break
        case 'environment_id':
          this.$store.state.formSearchEspecies.environment_id = null
          break
      }
    },
    clear: function () {
      this.$emit('clear')
    },
    getSpecies: async function (search, loading) {
      const params = {
        Page: 1,
        PageSize: this.padeSizeComboSpecie
      }
      // if (this.byInstitucional) {
      //   params.species_type = 1
      // }
      if (search && search.length > 2) {
        params.scientific_name = search
        params.species_id_array = this.$store.state.formSearchEspecies.specieSelected.join(',')
        loading(true)
        await axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.species_id_array = this.$store.state.formSearchEspecies.specieSelected.join(',')
        await axios.get('/Specie/getallcombo', { params })
          .then(response => {
            this.speciesList = response.data.items
            this.padeSizeComboSpecie = 9999
          })
      }
    },
    getTaxonomyKingdom: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/TaxonomyKingdom/getall', { params })
        .then(response => {
          this.taxonomyKingdomList = response.data.items
        })
    },
    getTaxonomyPhyllum: async function () {
      const params = { Page: 1, PageSize: 9999, kingdom_id: this.$store.state.formSearchEspecies.kingdom_id }
      await axios.get('/TaxonomyPhyllum/getall', { params })
        .then(response => {
          this.taxonomyPhyllumList = response.data.items
        })
    },
    getTaxonomyClass: async function () {
      const params = { Page: 1, PageSize: 9999, phyllum_id: this.$store.state.formSearchEspecies.phyllum_id }
      await axios.get('/TaxonomyClass/getall', { params })
        .then(response => {
          this.taxonomyClassList = response.data.items
        })
    },
    getTaxonomyOrder: async function () {
      const params = { Page: 1, PageSize: 9999, class_id: this.$store.state.formSearchEspecies.class_id }
      await axios.get('/TaxonomyOrder/getall', { params })
        .then(response => {
          this.taxonomyOrderList = response.data.items
        })
    },
    getTaxonomyFamily: async function () {
      const params = { Page: 1, PageSize: 9999, order_id: this.$store.state.formSearchEspecies.order_id }
      await axios.get('/TaxonomyFamily/getall', { params })
        .then(response => {
          this.taxonomyFamilyList = response.data.items
        })
    },
    getTaxonomyGenus: async function () {
      const params = { Page: 1, PageSize: 9999, family_id: this.$store.state.formSearchEspecies.family_id }
      await axios.get('/TaxonomyGenus/getall', { params })
        .then(response => {
          this.taxonomyGenusList = response.data.items
        })
    },
    getVocForm: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocform/getall', { params })
        .then(response => {
          this.vocFormList = response.data.items.map(i => {
            const item = {
              form_id: i.form_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocReproduction: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocreproduction/getall', { params })
        .then(response => {
          this.vocReproductionList = response.data.items.map(i => {
            const item = {
              reproduction_id: i.reproduction_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocSpread: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocspread/getall', { params })
        .then(response => {
          this.vocSpreadList = response.data.items.map(i => {
            const item = {
              spread_id: i.spread_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocDiet: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocDiet/getall', { params })
        .then(response => {
          this.vocDietList = response.data.items.map(i => {
            const item = {
              diet_id: i.diet_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocEconomicalUse: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/voceconomicaluse/getall', { params })
        .then(response => {
          this.vocEconomicalUseList = response.data.items.map(i => {
            const item = {
              economical_use_id: i.economical_use_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocPrefEnvironments: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocprefenvironments/getall', { params })
        .then(response => {
          this.vocprefenvironmentsList = response.data.items.map(i => {
            const item = {
              pref_inv_env_id: i.pref_inv_env_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocIntroductionType: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/VocIntroductionType/getall', { params })
        .then(response => {
          this.vocIntroductionTypeList = response.data.items.map(i => {
            const item = {
              introduction_type_id: i.introduction_type_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocCauseIntroduction: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/VocCauseIntroduction/getall', { params })
        .then(response => {
          this.vocCauseIntroductionList = response.data.items.map(i => {
            const item = {
              cause_introduction_id: i.cause_introduction_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getVocImpacts: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/vocimpacts/getall', { params })
        .then(response => {
          this.vocImpactsList = response.data.items.map(i => {
            const item = {
              impact_id: i.impact_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    getStates: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/VocStates/getall', { params })
        .then(response => {
          this.statesList = response.data.items
        })
    },
    getVocMunicipios: async function (search, loading) {
      const params = {
        Page: 1,
        PageSize: 20
      }
      if (search && search.length > 2) {
        params.municipio = search
        loading(true)
        await axios.get('/VocMunicipios/getall', { params })
          .then(response => {
            this.vocMunicipiosList = response.data.items
            loading(false)
          })
      } else if (!search) {
        params.municipio_id = this.$store.state.formSearchEspecies.municipio_id
        await axios.get('/VocMunicipios/getall', { params })
          .then(response => {
            this.vocMunicipiosList = response.data.items
          })
      }
    },
    getVocEnvironments: async function () {
      const params = { Page: 1, PageSize: 9999 }
      await axios.get('/VocEnvironments/getall', { params })
        .then(response => {
          this.vocEnvironmentsList = response.data.items.map(i => {
            const item = {
              environment_id: i.environment_id,
              value: ''
            }
            switch (this.$i18n.locale) {
              case 'en':
                item.value = i.en
                break
              case 'es':
                item.value = i.es
                break
              case 'pt':
                item.value = i.pt
                break
            }
            return item
          })
        })
    },
    search: function () {
      this.$emit('search', this.$store.state.formSearchEspecies)
    },
    loadVocs: function (value) {
      if (value === 1) {
        if (this.speciesList.length === 0) {
          this.getSpecies()
        }
      } else if (value === 2) {
        if (this.taxonomyKingdomList.length === 0 ||
        this.taxonomyPhyllumList.length === 0 ||
        this.taxonomyClassList.length === 0 ||
        this.taxonomyOrderList.length === 0 ||
        this.taxonomyFamilyList.length === 0 ||
        this.taxonomyGenusList.length === 0) {
          this.getTaxonomyKingdom()
          this.getTaxonomyPhyllum()
          this.getTaxonomyClass()
          this.getTaxonomyOrder()
          this.getTaxonomyFamily()
          this.getTaxonomyGenus()
        }
      } else if (value === 4) {
        if (this.vocFormList.length === 0 ||
        this.vocReproductionList.length === 0 ||
        this.vocSpreadList.length === 0 ||
        this.vocDietList.length === 0) {
          this.getVocForm()
          this.getVocReproduction()
          this.getVocSpread()
          this.getVocDiet()
        }
      } else if (value === 5) {
        if (this.vocEconomicalUseList.length === 0) {
          this.getVocEconomicalUse()
        }
      } else if (value === 6) {
        if (this.Environment.length === 0 ||
        this.vocprefenvironmentsList.length === 0 ||
        this.vocIntroductionTypeList.length === 0 ||
        this.vocCauseIntroductionList.length === 0 ||
        this.vocPathwaysCdbList.length === 0 ||
        this.vocDispersalRoutesList.length === 0 ||
        this.vocVectorsCdbList.length === 0 ||
        this.vocDispersionVectorsList.length === 0) {
          this.getVocPrefEnvironments()
          this.getVocIntroductionType()
          this.getVocCauseIntroduction()
          this.getVocPathwaysCdb()
          this.getVocDispersalRoutes()
          this.getVocVectorsCdb()
          this.getVocDispersionVectors()
        }
      } else if (value === 7) {
        if (this.vocImpactsList.length === 0) {
          this.getVocImpacts()
        }
      } else if (value === 8) {
        if (this.statesList.length === 0 ||
        this.vocMunicipiosList.length === 0 ||
        this.vocEnvironmentsList.length === 0) {
          this.getStates()
          this.getVocMunicipios()
          this.getVocEnvironments()
        }
      }
    }
  },
  created: async function () {
    if (this.withFilter) {
      if (this.$store.state.beforeRouter === 'AdminEspeciesForm') {
        if (this.$store.state.formSearchEspecies.specieSelected.length > 0) {
          this.padeSizeComboSpecie = 9999
        } else {
          this.padeSizeComboSpecie = 10
        }
      }
      this.loadFilters()
      this.loadVocs(this.$store.state.formSearchEspecies.theme)
    }
  }
}
</script>

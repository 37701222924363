<template>
  <div class="container-fluid p-0">
    <PageTitle v-if="withPageTitle" :title="$t('label.especies')" :titleAdd="$t('label.adicionarEspecie')" :isAdd="true"/>
    <EspeciesFiltro @search="search" @clear="clear" :withFilter="withFilter" :byInstitucional="byInstitucional"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
        <div class="card flex-fill">
          <div class="card-body box-dashboard p-0">
            <div class="align-self-center">
              <EspeciesTable @orderBy="orderBy" @search="search" :items="items" :withOrderBy="withOrderBy" :byInstitucional="byInstitucional" @actionByInstitucional="actionByInstitucional"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /tabela -->
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import EspeciesTable from './EspeciesTable.vue'
import EspeciesFiltro from './EspeciesFiltro.vue'
export default {
  name: 'Especies',
  components: { PageTitle, EspeciesTable, EspeciesFiltro },
  props: {
    byInstitucional: {
      type: Boolean,
      default: false
    },
    withPageTitle: {
      type: Boolean,
      default: true
    },
    withOrderBy: {
      type: Boolean,
      default: true
    },
    withFilter: {
      type: Boolean,
      default: true
    },
    dataFilter: {
      type: Object
    }
  },
  data: function () {
    return {
      filter: {
        theme: 1,
        fieldTaxonomy: 1,
        fieldBiologiaEcologia: 1,
        fieldInvasaoBiologia: 1,
        fieldOccurrence: 1
      },
      formSearch: {},
      items: [],
      dataOrderBy: {}
    }
  },
  methods: {
    orderBy: function (dataOrderBy) {
      this.$store.state.dataOrderBy = dataOrderBy
      this.search()
    },
    setFormOrderBy: function () {
      this.formSearch.OrderByKingdomAcs = this.$store.state.dataOrderBy.OrderByKingdomAcs
      this.formSearch.OrderByKingdomDesc = this.$store.state.dataOrderBy.OrderByKingdomDesc
      this.formSearch.OrderByKingdomDesc = this.$store.state.dataOrderBy.OrderByKingdomDesc
      this.formSearch.OrderByKingdomAcs = this.$store.state.dataOrderBy.OrderByKingdomAcs
      this.formSearch.OrderByFamilyAcs = this.$store.state.dataOrderBy.OrderByFamilyAcs
      this.formSearch.OrderByFamilyDesc = this.$store.state.dataOrderBy.OrderByFamilyDesc
      this.formSearch.OrderByFamilyDesc = this.$store.state.dataOrderBy.OrderByFamilyDesc
      this.formSearch.OrderByFamilyAcs = this.$store.state.dataOrderBy.OrderByFamilyAcs
      this.formSearch.OrderByScientificNameAcs = this.$store.state.dataOrderBy.OrderByScientificNameAcs
      this.formSearch.OrderByScientificNameDesc = this.$store.state.dataOrderBy.OrderByScientificNameDesc
      this.formSearch.OrderByScientificNameDesc = this.$store.state.dataOrderBy.OrderByScientificNameDesc
      this.formSearch.OrderByScientificNameAcs = this.$store.state.dataOrderBy.OrderByScientificNameAcs
      this.formSearch.OrderByIDAcs = this.$store.state.dataOrderBy.OrderByIDAcs
      this.formSearch.OrderByIDDesc = this.$store.state.dataOrderBy.OrderByIDDesc
    },
    search: async function (filter) {
      if (filter) {
        this.filter = filter
      }
      this.$store.dispatch('showPreload')
      if (this.byInstitucional && this.dataFilter) {
        this.formSearch = this.dataFilter
      } else {
        if (this.filter.specieSelected) {
          this.formSearch.species_id_array = this.filter.specieSelected.join(',')
        }
        this.formSearch.kingdom_id = this.filter.kingdom_id
        this.formSearch.phyllum_id = this.filter.phyllum_id
        this.formSearch.class_id = this.filter.class_id
        this.formSearch.order_id = this.filter.order_id
        this.formSearch.family_id = this.filter.family_id
        this.formSearch.genus_id = this.filter.genus_id
        this.formSearch.origin = this.filter.origin
        this.formSearch.reproduction_id = this.filter.reproduction_id
        this.formSearch.spread_id = this.filter.spread_id
        this.formSearch.diet_id = this.filter.diet_id
        this.formSearch.biological_form_id = this.filter.form_id
        this.formSearch.economic_use_id = this.filter.economic_use_id
        this.formSearch.pref_inv_env_id = this.filter.pref_inv_env_id
        this.formSearch.introduction_type_id = this.filter.introduction_type_id
        this.formSearch.cause_introduction_id = this.filter.cause_introduction_id
        this.formSearch.location = this.filter.location
        this.formSearch.intro_data = this.filter.intro_data
        this.formSearch.impact_id = this.filter.impact_id
        this.formSearch.state_id = this.filter.state_id
        this.formSearch.municipio_id = this.filter.municipio_id
        this.formSearch.environment_id = this.filter.environment_id
        this.formSearch.protected_area = this.filter.protected_area
        this.formSearch.habitat = this.filter.habitat
        this.formSearch.Common_name = this.filter.commonNameDesc
        this.formSearch.synonymous = this.filter.taxonomySynonymousDesc
        this.formSearch.pathways_cdb_id = this.filter.pathways_cdb_id
        this.formSearch.route_id = this.filter.route_id
        this.formSearch.vectors_cdb_id = this.filter.vectors_cdb_id
        this.formSearch.vector_id = this.filter.vector_id
        // if (this.byInstitucional) {
        //   this.formSearch.species_type = 1
        // } else {
        this.formSearch.species_type = this.filter.species_type
        // }
      }
      this.setFormOrderBy()
      if (this.filter.pending_analysis) {
        this.formSearch.pending_analysis = this.filter.pending_analysis
      } else {
        delete this.formSearch.pending_analysis
      }
      if (this.$store.state.currentPageSpecie) {
        this.$store.state.currentPage = this.$store.state.currentPageSpecie
      }
      const params = this.formSearch
      params.Page = this.$store.state.currentPage
      params.PageSize = this.$store.state.perPage
      await axios.get(this.byInstitucional ? '/Specie/getallwithcommomnames' : '/Specie/getallgrid', { params })
        .then(response => {
          this.$store.state.currentPage = response.data.page
          this.$store.state.countList = response.data.count
          this.items = response.data.items
          this.$store.state.currentPageSpecie = null
        })
    },
    actionByInstitucional: function (item) {
      this.$emit('actionByInstitucional', item)
    },
    clear: function () {
      this.$store.state.formSearchEspecies = {
        theme: null,
        fieldTaxonomy: 1,
        fieldBiologiaEcologia: 1,
        fieldInvasaoBiologia: 1,
        fieldOccurrence: 1,
        specieSelected: []
      }
      this.$store.state.dataOrderBy = {}
      this.formSearch = {}
    }
  },
  created: async function () {
    if (this.$store.state.beforeRouter === 'AdminEspeciesForm') {
      await this.search(this.$store.state.formSearchEspecies)
    } else {
      await this.search()
    }
  }
}
</script>

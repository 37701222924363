<template>
  <div class="container-fluid p-0">
    <PageTitle :title="pageTitle" :titleSave="$t('label.salvarUsuarix')" :isAdd="false" v-on:onSave="save()" v-on:onCancel="cancel()" v-on:onExcluir="excluir()" :nameEdit="nameEdit"/>

    <!-- tabela -->
    <div class="row">
      <div class="col-12 d-flex">
          <div class="card flex-fill ">
            <div class="card-body box-dashboard p-3">
              <div class="align-self-center">
                <div class="row">
                  <div class="col-md-6">
                    <div class="row">
                      <div class="mb-3 col-md-4">
                        <label for="usuarios-nome" class="form-label">{{ $t('login.username') }}</label>
                        <input class="form-control" id="usuarios-nome" type="text" v-model="form.name" :disabled="action === $actionEdit" :class="{ 'is-invalid': $v.form.name.$dirty && $v.form.name.$error }">
                          <div class="invalid-feedback">
                            {{ $t('message.requiredField') }}
                          </div>
                      </div>
                      <div class="mb-3  col-md-8">
                        <label for="usuarios-senha" class="form-label">{{ $t('label.senha') }}</label>
                        <input class="form-control" id="usuarios-senha" type="password" v-model="form.password" :class="{ 'is-invalid': clicou && action !== $actionEdit && !form.password }">
                        <div class="invalid-feedback">
                          {{ $t('message.requiredField') }}
                        </div>
                      </div>
                    </div>
                    <div class="mb-3 ">
                      <label for="usuarios-descricao" class="form-label">{{ $t('label.descricao') }}</label>
                      <input class="form-control" type="text" v-model="form.description" :class="{ 'is-invalid': $v.form.description.$dirty && $v.form.description.$error }">
                      <div class="invalid-feedback">
                        {{ $t('message.requiredField') }}
                       </div>
                    </div>
                    <div class="mb-3">
                      <label for="usuarios-observacoes" class="form-label">{{ $t('label.observacoes') }}</label>
                      <textarea id="usuarios-observacoes" v-model="form.observation" rows="4" class="box-dashboard p-2 textarea_adm form-control"></textarea>
                    </div>
                  </div>
                  <div class="col-md-6 border-start">
                    <div class="mb-3">
                      <label for="usuarios-email" class="form-label">{{ $t('label.email') }}</label>
                      <input class="form-control" id="usuarios-email" type="email" v-model="form.email">
                    </div>
                    <div class="mb-3">
                      <label for="expertise" class="form-label">{{ $t('label.especialidade') }}</label>
                      <input class="form-control" id="expertise" type="text" v-model="form.expertise">
                    </div>
                    <div class="mb-3">
                      <label for="profession" class="form-label">{{ $t('label.profissao') }}</label>
                      <input class="form-control" id="profession" type="text" v-model="form.profession">
                    </div>
                    <div class="mb-3">
                      <label for="usuarios-ativo" class="form-label">{{ $t('label.ativo') }}</label>
                      <div class="mt-0">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="Y" v-model="form.active">
                            <label class="form-check-label" >{{ $t('label.sim') }}</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" value="N" v-model="form.active">
                            <label class="form-check-label" >{{ $t('label.nao') }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3">
                      <div class="row  d-flex justify-content-center  align-items-center">
                        <label for="usuarios-grupos" class="form-label">{{ $t('label.grupos') }}</label>
                        <div class="mt-0">
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" value="basic" v-model="form.groupUsers[0].group_name">
                              <label class="form-check-label" >{{ $t('label.basico') }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" value="administrator" v-model="form.groupUsers[0].group_name">
                              <label class="form-check-label" >{{ $t('label.administrator') }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                              <input class="form-check-input" type="radio" value="appuser" v-model="form.groupUsers[0].group_name">
                              <label class="form-check-label" >{{ $t('label.appuser') }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import PageTitle from '@/components/shared/PageTitle'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'UsuariosForm',
  components: {
    PageTitle
  },
  data: function () {
    return {
      pageTitle: 'Usuarixs > Adicionar',
      nameEdit: '',
      action: this.$actionNew,
      form: { name: '', active: 'Y', groupUsers: [{ group_name: 'basic' }] },
      statesList: [],
      vocSpecialtiesList: [],
      clicou: false
    }
  },
  validations: {
    form: {
      name: { required },
      description: { required }
    }
  },
  watch: {
    'form.password' () {
      if (this.password) {
        this.clicou = false
      }
    }
  },
  methods: {
    getById: function (id) {
      this.$store.dispatch('showPreload')
      axios.get(`/User/${id}`)
        .then(response => {
          this.form = response.data
          this.nameEdit = ` ${this.form.name}`
        })
    },
    save: function () {
      this.clicou = true
      this.$v.$touch()
      if (this.$v.form.$invalid || (this.action !== this.$actionEdit && !this.form.password)) {
        return
      }
      if (this.action === this.$actionEdit) {
        axios.put('/User', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      } else {
        axios.post('/User', this.form)
          .then(() => {
            this.$toasted.global.saved()
            this.cancel()
          })
      }
    },
    cancel: function () {
      this.$router.replace('/admin/usuarios')
    },
    excluir: function () {
      if (confirm(this.$t('message.confirmDel'))) {
        axios.delete(`/User/${this.form.name}`)
          .then(() => {
            this.$toasted.global.defaultSuccess()
            this.cancel()
          })
      }
    }
  },
  created: function () {
    this.action =
      this.$route.params.id !== this.$actionNew
        ? this.$actionEdit
        : this.$actionNew

    if (this.action === this.$actionEdit) {
      this.pageTitle = 'Usuarixs > Editando:'
      this.getById(this.$route.params.id)
    }
  }
}
</script>

export const ptBR = {
  message: {
    requiredField: 'Campo obrigatório',
    noRecords: 'Nenhum registro encontrado!',
    confirmDel: 'Tem certeza de que deseja excluir?',
    confirmApproval: 'Tem certeza de que deseja aprovar esta ocorrência?',
    noResultsFor: 'Nenhum resultado para'
  },
  login: {
    username: 'Usuárix',
    password: 'Senha',
    login: 'Login',
    entrar: 'Entrar',
    descricao: 'Entre com seus dados de acesso no formulário abaixo:'
  },
  exportarFicha: {
    author: 'autor',
    biodiversity_impact: 'impato_na_biodiversidade',
    chemical_control: 'controle_quimico',
    class: 'classe',
    common_name: 'nome_comum',
    control_biological: 'controle_biológico',
    data_bibliography: 'dados_bibliografia',
    economic_impact: 'impacto_económico',
    economic_use: ' uso_econômico',
    family: 'família',
    health_impact: 'impacto_na_saúde',
    intro_data: 'dado_introdução',
    intro_description: 'descrição_da_introdução',
    invasion_preferential_environments: 'ambiente_preferencial_de_ invasão',
    kingdom: 'reino',
    location: 'localização',
    morphophysiology_description: 'descrição_morfofisiológica',
    native_distribution_area: 'área_de_distribuição_nativa',
    natural_environment: 'ambiente_natural',
    oorder: 'ordem',
    origin: 'origem',
    phyllum: 'phyllum',
    physical_control: 'controle_físico',
    prevention: ' prevenção',
    scientific_name: 'nome_científico',
    social_impact: 'impacto_social',
    species_id: 'id_espécie',
    voc_cause_introduction: 'voc_causa_introdução',
    voc_diet: 'voc_dieta',
    voc_dispersal_routes: 'voc_ruta_dispersão',
    voc_dispersion_vectors: 'voc_vetor_dispersão',
    voc_form: 'voc_forma',
    voc_introduction_type: 'voc_tipo_introdução',
    voc_reproduction: 'voc_reprodução',
    voc_spread: 'voc_ dispersão',
    world_invasive_place: 'mundo_locais_invasão'
  },
  label: {
    voltar: 'Voltar',
    outro: 'Outro',
    filtros: 'Filtros',
    acesseBaseDados: 'Acesse a base de dados',
    conhecaNossaRede: 'Rede Latino Americana',
    baseDadosNacional: 'InBiAr - Invasões biológicas na Argentina',
    politicaDeDados: 'Política de dados',
    comiteCientifico: 'Comitê científico',
    colabore: 'Fornecer dados',
    especiesMarinhas: 'Espécies marinhas e costeiras',
    peixesAquario: 'Peixes de aquário',
    peixesAquicultura: 'Peixes de aquicultura',
    pescaDesportiva: 'Pesca desportiva',
    pets: 'Pets',
    plantasForrageiras: 'Plantas forrageiras',
    plantasOrnamentais: 'Plantas ornamentais',
    usoFlorestal: 'Uso florestal',
    tutorial: 'Tutorial',
    especies: 'Espécies',
    colaboradores: 'Colaboradorxs',
    referencias: 'Referências',
    projetos: 'Projetos',
    saibaMais: 'Saiba mais',
    contida: 'Contida',
    casual: 'Casual',
    estabelecida: 'Estabelecida',
    invasora: 'Invasora',
    exibirListagem: 'Exibir listagem',
    concluir: 'Concluir',
    fechar: 'Fechar',
    próximo: 'Próximo',
    anterior: 'Anterior',
    clickAqui: 'Clique aqui',
    numRegistrosEncontrados: 'Número de registros encontrados:',
    exibir10Reg: 'Exibir 10 registros',
    exibir25Reg: 'Exibir 25 registros',
    exibir50Reg: 'Exibir 50 registros',
    exibir100Reg: 'Exibir 100 registros',
    limpar: 'Limpar',
    buscar: 'Buscar',
    addColaborador: 'Adicionar colaborador/a',
    nome: 'Nome',
    sobrenome: 'Sobrenome',
    aprovarColaborador: 'Aprovar colaborador/a',
    salvarColaborador: 'Salvar colaborador/a',
    titulo: 'Título',
    cargo: 'Cargo',
    especialidade: 'Especialidade',
    instituicao: 'Instituição',
    endereco: 'Endereço',
    estado: 'Estado',
    cidade: 'Cidade',
    pais: 'País',
    email: 'E-mail',
    telefone: 'Telefone 1',
    telefone2: 'Telefone 2',
    websiteURL: 'Website URL',
    midiaSocial1: 'Mídia Social 1',
    midiaSocial2: 'Mídia Social 2',
    midiaSocial3: 'Mídia Social 3',
    observacoesAdicionais: 'Observações adicionais',
    adicionar: 'Adicionar',
    editando: 'Editando',
    acoes: 'Ações',
    usuarixs: 'Usuárixs',
    adicionarUsuario: 'Adicionar usuárix',
    descricao: 'Descrição',
    ativo: 'Ativo',
    sim: 'Sim',
    nao: 'Não',
    salvarUsuarix: 'Salvar usuárix',
    senha: 'Senha',
    observacoes: 'Observações',
    grupos: 'Grupos',
    basico: 'Básico',
    administrator: 'Administrador',
    taxonomia: 'Taxonomia',
    nomeCientifico: 'Nome científico',
    adicionarEspecie: 'Adicionar espécie',
    genero: 'Gênero',
    familia: 'Família',
    ordem: 'Ordem',
    classe: 'Classe',
    phylumDivisao: 'Phylum / Divisão',
    reino: 'Reino',
    phylum: 'Phylum',
    origem: 'Origem',
    descricaoInvalida: 'Descrição inválida',
    selecioneFamilia: 'Por favor, selecione uma Família.',
    selecioneOrdem: 'Por favor, selecione uma Ordem.',
    selecioneClasse: 'Por favor, selecione uma Classe.',
    selecioneDivisao: 'Por favor, selecione um Phylum / Divisão.',
    selectioneReino: 'Por favor, selecione um Reino.',
    selectioneGenero: 'Por favor, selecione um Gênero.',
    addReferencia: 'Adicionar referência',
    autoresEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    aprovarReferencia: 'Aprovar referência',
    salvarReferencia: 'Salvar referência',
    autorxsEX: 'Autorxs (ex. ZILLER, S. R.; DECHOUM, M. S.)',
    autores: 'Autorxs',
    local: 'Local (Ex. Bahía Blanca, Buenos Aires  /  África do Sul: Cidade do Cabo)',
    localFiltro: 'Local',
    edicao: 'Edição (ex. 2 ed.)',
    editora: 'Editora',
    revista: 'Revista',
    anoPublicacao: 'Ano da publicação',
    meioDivulgacao: 'Meio de divulgação',
    volume: 'Volume (ex. v. 3)',
    numero: 'Número (ex. n. 28)',
    pagina: 'Páginas (ex. p. 25-34  /  245p.)',
    url: 'DOI / URL',
    palavraChave: 'Palavra-chave',
    anoPublic: 'Ano publicação',
    addProjeto: 'Adicionar projeto',
    aprovarProjeto: 'Aprovar Projeto',
    salvarProjeto: 'Salvar projeto',
    contato: 'Contato',
    areaGeografica: 'Área geográfica',
    objetivos: 'Objetivos',
    breveDescricao: 'Breve descrição',
    dataInicio: 'Data início',
    dataTermino: 'Data término',
    comentarios: 'Comentários',
    anoInicio: 'Ano de início',
    anoTermino: 'Ano de término',
    ocorrencias: 'Ocorrências',
    exportarDados: 'Exportar dados',
    addOcorrencia: 'Adicionar ocorrência',
    visualizarOcorrencia: 'Visualizar ocorrência',
    alterarOcorrencia: 'Alterar ocorrência',
    ocorrenciasVinculadas: 'Ocorrências vinculadas',
    areaProtegida: 'Área protegida',
    especie: 'Espécie',
    aprovarOcorrencia: 'Aprovar ocorrência',
    salvarOcorrencia: 'Salvar ocorrência',
    colaborador: 'Colaborador/a',
    municipio: 'Município',
    municipioSede: 'Município (sede)',
    municipioCentroide: 'Município (centróide)',
    referenciaLocal: 'Referência local',
    referencia: 'Referência',
    latitude: 'Latitude',
    longitude: 'Longitude',
    coordenadasCorrespondem: 'As coordenadas correspondem',
    distanciaAproximada: 'Distância aproximada',
    ambiente: 'Ambiente',
    baciaHidro: 'Bacia hidrográfica',
    descricaoInvasao: 'Descrição da invasão',
    abundancia: 'Abundância',
    situacaoPopulacional: 'Situação populacional',
    manejo: 'Manejo',
    dataEntrada: 'Data de entrada',
    dataObservacao: 'Data de observação (ex. 2020 ou 13/05/2020)',
    validado: 'Validado',
    especieMuseuHerbario: 'É um espécime de coleção zoológica ou herbário',
    selecioneTipo: 'Selecione o tipo',
    herbario: 'Herbário',
    museu: 'Coleção zoológica',
    numeroExemplaInstituicao: 'Número de exemplar (Instituição)',
    numeroExemplarColetor: 'Número de exemplar (Coletor)',
    selecioneOpcao: 'Selecione uma opção...',
    pointList1: 'A um ponto de ocorrência de um ou mais indivíduos da espécie',
    pointList2: 'Ao centro de um grupo de indivíduos da espécie',
    pointList3: 'A um ponto próximo da ocorrência, a uma distância aproximada de:',
    coordinatesSourceList1: 'A - Uma unidade política ou administrativa',
    coordinatesSourceList2: 'B - O local real da ocorrência',
    ultimoRegistroBaseDados: 'Último registros na base de dados',
    ultimosAcessos: 'Últimos acessos',
    fichaTecnicaEspecie: 'Ficha técnica das espécies',
    fichaTecnicaEspecieMMA: 'Ficha técnica das espécies MMA versão 1',
    fichaTecnicaEspecieMMA2: 'Ficha técnica das espécies MMA versão 2',
    usoRestringido: 'Categoria 1 Uso restrito',
    usoControlado: 'Categoria 2 Uso controlado',
    noAsignada: 'Categoria 3 Não atribuída',
    noEvaluada: 'Categoria 4 Não avaliada',
    formatosDisponiveisExportacao: 'Formatos disponíveis para exportação:',
    biologiaEcologia: 'Biologia e ecologia',
    usoEconomico: 'Uso econômico',
    usoEconomicoDescricao: 'Uso econômico - descrição',
    invasaoBiologica: 'Invasão biológica',
    impactos: 'Impactos',
    habitat: 'Hábitat',
    nomeCientificoSinonimos: 'Nome científico + sinônimos',
    nomesPopulares: 'Nomes populares',
    reproducao: 'Reprodução',
    dispersao: 'Dispersão',
    dieta: 'Dieta',
    formaBiologica: 'Forma biológica',
    ambientesPreferenciaisInvasao: 'Ambientes mais suscetíveis à invasão',
    tipoIntroducao: 'Tipo de introdução',
    causaIntroducao: 'Causa de introdução',
    localIntroducao: 'Local de introdução',
    anoIntroducao: 'Ano da introdução',
    marinho: 'Marinho',
    dulcicola: 'Água doce',
    terrestre: 'Terrestre',
    validarEspecie: 'Validar espécie',
    salvarEspecie: 'Salvar espécie',
    data: 'Data',
    confiabilidade: 'Confiabilidade',
    resultadosAnalisesRisco: 'Resultados de análises de risco',
    risco: 'Risco',
    areaOrigem: 'Área de origem',
    areaDistribuicaoNatural: 'Área de distribuição natural',
    ambientesNaturais: 'Ambientes naturais',
    descricaoEspecie: 'Descrição da espécie',
    ambientesPreferenciaisInvasaoDescricao: 'Ambientes mais suscetíveis à invasão - descrição',
    outrosLocaisOndeEspecieInvasora: 'Outros locais onde a espécie é invasora',
    impactosEcologicos: 'Impactos ecológicos',
    impactosEconomicos: 'Impactos econômicos',
    impactosSociais: 'Impactos sociais',
    impactosSaude: 'Impactos à saúde',
    categoriaEICAT: 'Categoria EICAT',
    mecanismosImpactoEICAT: 'Mecanismos de impacto EICAT',
    nivelConfiancaAvaliacaoEICAT: 'Nível de confiança da avaliação EICAT',
    referenciaEICAT: 'Referência EICAT',
    dataEICAT: 'Data EICAT (ex. 2021)',
    categoriaSEICAT: 'Categoria SEICAT',
    mecanismosImpactoSEICAT: 'Mecanismos de impacto SEICAT',
    nivelConfiancaAvaliacaoSEICAT: 'Nível de confiança da avaliação SEICAT',
    referenciaSEICAT: 'Referência SEICAT',
    dataSEICAT: 'Data SEICAT (ex. 2021)',
    digitarSomenteAno: 'Digitar somente o ano - 2021',
    viasCDBCategorias: 'Vias/vetores CDB - categorias',
    viasIntroducao: 'Vias de introdução e dispersão',
    viasVetoresCDBSubcategorias: 'Vias/vetores CDB - subcategorias',
    vetoresIntroducao: 'Vetores de introdução e dispersão',
    introducao: 'Introdução',
    adicionarIntroducao: 'Adicionar introdução',
    ano: 'Ano',
    descricaoIntroducao: 'Descrição da introdução',
    salvar: 'Salvar',
    cancelar: 'Cancelar',
    excluir: 'Excluir',
    medidasPreventivas: 'Medidas preventivas',
    medidasPreventivasMMA: 'Medidas preventivas MMA',
    medidasDeteccaoPrecoce: 'Medidas de detecção precoce',
    controleMecanico: 'Controle mecânico',
    controleQuimico: 'Controle químico',
    controleBiologico: 'Controle biológico',
    distribuicaoPais: 'Distribuição no país',
    vejaMapaOcorrencias: 'Veja o mapa de ocorrências',
    ecologiaUso: 'Ecologia e uso',
    introducaoDispersao: 'Introdução e dispersão',
    analiseRisco: 'Análise de risco',
    nomeComum: 'Nome comum',
    foto: 'Foto',
    credito: 'Crédito',
    sinonimos: 'Sinônimos',
    autor: 'Autor/a',
    subespecie: 'Subespécie',
    autorSubespecie: 'Autor/a da subespécie',
    pendenteAnalise: 'Pendente de análise',
    cancelarVoltar: 'Cancelar / Voltar',
    versaoAnterior: 'Versão anterior',
    versaoAtual: 'Versão atual',
    colaboradorxs: 'Colaboradorxs',
    consultaTaxonomiaListaCompletaEspecies: 'Taxonomia - Lista completa de espécies',
    consultaEspeciesOrigemAmbientes: 'Espécies, origem, ambientes de invasão e impactos',
    consultaEspeciesManejo: 'Espécies e alternativas de manejo',
    consultaParaGerarFichaTecnica: 'Ficha técnica da espécie sem ocorrências',
    consultaEspeciesOcorrencias: 'Espécies e locais de ocorrência, com coordenadas',
    exportarDadosConsultas: 'Exportar consultas',
    ocorrenciasApp: 'Ocorrências App',
    dataCadastro: 'Data de cadastro',
    criadoPor: 'Criado por',
    nivelCerteza: 'Nível de certeza',
    byApp: 'Por aplicativo móvel',
    verDados: 'Ver dados',
    appuser: 'App user',
    profissao: 'Profissão',
    colobadorJaCadastrado: 'Colaborador já cadastrado',
    especialidadeApp: 'Especialidade App',
    cep: 'CEP',
    categoriaCDB: 'Categoria CDB',
    subCategoriaCDB: 'Subcategoria CDB',
    especiesDeAguaDoce: 'Espécies de água doce',
    insetosOutrosInvertebradosTerrestres: 'Insetos e outros invertebrados terrestres',
    animaisOrnamentaisCompanhiaAquario: 'Animais ornamentais, de companhia e de aquário',
    plantasOrnamentales: 'Plantas ornamentais',
    especiesFlorestais: 'Espécies florestais',
    especiesChaveEngenheirosEcossistema: 'Espécies-chave e engenheiros de ecossistema',
    mensagemEnviada: 'Mensagem enviada com sucesso.'
  },
  home: {
    destaque1: 'Bem-vindxs a esta iniciativa que visa proteger a biodiversidade do desafio das invasões biológicas!',
    destaqueDescricao1: 'Este sistema de banco de dados recebe contribuições de pessoas comprometidas com a conservação da biodiversidade. Convidamos você a visitar nossa página!',
    destaque2: 'Desde 2004 documentando espécies exóticas invasoras e compartilhando informações online!',
    destaqueDescricao2: 'Esta base de dados foi desenvolvida e atualizada com recursos de projetos internacionais. Atualmente conta com um Comitê Científico de apoio à gestão.',
    destaque3: 'O que inclui este sistema de informação?',
    destaqueDescricao3: 'Gerenciamos cinco bases de dados interligadas sobre espécies, localidades de ocorrência, especialistas e projetos.',
    destaque4: 'Como funciona?',
    destaqueDescricao4: 'Você pode consultar sobre espécies exóticas invasoras e potencialmente invasoras, especialistas, projetos e bibliografia na República Argentina.',
    titulo: 'Procure informações em nossas bases de dados.',
    conteudo: 'As espécies e os sítios onde se encontram no território nacional, especialistas na temática, referências e projectos dedicados ao estudo e gestão desta problemática.',
    conteudoEspecies: 'Esta seção de nosso sistema contém informações sobre as espécies exóticas invasoras e potencialmente invasoras presentes na República Argentina, sua classificação taxonômica, características biológicas, formas de introdução e dispersão, distribuição, abundância e iniciativas de manejo.',
    conteudoColaboradores: 'Cadastro de pessoas que já enviaram dados para a base de dados. Se você precisar falar com um especialista ou saber mais sobre uma ocorrência, busque contatos aqui.',
    conteudoReferencias: 'Listagem completa de referências utilizadas como base para o preenchimento das informações sobre espécies exóticas invasoras na base de dados.',
    conteudoProjetos: 'Projetos de pesquisa e de manejo desenvolvidos para espécies exóticas invasoras na Argentina. Se você tem um projeto em andamento, escreva-nos para que possamos fazer a inclusão.',
    titulo1: 'Sepa más sobre algunas de las especies exóticas invasoras de la Argentina',
    conteudo1: 'As espécies exóticas invasoras pertencem a todos os grupos de organismos e são introduzidas acidental ou voluntariamente por uma variedade de razões, seus efeitos também são muito diversos.'
  },
  areaParceiros: {
    titulo: 'Rede de Bases de Dados de Espécies Exóticas Invasoras:',
    argentina: 'Argentina',
    paraguai: 'Paraguai',
    uruguai: 'Uruguai',
    brasil: 'Brasil',
    jamaica: 'Jamaica'
  },
  redeBaseDeDados: {
    cabecalhoTitulo: 'Rede Latinoamericana',
    paragrafo1: 'Invasões biológicas na Argentina',
    paragrafo2: 'A origem desta rede de bancos de dados remonta a 2004, como parte da Rede Temática sobre Espécies Exóticas Invasoras da Rede Interamericana de Informação sobre Biodiversidade (I3N-IABIN), criada por iniciativa da Cúpula das Américas.',
    paragrafo2_1: 'Cada país membro da I3N designou um líder nacional cujo papel era desenvolver um banco de dados nacional sobre padrões comuns e compartilhar informações sobre espécies exóticas invasoras:',
    marcador1: ': Dr. Sergio M. Zalba, Professor, Universidad Nacional del Sur, Bahía Blanca (desde 2003);',
    marcador2: ': Dra. Sílvia R. Ziller, Fundadora e Diretora Executiva do Instituto Hórus de Desenvolvimento e Conservação Ambiental (desde 2004);',
    marcador3: ': Dr. Aníbal Pauchard, Professor, Facultad de Ciencias Forestales, Universidad de Concepción;',
    marcador4: ': PhD. Eduardo Chacón-Madrigal, Professor da Universidade da Costa Rica. Pesquisadora do Centro de Pesquisas em Biodiversidade e Ecologia Tropical. Co-coordenador da Flora Invasiva e Naturalizada da América Central;',
    marcador5: ': Dr. Diego Inclán, Diretor Executivo do Instituto Nacional de Biodiversidade (desde 2021) e Francisco José Prieto Albuja;',
    marcador5_1: ': Dra Lilian Ferrufino, Universidad Nacional Autónoma de Honduras (desde 2023).',
    marcador5_2: ': Dra. Suzanne Davis, Clearing-House Mechanism, Institute of Jamaica (desde 2007).',
    marcador5_3: ': Hugo Fernando del Castillo, Guyra Paraguay Conservação de Aves (desde 2006);',
    marcador5_4: ': Dr. Ernesto Brugnoli, Professor, Universidad de la República, Montevideo (desde 2005), e o M.Sc. Marcelo Ituruburu, Coordenador do Comitê de Espécies Exóticas Invasoras Ministério do Meio Ambiente (desde 2018);',
    paragrafo3: 'A IABIN foi financiada por um projeto do Banco Mundial (GEF) entre os anos de 2004 e 2011, então extinta. Apesar do término da iniciativa, alguns dos Líderes da rede seguem mantendo as bases de dados nacionais (Brasil, Argentina, Uruguai, Paraguai e Jamaica).',
    paragrafo4: 'No ano de 2021, recebemos um recurso da Secretaria da Convenção sobre Diversidade Biológica, como parte da iniciativa BioBridge, para revitalizar e atualizar as bases de dados da Argentina, do Brasil, do Paraguai e do Uruguai. A interface foi atualizada e novas funcionalidades foram embutidas nas bases de dados.',
    paragrafo5: 'Os profissionais de TI responsáveis ​​pelo desenvolvimento do Banco de Dados são:',
    marcador6: 'João Scucato, de Curitiba - PR, Brasil, que desenvolveu a primeira versão no Microsoft Access, 2004-2005;',
    marcador7: 'Alejandro Moreno, da Argentina, que desenvolveu as primeiras versões open source de nossas bases de dados e da interface web que se utilizou até 2021. Desde 2006 apoiou as bases de dados de diferentes países. Ele guardou cópias dos arquivos e resgatou dados do Paraguai e do Uruguai para esta nova versão. Alejandro também desenvolveu a Plataforma Comum dos países da rede.',
    marcador8: 'O designer Rafael Moura e o programador Thiago Lôbo, do Brasil, por meio da Plataforma Vibbra em Florianópolis - SC, desenvolveram a nova versão (2021) das bases de dados nacionais. Os programadores Leonardo Rotondano e Thiago Lôbo desenvolveron o aplicativo para upload de dados de telefones celulares.',
    paragrafo6: 'Esperamos voltar a ampliar a rede para mais países das Américas a fim de que mais dados sobre espécies exóticas invasoras fiquem disponíveis para fins de conhecimento público, gestão governamental e manejo aplicado.',
    paragrafo7: 'Apoio',
    paragrafo8: 'Período 2021',
    argentina: 'Argentina',
    brasil: 'Brasil',
    chile: 'Chile',
    costarica: 'Costa Rica',
    ecuador: 'Equador',
    honduras: 'Honduras',
    jamaica: 'Jamaica',
    paraguai: 'Paraguai',
    uruguai: 'Uruguai',
    paragrafo9: 'Período 2004-2011'
  },
  baseDadosNacional: {
    cabecalhoTitulo: 'InBiAr',
    titulo: 'Invasões biológicas na Argentina',
    paragrafo1: 'Este banco de dados e a interface Web correspondente foram desenvolvidos pela Universidade Nacional do Sul (Argentina) com a colaboração do Instituto Horus para o Desenvolvimento e Conservação Ambiental (Brasil) e o apoio do Serviço Geológico (USGS) e do Escritório Nacional de Informação Biológica dos Estados Unidos (NBII) no âmbito do projeto I3N (Rede de Informação de Espécies Invasoras) da IABIN (Rede Interamericana de Informação sobre Biodiversidade). Desde 2006, o sistema está operacional, publicado na internet e aberto à consulta gratuita e gratuita, administrado e mantido pelo Grupo de Estudios em Conservação e Gestão (GEKKO), do Departamento de Biologia, Bioquímica e Farmácia da Universidade Nacional del Sur, Argentina.',
    paragrafo2: 'Entre 2015 e 2022, melhorias significativas foram feitas dentro do projeto “Fortalecimento da governança para a proteção da biodiversidade através da Formulação e implementação da Estratégia Nacional de Espécies Exóticas Invasoras (ENEEI)” (GCP / ARG / 023 / GFF), executado pelo Ministério do Meio Ambiente e Desenvolvimento Sustentável da República Argentina, com financiamento parcial do Fundo para o Meio Ambiente Global (GEF) e com a Organização das Nações Unidas para Alimentação e Agricultura (FAO-ONU) como agência de implementação.',
    paragrafo3: 'Em 2020, os esforços foram retomados para coordenar a gestão de informações sobre espécies exóticas invasoras com outros países da região. Com o apoio da iniciativa Biobridge da Convenção sobre Diversidade Biológica (CBD-PNUMA), este sistema de informação uniu esforços com iniciativas semelhantes no Brasil, Paraguai e Uruguai, desenvolvendo uma ',
    paragrafo3_1: 'plataforma de pesquisa de informação unificada',
    paragrafo3_2: ', como mais um passo para consolidar os esforços conjuntos para enfrentar o desafio das invasões biológicas por meio de conhecimentos sólidos e atualizados sobre as espécies exóticas invasoras na região e, assim, contribuir para o cumprimento da Meta 8 da Agenda 2030 / ODS e da Meta 9 de Aichi.'
  },
  politicaDeDados: {
    cabecalhoTitulo: 'Política de dados',
    titulo: 'Conceitos e fontes de referência',
    criterio: 'Critérios para inclusão de espécies',
    construccion: 'Construção colaborativa',
    validacao: 'Validação de dados',
    paragrafo1: 'Para fins desta base de dados define-se, em acordo com a Convenção Internacional sobre Diversidade Biológica:',
    marcador1: 'espécie nativa: aquela que se encontra dentro da área de distribuição geográfica onde evoluiu e faz parte de uma comunidade biológica considerada natural ou semi-natural,',
    marcador2: 'espécie exótica: aquela que está fora de sua área natural passada ou presente,',
    marcador3: 'espécie exótica invasora: qualquer espécie exótica que afeta ou ameaça habitats, ecossistemas ou espécies, causando alterações em ambientes naturais,',
    marcador3_1: 'espécie criptogênica: aquela espécie cuja origem não pode ser estabelecida com certeza e, portanto, não pode ser classificada como nativa ou exótica.',
    paragrafo2: 'Os vocabulários controlados relacionados a ambientes de invasão preferencial, rotas de dispersão e vetores, formas biológicas, impactos e métodos de controle foram definidos de acordo com os termos usados pelo Banco de Dados Global de Espécies Invasoras do Grupo de Especialistas em Espécies Invasoras da IUCN',
    paragrafo3: 'As principais referências taxonômicas são retiradas dos sistemas propostos pelo Catálogo de Plantas Vasculares do Istituto de Botanica Darwinion,',
    paragrafo3_1: 'pelo Jardim Botanico de Missouri',
    paragrafo3_2: 'e pelo Sistema Integrado de Informação Taxonômica, ',
    paragrafo3_3: 'Referências complementares incluem o Registro Mundial de Espécies Marinhas',
    paragrafo3_4: ' o banco de dados global de espécies exóticas invasoras',
    paragrafo3_5: ' e o Compêndio de Espécies Invasoras da CABI',
    paragrafo6: 'Em cada ponto de ocorrência, cada espécie é enquadrada em uma das seguintes categorias:',
    marcador4: '– quando sua presença está restrita a estruturas de uso antrópico, como laboratórios ou áreas de cultivo que não permitam o escape de indivíduos para ambientes naturais.',
    marcador5: '- quando a espécie foi avistada em ambientes naturais ou seminaturais, fora de sistemas de cultivo ou de reprodução, ainda sem evidências de ter estabelecido um núcleo populacional. ',
    marcador6: '- quando a espécie se reproduz efetivamente, formando uma população autossustentável, mas permanece em uma área limitada, próxima ao local de introdução local.',
    marcador7: '– cuando la especie avanza, ya sea de manera autónoma o auxiliada por vector antrópicos, hacia otros sitios más allá del punto de introducción local.',
    paragrafo7: 'Assim sendo, é importante que fique claro que uma espécie pode ser invasora num local, ainda estar em fase de latência (adaptação) em outro, e não ser invasora em ainda outro ambiente.',
    paragrafo11: ' O banco de dados de espécies exóticas invasoras da República Argentina inclui espécies exóticas e criptogênicas detectadas em ambientes naturais ou semi-naturais do país, bem como aquelas que, embora permanecendo contidas em sistemas de cultivo ou reprodução, têm histórico de serem invasoras em outros países. Também estão incluídas espécies que, por serem nativas de alguma região do país, foram translocadas para outras regiões, bacias ou ecossistemas. Todas as espécies estão associadas a pelo menos uma localidade de ocorrência no país. Em alguns casos, esse local é o aeroporto ou porto de entrada, quando não há dados pós-importação. O foco deste sistema é a conservação da diversidade biológica e, portanto, espécies exóticas que se comportam como ervas daninhas, pragas ou patógenos de pessoas ou animais domésticos não são incluídas no banco de dados se ainda não foram detectadas. afetam espécies da flora e da fauna nativas ou não possuem antecedentes internacionais a esse respeito. Além disso, é muito comum que espécies que afetam a estrutura, composição e funcionamento de ecossistemas naturais e semi-naturais também tenham impactos na economia, na saúde e nos valores culturais.',
    paragrafo13: 'As bases de dados deste sistema são mantidas atualizadas graças às contribuições de informações de colaboradores voluntários distribuídas por todo o país. Nossos colaboradores podem contribuir com informações sobre novas espécies, novos locais de ocorrência de espécies já detectadas no território nacional, sobre projetos, especialistas ou publicações sobre o assunto. Cada informação fica vinculada aos dados de quem a forneceu, como forma de reconhecimento de suas contribuições para o conhecimento do estado do problema das invasões biológicas no país e como forma de promover a comunicação direta entre pessoas com conhecimento e interesse na o assunto. Para contribuir com informações, vá para',
    paragrafo13_1: ' link de upload de dados',
    paragrafo13_2: ' ou contate',
    paragrafo13_3: ' As informações também são carregadas de publicações e espécimes de museus e herbários. Cada novo local de ocorrência é avaliado de acordo com os padrões de validação compartilhados com a',
    paragrafo13_4: 'rede LatinoAmericana de Bases de Dados',
    paragrafo13_5: 'Os dados estão associados a coordenadas geográficas que permitem mapear a distribuição registrada para cada espécie.',
    paragrafo14: 'Os administradores da base de dados são responsáveis por preencher as informações sobre as características biológicas de cada espécie, os ambientes preferenciais de invasão, vetores e rotas de introdução e dispersão, impactos e técnicas de controle, entre outros.',
    paragrafo14_1: ' Os dados são revisados continuamente, a cada nova unidade de informação recebida. Novas espécies ou locais de ocorrência que impliquem em mudanças significativas na distribuição de uma espécie ou na colonização de novos ambientes são confirmados por consulta com especialistas',
    paragrafo14_2: 'Comité Científico',
    paragrafo14_3: ' ou outros especialmente convocados com base em sua especialidade',
    paragrafo15: 'A validação dos dados inseridos na base de dados por colaboradores é feita com base na confiabilidade das informações, especialmente com relação à identificação da espécie e ao local de ocorrência.',
    paragrafo15_1: '',
    paragrafo16: 'Validação taxonômica',
    marcador8: 'A pessoa que aportou os dados é especialista no grupo biológico em questão ou tem experiência/conhecimento equivalente?',
    marcador9: 'A ocorrência está documentada mediante uma fotografía que permite a identificação confiável do organismo em questão?',
    marcador10: 'A ocorrência está documentada em uma publicação indexada e com avaliação de pares ou num documento técnico elaborado ou revisado por especialistas?',
    marcador11: 'A informação corresponde a um exemplar de herbário ou a espécime depositado numa coleção de museu?',
    marcador12: 'A ocorrência corresponde a uma espécie que já estava registrada para o país?',
    marcador13: 'O grupo a que pertence a espécie tem taxonomia estável e bem conhecida?',
    marcador14: 'As características da espécie permitem a identificação com facilidade?',
    paragrafo17: 'A resposta positiva a pelo menos uma das perguntas 1, 2, 3 ou 4 recomenda a validação do dado. Se essas quatro perguntas tiverem respostas negativas, o dado somente será validado se as três perguntas subsequentes tiverem respostas positivas (5, 6 e 7).',
    paragrafo18: 'Validação geográfica',
    marcador15: 'O dado inclui coordenadas geográficas? São consideradas coordenadas tomadas diretamente no local da observação, como outras que correspondem à referência geográfica próxima (caso indicada).',
    marcador16: 'O dado inclui uma descrição do local que permite sua identificação confiável?',
    marcador17: 'A ocorrência está documentada mediante uma fotografía que permite a identificação confiável do local?',
    marcador17_1: 'As coordenadas geográficas coincidem com a localização da área descrita e/ou fotografada?',
    marcador18: 'As coordenadas geográficas coincidem com a localização da área descrita e/ou fotografiada?',
    marcador19: 'A espécie já está citada para o país e, nesse caso, o local está incluído na área de distribuição conhecida da espécie ou num local compatível com os ambientes onde a espécie já ocorre (ambientes preferenciais de invasão conhecidos)?',
    marcador20: 'As condições ecológicas do local, inferidas em função da localização indicada, são compatíveis com os requerimentos gerais de nicho da espécie?',
    paragrafo19: 'O dado será validado do ponto de vista da localização geográfica se:',
    marcador21: 'a resposta a uma das perguntas 1, 2 e/ou 3, e 4 é positiva; ',
    marcador22: 'a resposta à pergunta 1 é positiva ou negativa, uma resposta negativa às perguntas 2 e 3, mas as respostas às perguntas 5 e 6 são positivas. ',
    paragrafo20: 'O dado de localização não será validado se: ',
    marcador23: 'a resposta à pergunta 1 e às perguntas 2 e/ou 3 são positivas, mas a resposta à pergunta 4 é negativa;  ',
    marcador24: 'a resposta à pergunta 1 é positiva ou negativa e a resposta às perguntas 5 e 6 é positiva somente para uma ou para nenhuma das perguntas 5 e 6.',
    paragrafo21: 'Finalmente, o dado completo é validado quando aprovado tanto na análise de identificação como na de localização.'
  },
  comiteCientifico: {
    cabecalhoTitulo: 'Comitê científico',
    paragrafo1: ' Este sistema de base de dados conta com um grupo de especialistas que contribuem para assegurar a qualidade, a validade e a atualização das informações. O comitê inclui especialistas em distintos grupos de animais, plantas e microrganismos, assim como pesquisadores com ampla experiência no estudo de distintos biomas da Argentina. O comitê científico ajuda a validar a identificação de organismos detectados e a verificar e confirmar dados de presença de novas espécies no território nacional e de ocorrências em novas localidades ou regiões para as quais ainda não há registro.',
    paragrafo2: ' Este Comitê Científico está integrado por:',
    marcador1: 'Sergio M. Zalba, Biólogo, MSc. em Manejo de Vida Silvestre, Dr. em Biologia. Professor da Universidad Nacional del Sur, Pesquisador Superior CONICET. Administrador da Base de Dados Nacional de Espécies Exóticas Invasoras da Argentina.',
    marcador2: 'Silvia R. Ziller, Engenheira Florestal, Dra. em Conservação da Natureza. Instituto Hórus de Desenvolvimento e Conservação Ambiental. Administradora da Base de Dados Nacional de Espécies Exóticas Invasoras no Brasil.',
    marcador3: 'Alberto L. Scorolli, Biólogo, MSc. e Dr. em Biologia. Professor da Universidade Nacional do Sul. Especialista em ungulados invasores.',
    marcador4: 'Carlos Villamil, Engenheiro Agrônomo, Doutor. Professor Emérito da Universidade Nacional do Sul. Especialista em taxonomia de plantas vasculares e flora regional da República Argentina.',
    marcador4b: 'Evangelina Schwindt, Bióloga, Doutora em Ciências Biológicas. Pesquisador do CONICET. Grupo de Ecologia em Ambientes Costeiros (GEAC), Centro Nacional da Patagônia (CENPAT-CONICET). Especialista em espécies exóticas marinhas e costeiras.',
    marcador4c: 'Jorge Liotta, Biólogo, Museu de Ciências Naturais P. Scasso. Especialista em peixes de agua doce.',
    marcador4d: 'Sebastián Di Martino, Zoólogo, Diretor de Conservação. Fundação Rewilding Argentina.',
    marcador4e: 'Dra. M. Laura Guichón, Grupo de Ecologia Terrestre de Neuquén, Instituto de Pesquisas sobre Biodiversidade e Meio Ambiente (INIBIOMA, CONICET-UNCo), Subsede Junín de los Andes. Centro de Ecologia Aplicada de Neuquén (CEAN). Especialista em Mamíferos Invasores;',
    marcador4f: 'Lic. Francisco Firpo Lacoste, Dirección Nacional Dirección Nacional de Gestión Ambiental del Agua y los Ecosistemas Acuáticos, Ministerio de Ambiente y Desarrollo Sostenible de Nación. Especialista en peces;',
    marcador4g: 'Dr. Cristina Sanhueza e Yannina Cuevas, Universidad Nacional del Sul. Responsáveis pelo gerenciamento de dados.',
    paragrafo3: 'Atribuições do Comitê Científico:',
    marcador5: '1- decisão sobre a inclusão de espécies novas na base de dados;',
    marcador6: '2- decisão sobre a eliminação de espécies por falta ou inconsistência de dados;',
    marcador7: '3- apoio à validação de informações da base de dados;',
    marcador8: '4- apoio na revisão e busca de dados;',
    marcador9: '5- apoio à busca e consulta a colaboradores e especialistas para aporte e validação de dados;',
    marcador10: '6- recomendações de melhorias na base de dados;',
    marcador11: '7- melhoria contínua do nível de informação.',
    paragrafo4: 'Dra. M. Laura Guichón, Grupo de Ecologia Terrestre de Neuquén, Instituto de Pesquisas sobre Biodiversidade e Meio Ambiente (INIBIOMA, CONICET-UNCo), Subsede Junín de los Andes. Centro de Ecologia Aplicada de Neuquén(CEAN).Especialista em Mamíferos Invasores;',
    paragrafo5: 'Dr. Cristina Sanhueza e Yannina Cuevas, Universidad Nacional del Sul. Responsáveis pelo gerenciamento de dados.'
  },
  fornecerDados: {
    cabecalhoTitulo: 'Colabore com dados',
    paragrafo1: 'Nossas bases de dados são mantidas atualizadas graças aos aportes de especialistas e observadores da natureza distribuídos em todo o território nacional. Todas as informações recebidas são validadas seguindo critérios acordados em nível regional. Você pode enviar dados sobre espécies ainda não registradas ou de novas localidades de ocorrência baixando nosso app (Invasoras AR) ou enviando um e-mail para',
    paragrafo1_1: 'inbiar@uns.edu.ar',
    paragrafo2: 'Você pode também enviar por email informações sobre artigos, publicações e projetos sobre invasões biológicas na Argentina. As pessoas que enviam dados regularmente podem receber un nome de usuárix e senha para registrar dados diretamente no sistema. Ajude a enfrentar o desafio representado por espécies exóticas invasoras com contribuições para manter a base de dados completa, atualizada e confiável!',
    paragrafo3: 'Muito obrigadx!'
  },
  especiesInstitucional: {
    titulo: 'Espécies',
    paragrafo1: 'Procure aqui informações detalhadas sobre as espécies exóticas invasoras e potencialmente invasoras presentes na República Argentina, suas características e efeitos, e as opções de controle.',
    paragrafo2_1: ' Você pode contribuir com informações sobre novos locais de ocorrência ou sobre espécies ainda não incluídas neste banco de dados, clique ',
    paragrafo2_2: 'aquí',
    verTutorial: 'Abrir tutorial',
    paragrafo3: ' Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Consultado DD/MM/AAA.',
    paragrafo4_2: '',
    tutorialTitulo1: 'Como citar a base de dados',
    tutorialDescricao1: 'Veja aqui o formato da referência para citação da base de dados em publicações.',
    tutorialTitulo2: 'Use os filtros',
    tutorialDescricao2: 'Aqui temos algumas opções para filtrar conteúdo.',
    tutorialTitulo3: 'Use os filtros: selecione dados',
    tutorialDescricao3: 'Escolha filtros e faça combinações para selecionar dados por local, forma biológica, uso, grupo taxonômico, etc.',
    tutorialTitulo4: 'Número de registros',
    tutorialDescricao4: 'Este é o número de espécies exóticas invasoras na base de dados ou resultante das filtragens realizadas.',
    tutorialDescricao5: 'Aqui você pode selecionar quantos registros visualizar por página.',
    tutorialTitulo6: 'Lista de espécies exóticas invasoras',
    tutorialDescricao6: 'Listagem de todas as espécies ou resultados do uso de filtros. Clique no nome da espécie para ver os dados completos.',
    tutorialTitulo6_1: 'Exportar dados',
    tutorialDescricao6_1: 'Aqui você pode filtrar dados e escolher o formato para exportar.',
    tutorialTitulo7: 'Taxonomia',
    tutorialDescricao7: 'Classificação taxonômica da espécie selecionada, nomes populares, sinônimos e uma foto quando possível!',
    tutorialTitulo8: 'Ecologia e uso',
    tutorialDescricao8: 'Descrição, biologia e ecologia da espécie selecionada, de onde vem, que ambientes invade e usos econômicos.',
    tutorialTitulo9: 'Introdução e dispersão',
    tutorialDescricao9: 'Como a espécie chegou onde é invasora, por quê foi introduzida e como se dispersa para outros lugares.',
    tutorialTitulo10: 'Impactos',
    tutorialDescricao10: 'Ambientais, econômicos, à saúde e culturais. Classificação de impactos ambientais pelo protocolo EICAT – IUCN.',
    tutorialTitulo11: 'Manejo',
    tutorialDescricao11: 'Medidas preventivas, de detecção precoce, controle mecânico, químico e biológico para a espécie selecionada.',
    tutorialTitulo12: 'Análise de Risco',
    tutorialDescricao12: 'Resultados de análises de risco para invasão biológica realizadas para a espécie selecionada.',
    tutorialTitulo13: 'Ocorrências',
    tutorialDescricao13: 'Locais, ambientes, unidades de conservação, províncias onde a espécie ocorre na Argentina e estágio de invasão – veja o mapa!',
    tutorialDescricao14: 'Lista de referências usadas para compilar dados sobre a espécie selecionada e outras publicações que citam a espécie.',
    tutorialTitulo15: 'Projetos',
    tutorialDescricao15: 'Lista de projetos de pesquisa e manejo que contemplam a espécie selecionada.'
  },
  colaboradoresInstitucional: {
    titulo: 'Colaboradorxs',
    paragrafo1: 'Os contatos incluem aqueles que fornecem dados para este sistema de informação, bem como aqueles que coletaram espécimes de museus ou espécimes de herbário, ou são autores de publicações que foram usadas como fontes de dados.',
    paragrafo2: '',
    paragrafo3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Consultado DD/MM/AAA.',
    paragrafo4_2: ''
  },
  especiesMarinhasInstitucional: {
    titulo: 'Espécies marinhas',
    paragrafo1: 'Na maioria dos casos, eles são transportados acidentalmente, muitas vezes com água de lastro ou presos ao casco dos navios. Sua presença tende a passar despercebida durante as primeiras fases de invasão e quando são detectados as chances de controlá-los são muito limitadas pelas restrições impostas pelo ambiente em que se encontram. É por isso que os maiores esforços são feitos para evitar novas introduções. O problema é uma prioridade global e tem motivado o desenvolvimento do',
    paragrafo1_1: 'Convénio Global de Gestão de Água de Lastro e Sedimentos',
    paragrafo1_2: ', do qual a República Argentina é signatária.',
    paragrafo2: 'Outras espécies, como a ostra do Pacífico, são introduzidas voluntariamente para produção. Mais informações sobre o problema das espécies invasoras na costa argentina podem ser obtidas no site do',
    paragrafo2_1: 'Grupo de Ecología en Ambientes Costeros',
    paragrafo2_2: ', CENPAT-CONICET.',
    paragrafo4: 'Amphibalanus amphitrite - dente-de-cachorro',
    paragrafo4b: 'Foto Nicolas Battini',
    paragrafo5: 'Botryllus schlosseri - tunicado-de-estrela',
    paragrafo5b: 'Foto Nicolas Battini',
    paragrafo6: 'Carcinus maenas - caranguejo-verde-europeu',
    paragrafo6b: 'Foto Alejandro Bortolus',
    paragrafo7: 'Crassostrea gigas - ostra-do-Pacífico',
    paragrafo7b: 'Foto Silvana Burela',
    paragrafo8: 'Ficopomatus enigmaticus – poliqueta formador de recifes',
    paragrafo8b: 'Foto Alejandro Bortolus'
  },
  especiesAguaDulceInstitucional: {
    titulo: ' Espécies de água doce',
    paragrafo1: ' Como no caso das espécies marinhas e costeiras, é comum que as espécies de água doce sejam introduzidas com a água de lastro, em associação com os navios que vêm de portos localizados na foz de grandes rios. Desta forma, algas, moluscos, crustáceos e microrganismos conseguem ultrapassar a barreira que os mares e oceanos historicamente lhes impõem. Uma vez introduzidos, é comum que se dispersem associados ao transporte fluvial, náutico ou de pesca.',
    paragrafo3: ' Os peixes de águas interiores, por outro lado, costumam ser introduzidos de forma voluntária, principalmente para a pesca esportiva, como é o caso da truta e outras espécies de salmonídeos que foram, e ainda são, “estocados” em rios, riachos e lagos de quase todo o país, onde causam graves impactos como predadores de espécies nativas.',
    paragrafo5: 'Acipenser gueldenstaedtii - esturión ruso',
    paragrafo5b: 'Foto Danilo Demonte',
    paragrafo6: 'Corbicula fluminea - berbigão-asiático',
    paragrafo6b: 'Foto Silvana Burela',
    paragrafo7: 'Limnoperna fortunei - mexilhão-dourado',
    paragrafo7b: 'Foto Silvana Burela',
    paragrafo8: 'Litobathes catesbeianus - rã-touro',
    paragrafo8b: 'Foto Walter Prado',
    paragrafo9: 'Oncorhynchus tshawytscha - salmão-chinook',
    paragrafo9b: 'Foto Jorge Liotta',
    paragrafo10: 'Sinotaia quadrata - caracol asiático grande',
    paragrafo10b: 'Foto Silvana Burela'
  },
  insectosInstitucional: {
    titulo: ' Insetos e outros invertebrados terrestres ',
    paragrafo1: ' Normalmente transportado de forma acidental, como isca viva, contaminantes de sementes ou acompanhando diferentes tipos de cargas. Algumas espécies são introduzidas como polinizadores de culturas, por exemplo, para plantas cultivadas em estufas ou pastagens, outras são importadas como agentes de controle biológico e, cada vez mais, como animais de companhia. Um dos insetos mais amplamente distribuídos no mundo em associação com as atividades humanas é a abelha, uma espécie que é, ao mesmo tempo, um suporte para as atividades econômicas, mas também uma causa de impactos na biodiversidade.',
    paragrafo2: ' Alguns invertebrados estão associados a impactos na produção agrícola e outros à transmissão de doenças, como o mosquito da febre amarela e o caracol gigante africano.',
    paragrafo4: 'Aedes aegypti - mosquito-da-dengue',
    paragrafo4b: 'Foto: ENEEI Argentina',
    paragrafo5: 'Harmonia axyridis - vaquinha',
    paragrafo5b: 'Foto: Cristina Sanhueza',
    paragrafo6: 'Rumina decollata - caracol',
    paragrafo6b: 'Foto: Silvana Burela',
    paragrafo7: 'Bombus terrestris - mamangava',
    paragrafo7b: 'Foto: Carolina Morales'
  },
  animalesonamentalescompaniaacuarioInstitucional: {
    titulo: ' Animais onamentais, animais de estimação e aquário',
    paragrafo1: ' O comércio internacional de animais de companhia e de aquário é uma das rotas mais frequentes de introdução voluntária de vertebrados e invertebrados em todo o mundo. A variedade de espécies que são comercializadas internacionalmente cresce com os processos de globalização e comércio via Internet intensifica a troca informal, e muitas vezes ilegal, de espécies. Muitas dessas espécies acabam sendo liberadas em ambientes naturais ou seminaturais onde, em alguns casos, podem estabelecer populações silvestres, como a tartaruga-de-orelhas, ou mesmo que não se reproduzam, afetam a fauna nativa como predadores ou doenças transmissores.',
    paragrafo2: ' A atividade do aquarismo também implica a introdução de outros organismos potencialmente invasores, como algas e "rochas vivas".',
    paragrafo4: 'Callosciurus erythraeus - esquilo-de-barriga-vermelha',
    paragrafo4b: 'Foto: ENEEI Argentina',
    paragrafo5: 'Callosciurus erythraeus - esquilo-de-barriga-vermelha',
    paragrafo5b: 'Foto: Fernando Milesi',
    paragrafo6: 'Felis catus - gato doméstico',
    paragrafo6b: 'Foto: Instituto Hórus',
    paragrafo7: 'Sturnus vulgaris - estorninho',
    paragrafo7b: 'Foto: ENEEI Argentina',
    paragrafo8: 'Trachemys scripta - tartaruga-de-orelhas-vermelhas',
    paragrafo8b: 'Foto: Marcos Tortato'
  },
  plantasOrnamentalesInstitucional: {
    titulo: ' Plantas ornamentais',
    paragrafo1: ' Jardinagem e paisagismo estão entre as principais atividades responsáveis pela introdução de espécies invasoras em todo o mundo. Mais de um terço das plantas invasoras da República Argentina registram usos como plantas de parque e jardim. O controle do comércio é complexo, pois o tamanho das sementes permite que muitos indivíduos de muitas espécies sejam transportados em pequenos recipientes.',
    paragrafo2: ' Soma-se a isso a percepção generalizada de que não há nada de errado com o cultivo de plantas de valor ornamental e que, no pior dos casos, sua fuga para a natureza nada mais faz do que "embelezar" as paisagens. Longe disso, alguns dos invasores de maio impactam nosso país, como o alfeneiro, a vassoura, a madressilva ou o lírio amarelo, uma vez que escaparam dos jardins.',
    paragrafo3: 'Ligustrum lucidum - ligustro, alfeneiro',
    paragrafo3b: 'Foto: Sergio Zalba',
    paragrafo4: 'Prunus mahaleb - cereja-silvestre',
    paragrafo4b: 'Foto: Martín Amodeo',
    paragrafo5: 'Prunus mahleb - cereja-silvestre',
    paragrafo5b: 'Foto: Gabriela Brancatelli',
    paragrafo6: 'Rosa sp. - rosa-mosqueta',
    paragrafo6b: 'Foto: Sergio Zalba'
  },
  especiesForestalesInstitucional: {
    titulo: 'Espécies florestais ',
    paragrafo1: ' Árvores são introduzidas para uma variedade de propósitos, desde silvicultura a quebra-vento, produção de lenha e forragem, estabilização de dunas, uso ornamental, interesse botânico e fixação de dióxido de carbono. O sucesso de árvores invasoras costuma ser especialmente notável em regiões de savana e estepe, como a região pampeana e a estepe patagônica na República Argentina, onde ocupam um nicho ecológico que estava desocupado até sua chegada e alteram não apenas a composição das comunidades vegetais, mas a própria estrutura do ecossistema.',
    paragrafo2: ' Além disso, espécies florestais invasoras, incluindo ligustro, cinamomo, acácia-negra e diversas espécies de pínus ameaçam a conservação da biodiversidade em diversos biomas de nosso país.',
    paragrafo4: 'Acacia mearnsii - acácia-negra',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Pinus halepensis - pínus',
    paragrafo5b: 'Foto: Gabriela Brancatelli',
    paragrafo6: 'Tamarix ramosissima - tamarisco',
    paragrafo6b: 'Foto: Evangelina Natale'
  },
  ingenierosInstitucional: {
    titulo: ' Espécies-chave e engenheiros de ecossistemas',
    paragrafo1: ' Enquanto os efeitos mais conhecidos de espécies invasoras sobre a biodiversidade se referem ao impacto de uma espécie introduzida em uma ou algumas espécies nativas, existem invasores que produzem mudanças que afetam o funcionamento de sistemas ecológicos inteiros. Essas espécies desempenham papéis ecológicos importantes e incluem, por exemplo, predadores oportunistas que podem dizimar comunidades inteiras de presas, herbívoros com cascos que afetam a estrutura e composição de pastagens, arbustos e vegetação florestal, gramíneas que alteram a frequência e intensidade dos ataques. Incêndios, nitrogênio fixar plantas que modificam a dinâmica desse nutriente em solos e árvores capazes de atingir depósitos hídricos subterrâneos e causar a dessecação de pequenos corpos d\'água.',
    paragrafo4: 'Equus caballus - caballo',
    paragrafo4b: 'Foto: Alberto Scorolli',
    paragrafo5: 'Impacto de castor (Castor canadensis)',
    paragrafo5b: 'Foto: Erio Curto',
    paragrafo6: 'Bubalus bubalis - búfalo de agua',
    paragrafo6b: 'Foto: Pablo Petracci',
    paragrafo7: 'Capra hircus - cabra',
    paragrafo7b: 'Foto: Pablo Petracci',
    paragrafo8: 'Sus scrofa - jabalí',
    paragrafo8b: 'Foto: Pablo Petracci'
  },
  projetosInstitucional: {
    titulo: 'Projetos',
    paragrafo1: 'Esta seção inclui informações sobre projetos atuais ou anteriores focados no estudo e manejo de espécies exóticas invasoras e potencialmente invasoras presentes na República Argentina. Se você participa ou participou de um projeto relacionado a essas espécies, por favor ajude a incorporá-lo enviando as informações correspondentes para inbiar@uns.edu.ar.',
    paragrafo2_1: '',
    paragrafo2_2: '',
    paragrafo2_3: '',
    paragrafo3: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo4_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina. http://www.inbiar.uns.edu.ar. Consultado DD/MM/AAA.',
    paragrafo4_2: ''
  },
  referenciasInstitucional: {
    titulo: 'Referências',
    paragrafo1: 'As publicações e relatórios técnicos aqui citados foram utilizados para agregar informações sobre as espécies presentes no território nacional, seu comportamento e efeitos. Alguns incluem dados sobre as opções de manejo para diferentes espécies. Se você é autor ou conhece publicações sobre o assunto que ainda não estão incluídas nesta seção, ajude a incorporá-las enviando as citações correspondentes para inbiar@uns.edu.ar.',
    paragrafo2: '',
    paragrafo3: '',
    paragrafo4: 'Por favor use o seguinte formato para citar informação consultada neste sistema:',
    paragrafo5_1: 'InBiAr. Base de Datos sobre Invasiones Biológicas en Argentina. GEKKO, Grupo de Estudios en Conservación y Manejo, Departamento de Biología, Bioquímica y Farmacia, Universidad Nacional del Sur. Bahía Blanca, Argentina.',
    paragrafo5_2: 'Consultado DD/MM/AAA.'
  },
  usoFlorestalInstitucional: {
    titulo: 'Uso florestal',
    paragrafo1: 'Diversas espécies de árvores exóticas foram introduzidas no Brasil a partir da década de 1950 por iniciativas de governo para desenvolver a indústria florestal de produção de papel, celulose, madeira e outros subprodutos. Algumas dessas espécies se tornaram invasoras, sendo os pínus especialmente distribuídos em inúmeros ambientes. Essas espécies requerem um manejo que inclua o controle contínuo e sua contenção em talhões florestais para evitar impactos ambientais e à paisagem. A certificação florestal exige o controle de espécies exóticas invasoras e é preciso eliminar essas árvores de áreas naturais, margens de rodovias e ferrovias, florestas ciliares e outros ambientes onde não são cultivadas.',
    paragrafo2: 'O gênero pínus é tido como o gênero de plantas com maior amplitude de invasão no hemisfério sul! Os pínus deslocam espécies nativas em ecossistemas abertos e são grande consumidores de água, mais do que espécies nativas.',
    paragrafo3: 'Saiba mais neste artigo',
    paragrafo4: 'Pínus sp. - pinheiro-americano',
    paragrafo4b: 'Foto: Instituto Hórus',
    paragrafo5: 'Acacia mearnsii - acácia-negra',
    paragrafo5b: 'Foto: Instituto Hórus',
    paragrafo6: 'Acacia mangium - acácia',
    paragrafo6b: 'Foto: Instituto Hórus'
  },
  contatoInstitucional: {
    cabecalhoTitulo: 'Contato',
    contato: 'Contato',
    p1: 'Preencha todos os campos do formulário abaixo:',
    nome: 'Nome',
    email: 'E-mail',
    instituicao: 'Instituição',
    mensagem: 'Mensagem',
    enviar: 'Enviar'
  }
}

<template>
  <div>
    <div class="header-single header-especies">
      <div class="container  h-100">
        <div class="row align-items-end  h-100">
          <div class="col-sm ">
            <h1 class="align-bottom">{{ $t('especiesInstitucional.titulo') }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="container pt-5">
      <div class="row mb-4">
        <div class="col-md-4 p-4">
          <p>{{ $t('especiesInstitucional.paragrafo1') }}</p>
        </div>
        <div class="col-md-4 p-4">
          <p>{{ $t('especiesInstitucional.paragrafo2_1') }} <router-link to="/fornecer-dados">{{ $t('especiesInstitucional.paragrafo2_2') }}</router-link>.</p>
          <a href="#" class="btn btn-primary btn-1-horus mt-1" id="tutorial1" @click="guide($event)">{{ $t('especiesInstitucional.verTutorial') }}</a>
        </div>
        <div class="col-md-4 p-0">
          <div class="p-3 rounded-5 box-citacao" id="tut-box-citacao">
            <p>{{ $t('especiesInstitucional.paragrafo3') }}</p>
            <p class="mb-0">{{ $t('especiesInstitucional.paragrafo4_1') }}</p>
          </div>
          <div class="col-md-12 d-flex justify-content-end">
            <a class="btn btn-1-horus bt-export mt-5 mx-3" href="#tut-data-export" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="box_exportar">Exportar</a>
          </div>
        </div>
      </div>
      <ConsultasFiltro v-if="!viewEspecie" :byInstitucional="true" />
      <EspeciesForm v-if="viewEspecie" :speciesId="species.species_id" :viewEspecie="true" :byInstitucional="true"/>
      <Especies v-else :withPageTitle="false" :withOrderBy="true" :byInstitucional="true" @actionByInstitucional="actionByInstitucional"/>
      <div class="row mb-2 mb-xl-3">
        <div class="col-auto ml-auto text-right mt-n1">
          <button v-if="viewEspecie" @click="viewEspecie=false" class="btn bt-filtrar mt-1 mb-2">
            {{ $t('label.exibirListagem') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Driver from 'driver.js'
import 'driver.js/dist/driver.min.css'
import Especies from '../../admin/especies/Especies.vue'
import EspeciesForm from '../../admin/especies/EspeciesForm.vue'
import ConsultasFiltro from '../../admin/exportarDados/consultas/ConsultasFiltro.vue'
export default {
  name: 'EspeciesInstitucional',
  components: { Especies, EspeciesForm, ConsultasFiltro },
  data () {
    return {
      driver: null,
      viewEspecie: false,
      species: {},
      steps: [],
      stepsAbas: []
    }
  },
  watch: {
    '$i18n.locale' () {
      this.loadSteps()
      this.loadStepsAbas()
    }
  },
  mounted () {
    this.driver = new Driver({
      animate: true,
      opacity: 0.75,
      padding: 10,
      allowClose: true,
      doneBtnText: this.$t('label.concluir'),
      closeBtnText: this.$t('label.fechar'),
      nextBtnText: this.$t('label.próximo'),
      prevBtnText: this.$t('label.anterior'),
      keyboardControl: true,
      scrollIntoViewOptions: {},
      onNext: (Element) => {},
      onPrevious: (Element) => {}
    })
  },
  methods: {
    guide (e) {
      e.preventDefault()
      e.stopPropagation()
      this.driver.defineSteps(this.viewEspecie ? this.stepsAbas : this.steps)
      this.driver.start()
    },
    actionByInstitucional: function (item) {
      this.species = item
      this.viewEspecie = true
    },
    loadSteps: function () {
      this.steps = [
        {
          element: '#tut-box-citacao',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo1'),
            description: this.$t('especiesInstitucional.tutorialDescricao1'),
            position: 'left'
          }
        },
        {
          element: '#tut-box-filtros',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo2'),
            description: this.$t('especiesInstitucional.tutorialDescricao2'),
            position: 'top'
          }
        },
        {
          element: '#tut-filtro-select',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo3'),
            description: this.$t('especiesInstitucional.tutorialDescricao3'),
            position: 'top'
          }
        },
        {
          element: '#tut-num-registro',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo4'),
            description: this.$t('especiesInstitucional.tutorialDescricao4'),
            position: 'top'
          }
        },
        {
          element: '#select-registro',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo4'),
            description: this.$t('especiesInstitucional.tutorialDescricao5'),
            position: 'left'
          }
        },
        {
          element: '#tut-table-results',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo6'),
            description: this.$t('especiesInstitucional.tutorialDescricao6'),
            position: 'down'
          }
        },
        {
          element: '#tut-data-export',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo6_1'),
            description: this.$t('especiesInstitucional.tutorialDescricao6_1'),
            position: 'down'
          }
        }
      ]
    },
    loadStepsAbas: function () {
      this.stepsAbas = [
        {
          element: '#taxonomia-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo7'),
            description: this.$t('especiesInstitucional.tutorialDescricao7'),
            position: 'top'
          }
        },
        {
          element: '#ecologia-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo8'),
            description: this.$t('especiesInstitucional.tutorialDescricao8'),
            position: 'top'
          }
        },
        {
          element: '#dispersao-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo9'),
            description: this.$t('especiesInstitucional.tutorialDescricao9'),
            position: 'top'
          }
        },
        {
          element: '#impacto-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo10'),
            description: this.$t('especiesInstitucional.tutorialDescricao10'),
            position: 'top'
          }
        },
        {
          element: '#manejo-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo11'),
            description: this.$t('especiesInstitucional.tutorialDescricao11'),
            position: 'top'
          }
        },
        {
          element: '#analiserisco-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo12'),
            description: this.$t('especiesInstitucional.tutorialDescricao12'),
            position: 'top'
          }
        },
        {
          element: '#ocorrencias-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo13'),
            description: this.$t('especiesInstitucional.tutorialDescricao13'),
            position: 'top'
          }
        },
        {
          element: '#referencias-tab',
          popover: {
            title: this.$t('label.referencias'),
            description: this.$t('especiesInstitucional.tutorialDescricao14'),
            position: 'top'
          }
        },
        {
          element: '#projetos-tab',
          popover: {
            title: this.$t('especiesInstitucional.tutorialTitulo15'),
            description: this.$t('especiesInstitucional.tutorialDescricao15'),
            position: 'left'
          }
        }
      ]
    }
  },
  created () {
    this.viewEspecie = false
    this.loadSteps()
    this.loadStepsAbas()
  }
}
</script>
